import React from "react";
import classNames from "classnames";
import "./link.button.component.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LinkButtonProps {
  color?: "primary" | "secondary" | "danger" | "warning";
  className?: string;
  wrapperClassName?: string;
  label: string;
  disabled?: boolean;
  onClick?: (event: any) => void | Promise<void>;
  type?: "submit" | "button";
  form?: string;
  noPadding?: boolean;
  width?: "fit-content" | "full";
  fontSize?: "running-text" | "headline";
  icon?: IconProp;
  iconPosition?: "left" | "right";
}

const LinkButton = ({
  className,
  wrapperClassName,
  label,
  onClick,
  disabled = false,
  color = "primary",
  type = "button",
  form,
  noPadding = false,
  width = "full",
  fontSize = "running-text",
  icon,
  iconPosition = "right",
}: LinkButtonProps): JSX.Element => {
  const linkButtonClassName = classNames(
    {
      "link-button": true,
      "link-button--secondary": color === "secondary",
      "link-button--danger": color === "danger",
      "link-button--warning": color === "warning",
    },
    `link-button--${fontSize}`,
    className
  );

  const iconClassName = classNames(
    {
      "btn-icon": true,
    },
    `btn-icon--${iconPosition}`
  );

  const wrapperClass = classNames(
    {
      "link-button-wrapper": true,
      "link-button-wrapper--disabled": disabled,
      "link-button-wrapper--icon-left": iconPosition === "left",
      "link-button-wrapper--fit-content": width === "fit-content",
      "link-button-wrapper--no-padding": noPadding,
    },
    wrapperClassName
  );

  const _buildButton = (): JSX.Element => {
    return (
      <button
        type={type}
        form={form}
        className={linkButtonClassName}
        onClick={onClick}
        disabled={disabled}
      >
        {label}
      </button>
    );
  };

  // build the manin return and cosider the icon

  return (
    <div className={wrapperClass}>
      {_buildButton()}
      {icon && <FontAwesomeIcon icon={icon} className={iconClassName} />}
    </div>
  );
};

export default LinkButton;
