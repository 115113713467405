import { WidgetConfig } from "@vondot-development/metool_lib";
import FormWidgetIcon from "./form.svg";
import CustomerDataFormWidget from "./customer.data.form.widget.component/customer.data.form.widget.component";
import CustomerDataFormOptionSidebar from "./sidebar.component/customer.data.form.option.sidebar.component";

export const CustomerDataFormWidgetConfig: WidgetConfig = {
  name: "Customer Data Form",
  type: "CUSTOMER_DATA_WIDGET",
  component: CustomerDataFormWidget,
  icon: FormWidgetIcon,
  canHaveChildren: false,
  sidebarComponent: CustomerDataFormOptionSidebar,
};
