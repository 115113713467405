import React from "react";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { useTranslation } from "react-i18next";
import { createTranslate, formatDate } from "globals/helpers/global.helper";
import Image from "components/image.component/image.component";
import { CollectionDataType, ImageSizes } from "globals/enums/global.enum";
import Row from "components/general.compoenents/row.component/row.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { inject, observer } from "mobx-react";
import { ModalStore } from "stores/modal.store";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import ExerciseStore from "stores/exercise.store";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import StudioStore from "stores/studio.store";
import { navigationHelper } from "globals/helpers/navigation.helper";

interface ExerciseListProps {
  exercises: Exercise[];
  onClick?: (exercise: Exercise) => void;
  isLoading?: boolean;
  exerciseStore?: ExerciseStore;
  modalStore?: ModalStore;
  studioStore?: StudioStore;
}

const ExerciseList = ({
  exercises,
  onClick,
  isLoading = false,
  exerciseStore,
  studioStore,
  modalStore,
}: ExerciseListProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "inventoryPage.exercises.overview");
  const navigate = navigationHelper();

  const currentLanguage = studioStore?.currentLanguage?.value;

  const handleArchiveExercise = async (exercise: Exercise): Promise<void> => {
    modalStore?.showDeleteAlert({
      t,
      onConfirm: async () => {
        await exerciseStore?.archiveExercise(exercise);
      },
    });
  };

  const getMuscleGroup = (
    exercise: Exercise,
    type: "agonist" | "antagonist" | "synergist"
  ): string => {
    const muscleGroups = exercise?.muscleGroups[type] || [];

    if (muscleGroups.length === 0 || currentLanguage == null) {
      return "-";
    }

    // map over the muscle groups and return the name and join them with a comma
    return muscleGroups
      .map((muscleGroup) => muscleGroup?.title?.[currentLanguage])
      .join(", ");
  };

  const getLibraryInfo = (exercise: Exercise): string => {
    const library = exercise?.contentLibrary;
    const type = exercise?.type;

    if (library == null || type === CollectionDataType.STUDIO) {
      return "Internal";
    }

    return library.title[currentLanguage!];
  };

  return (
    <ComponentWrapper noPadding>
      <ListDataTable
        gap={20}
        data={exercises || []}
        noDataMessage={translate("noDataMessage")}
        isLoading={isLoading}
        onClick={onClick}
        listDropdownMenu={{
          items: [
            {
              label: translate("listDropdownMenu.edit"),
              icon: faEdit,
              onClick: (exercise: Exercise) => {
                if (exercise?._id == null) return;
                navigate(`inventory/exercises/${exercise._id}/info`);
              },
            },
            {
              label: translate("listDropdownMenu.delete"),
              icon: faTrash,
              onClick: (exercise: Exercise) => {
                handleArchiveExercise(exercise);
              },
            },
          ],
          placement: "left",
        }}
        columns={[
          {
            child: <TitleText tableHeader>{translate("title")}</TitleText>,
            flex: 2,
          },
          {
            child: <TitleText tableHeader>{translate("agonist")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("synergist")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("antagonist")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("library")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("createdAt")}</TitleText>,
            flex: 1,
          },
        ]}
        dataTableItemBuilder={(dataItem: Exercise) => {
          return {
            key: `exercise-item-${dataItem?._id}`,
            children: [
              {
                child: (
                  <Row alignItems="center">
                    <Image
                      imageUrl={dataItem?.coverImageUrl}
                      size={ImageSizes.M}
                    />

                    <RunningText className="ml-20">
                      {dataItem?.title?.[currentLanguage!]}
                    </RunningText>
                  </Row>
                ),
              },
              {
                child: (
                  <RunningText>
                    {getMuscleGroup(dataItem, "agonist")}
                  </RunningText>
                ),
              },
              {
                child: (
                  <RunningText>
                    {getMuscleGroup(dataItem, "antagonist")}
                  </RunningText>
                ),
              },
              {
                child: (
                  <RunningText>
                    {getMuscleGroup(dataItem, "synergist")}
                  </RunningText>
                ),
              },
              {
                child: (
                  <HighlightedInfoText>
                    {getLibraryInfo(dataItem)}
                  </HighlightedInfoText>
                ),
              },
              {
                child: (
                  <RunningText>
                    {formatDate(dataItem?.system?.createdAt)}
                  </RunningText>
                ),
              },
            ],
          } as any;
        }}
      />
    </ComponentWrapper>
  );
};

export default inject(
  "exerciseStore",
  "modalStore",
  "studioStore"
)(observer(ExerciseList));
