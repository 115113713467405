import React from "react";
import MainLayout from "layouts/main.layout/main.layout";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import { Outlet, Route, Routes } from "react-router";
import ArchiveTrainingPlanTab from "./components/archive.training.plan.tab/archive.training.plan.tab";
import ArchiveFeedTab from "./components/archive.feed.tab/archive.feed.tab";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { useTranslation } from "react-i18next";

const ArchivePage = (): JSX.Element => {
  const { t } = useTranslation();

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline showBackButton title={t("archivePage.pageTitle") ?? ""} />
    );
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <PageHeadline className="mb-15">
        <LinkTabs
          tabs={[
            <Tab
              label={t("archivePage.tabs.plans")}
              key="plans-archive"
              path="plans-archive"
              permissionAlias="ARCHIVED_PLANS"
            />,
            <Tab
              label={t("archivePage.tabs.news")}
              key="feed-archive"
              path="feed-archive"
              permissionAlias="ARCHIVED_FEED_ITEMS"
            />,
          ]}
        />
      </PageHeadline>
    );
  };

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer>
        {_buildTabs()}
        <InfoBox>
          <RunningText>{t("archivePage.introText")}</RunningText>
        </InfoBox>

        <Routes>
          <Route element={<Outlet />}>
            <Route path="plans-archive" element={<ArchiveTrainingPlanTab />} />
            <Route path="feed-archive" element={<ArchiveFeedTab />} />
          </Route>
        </Routes>
      </PageContainer>
    </MainLayout>
  );
};

export default ArchivePage;
