import moment from "moment";
import { ClientRole } from "schemas/token.role.schema";
import { Logging } from "./logging.helper";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import { TFunction } from "i18next";

export const formatDate = (
  value: Date | undefined,
  format?: string
): string => {
  if (!value || value == null) {
    return "";
  }

  if (!format) {
    return moment(value).format("DD.MM.yyyy")?.toString();
  }
  return moment(value).format(format)?.toString();
};

// separate token roles by membership
export const filterClientRolesByMembership = (
  tokenRoles: ClientRole[]
): { studioRoles: ClientRole[]; userRoles: ClientRole[] } => {
  const studioRoles = tokenRoles.filter(
    (tokenRole) => tokenRole.membership === "STUDIO"
  );

  const userRoles = tokenRoles.filter(
    (tokenRole) => tokenRole.membership === "USER"
  );

  return { studioRoles, userRoles };
};

// check if alias exists in permission array of given token role
export const checkIfAliasIsInTokenRoles = (
  tokenRoles: ClientRole[],
  alias: string
): boolean => {
  try {
    for (const tokenRole of tokenRoles) {
      if (
        tokenRole.permissions.includes(alias) ||
        tokenRole.roleAlias === alias
      ) {
        return true;
      }
    }
    return false;
  } catch (err) {
    Logging.error({
      className: "GlobalHelper",
      methodName: "checkIfAliasIsInTokenRoles",
      exception: err,
    });

    return false;
  }
};

// claclulate age from date of birth
export const calculateAge = (dateOfBirth: Date): string => {
  const today = new Date();
  const birth = new Date(dateOfBirth);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDifference = today.getMonth() - birth.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birth.getDate())
  ) {
    age--;
  }

  if (age < 0) {
    return "";
  }

  return age?.toString();
};

export const filterAndSortTrainingPlans = (
  trainingPlans: TrainingPlan[],
  type: "CUSTOMER" | "USER"
): TrainingPlan[] => {
  return trainingPlans
    .filter((trainingPlan: TrainingPlan) => trainingPlan.type === type)
    .sort((a: TrainingPlan, b: TrainingPlan) => {
      const aModified = a.system?.modifiedAt ?? 0;
      const bModified = b.system?.modifiedAt ?? 0;
      return Number(aModified) - Number(bModified);
    });
};

interface TranslateOptions {
  defaultValue?: string;
  [key: string]: any;
}

export const createTranslate = (t: TFunction, basePath: string) => {
  return (key: string, options: TranslateOptions = {}) => {
    const fullPath = `${basePath}.${key}`;
    const translation = t(fullPath, { ...options, defaultValue: fullPath });
    return translation;
  };
};

export const generatePlaceholderAvatarUrl = (
  name: string | undefined,
  backgroundColor: string = "00a86d",
  textColor: string = "ffffff"
): string => {
  if (name == null) {
    name = "GYMO";
  }
  const encodedName = encodeURIComponent(name);
  const profileImageUrl = `https://ui-avatars.com/api/?name=${encodedName}&background=${backgroundColor}&color=${textColor}`;
  return profileImageUrl;
};
