import Column from "components/general.compoenents/column.component/column.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import PaymentMethod from "components/stripe.components/payment.method.component/payment.method.component";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { subscriptionPlans } from "globals/data/subscription.data";
import { ContainerSizes } from "globals/enums/global.enum";
import { formatDate } from "globals/helpers/global.helper";
import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { ModalSize, ModalStore, ModalType } from "stores/modal.store";
import PaymentStore from "stores/payment.store";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface BillingTabPageProps {
  paymentStore?: PaymentStore;
  modalStore?: ModalStore;
}

const BillingTabPage = ({
  paymentStore,
  modalStore,
}: BillingTabPageProps): JSX.Element => {
  const { t } = useTranslation();

  const invoices = paymentStore?.invoices ?? [];
  const subscriptions = paymentStore?.subscriptions ?? [];
  const paymentMethods = paymentStore?.paymentMethods ?? [];
  const paymentCustomer = paymentStore?.paymentCustomer;

  useEffect(() => {
    paymentStore?.fetchAndSetInvoices();
    paymentStore?.fetchAndSetSubscriptions();
    paymentStore?.listPaymentMethods();
    paymentStore?.fetchAndSetPaymentCustomer();
  }, []);

  const _getTitleForProduct = (product: string): string => {
    switch (product) {
      case process.env.REACT_APP_STRIPE_BASIC_PLAN:
        return "Gymo Growth";
      case process.env.REACT_APP_STRIPE_BOOKING_PLAN:
        return "Gymo Buchungssystem";
      default:
        return "Custom Plan";
    }
  };

  const _getCurrencySymbol = (currency: string): string => {
    switch (currency) {
      case "eur":
        return "€";
      case "usd":
        return "$";
      default:
        return currency;
    }
  };

  const _buildCurrentSubscription = (): JSX.Element => {
    return (
      <ComponentWrapper
        title={t("billing.currentSubscriptions") ?? "Aktuelle Abonnements"}
        className="mr-20"
      >
        {subscriptions.length === 0 && (
          <Column>
            <TitleText className="mb-15">
              {t("billing.noSubscriptions")}
            </TitleText>
            <FilledButton
              label={t("billing.subscribe")}
              color="secondary"
              onClick={() => {
                modalStore?.openModal(ModalType.SUBSCRIPTION, ModalSize.LARGE, {
                  plan: subscriptionPlans.basic,
                });
              }}
            />
          </Column>
        )}
        {subscriptions.map((subscription) => {
          return (
            <Column className="mb-15" key={subscription.id}>
              <RunningText>{t("billing.products")}</RunningText>
              {subscription.plans.map((plan: any) => {
                return (
                  <TitleText key={plan.plan}>
                    {_getTitleForProduct(plan.product)}
                  </TitleText>
                );
              })}
              <SizedContainer size={ContainerSizes.S} className="mt-10">
                <FilledButton
                  label={t("billing.cancelSubscription")}
                  color="secondary"
                  onClick={async () => {
                    modalStore?.showConfirmAlert({
                      title: t("billing.cancelSubscription"),
                      description: t("billing.cancelSubscriptionDescription"),
                      confirmLabel: t("billing.cancelSubscription"),
                      onConfirm: async () => {
                        await paymentStore?.cancelSubscription({
                          subscriptionID: subscription.id,
                        });
                        await paymentStore?.fetchAndSetSubscriptions();
                      },
                    });
                  }}
                />
              </SizedContainer>
            </Column>
          );
        })}
      </ComponentWrapper>
    );
  };

  const _buildPaymentPaymentMethods = (): JSX.Element => {
    return (
      <ComponentWrapper
        title={t("billing.paymentMethods") ?? "Zahlungsmethoden"}
      >
        {paymentCustomer == null && paymentMethods.length === 0 && (
          <TitleText>
            {t("billing.noPaymentMethods") ??
              "Du hast noch keine Zahlungsmethoden."}
          </TitleText>
        )}
        {paymentMethods.length > 0 && (
          <Column className="mb-15">
            <>
              {paymentMethods.map((paymentMethod) => {
                return (
                  <PaymentMethod
                    key={paymentMethod.id}
                    paymentMethod={paymentMethod}
                    onDelete={async (paymentMethod: any) => {
                      await paymentStore?.deletePaymentMethod({
                        paymentMethodID: paymentMethod.id,
                      });
                      await paymentStore?.listPaymentMethods();
                    }}
                  />
                );
              })}
            </>
          </Column>
        )}
        {paymentCustomer != null && (
          <Column className="mb-15">
            <RunningText className="mb-5">
              {t("billing.billingAddress")}
            </RunningText>
            {paymentCustomer?.address?.line1 != null && (
              <TitleText>{paymentCustomer?.address?.line1}</TitleText>
            )}
            {paymentCustomer?.address?.line2 != null && (
              <TitleText>{paymentCustomer?.address?.line2}</TitleText>
            )}
            {paymentCustomer?.address?.postal_code != null &&
              paymentCustomer?.address?.city != null && (
                <TitleText>
                  {paymentCustomer?.address?.postal_code}
                  {", "}
                  {paymentCustomer?.address?.city}
                </TitleText>
              )}

            <FilledButton
              className="mt-15"
              label={t("billing.editBillingAddress")}
              onClick={() => {
                modalStore?.openModal(ModalType.EDIT_PAYMENT_ADDRESS_MODAL);
              }}
            />
          </Column>
        )}
      </ComponentWrapper>
    );
  };

  const _buildInvoices = (): JSX.Element => {
    return (
      <ComponentWrapper title={t("billing.invoices") ?? "Rechnungen"} noPadding>
        <ListDataTable
          data={invoices}
          noDataMessage={t("billing.noInvoices") ?? "Keine Rechnungen."}
          columns={[
            {
              child: <RunningText>{t("billing.invoice")}</RunningText>,
              flex: 2,
            },
            {
              child: <RunningText>{t("billing.date")}</RunningText>,
            },
            {
              child: <RunningText>{t("billing.amount")}</RunningText>,
            },
            {
              child: <RunningText>{t("billing.download")}</RunningText>,
            },
          ]}
          dataTableItemBuilder={(dataItem: any) => {
            return {
              key: dataItem.id,
              children: [
                {
                  child: <RunningText>{dataItem.id}</RunningText>,
                },
                {
                  child: (
                    <RunningText>
                      {formatDate(
                        moment.unix(dataItem.created).toDate(),
                        "DD.MM.YYYY"
                      )}
                    </RunningText>
                  ),
                },
                {
                  child: (
                    <RunningText>{`${dataItem.total / 100} ${_getCurrencySymbol(
                      dataItem.currency as string
                    )}`}</RunningText>
                  ),
                },
                {
                  child: (
                    <LinkButton
                      className="pr-0 pl-0"
                      label={t("billing.download")}
                      onClick={() => {
                        window.open(dataItem.invoice_pdf, "_blank");
                      }}
                    />
                  ),
                },
              ],
            };
          }}
        />
      </ComponentWrapper>
    );
  };

  return (
    <Column>
      <Row className="mb-15 mobile-row-break">
        {_buildCurrentSubscription()}
        {_buildPaymentPaymentMethods()}
      </Row>
      {_buildInvoices()}
    </Column>
  );
};

export default inject("paymentStore", "modalStore")(observer(BillingTabPage));
