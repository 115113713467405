import React from "react";
import {
  CollapsibleSection,
  EditorStore,
  WidgetStore,
} from "@vondot-development/metool_lib";
import { inject, observer } from "mobx-react";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";

interface PageIntroWidgetOptionSidebarProps {
  widgetStore?: WidgetStore;
  editorStore?: EditorStore;
}

const PageIntroWidgetOptionSidebar = ({
  widgetStore,
  editorStore,
}: PageIntroWidgetOptionSidebarProps): JSX.Element => {
  const selectedWidgetID = editorStore?.selectedWidget?.widget.widgetID;

  const title: string = widgetStore?.getWidgetOption(
    selectedWidgetID ?? "",
    "title"
  );

  const description: string = widgetStore?.getWidgetOption(
    selectedWidgetID ?? "",
    "description"
  );

  const buttonLabel: string = widgetStore?.getWidgetOption(
    selectedWidgetID ?? "",
    "buttonLabel"
  );

  return (
    <div>
      <CollapsibleSection title="Content">
        <OutlinedTextInput
          label="Title"
          value={title ?? ""}
          onChange={(value: any) => {
            if (value != null) {
              widgetStore?.updateWidgetOption(
                selectedWidgetID ?? "",
                "title",
                value
              );
            }
          }}
        />

        <OutlinedTextInput
          label="Description"
          value={description ?? ""}
          onChange={(value: any) => {
            if (value != null) {
              widgetStore?.updateWidgetOption(
                selectedWidgetID ?? "",
                "description",
                value
              );
            }
          }}
        />

        <OutlinedTextInput
          label="Button Label"
          value={buttonLabel ?? ""}
          onChange={(value: any) => {
            if (value != null) {
              widgetStore?.updateWidgetOption(
                selectedWidgetID ?? "",
                "buttonLabel",
                value
              );
            }
          }}
        />
      </CollapsibleSection>
    </div>
  );
};

export default inject(
  "widgetStore",
  "editorStore"
)(observer(PageIntroWidgetOptionSidebar));
