import React from "react";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { DataAttribute } from "schemas/data.attribute.schemas/data.attribute.schema";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import TitleText from "components/text.components/title.text.component/title.text.component";

interface DataAttributeListProps {
  dataAttributes: DataAttribute[];
  onClick?: (dataAttribute: DataAttribute) => void;
  isLoading?: boolean;
}

const DataAttributeList = ({
  dataAttributes,
  onClick,
  isLoading,
}: DataAttributeListProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(
    t,
    "accountPage.dataAttributeTab.attributes.table"
  );

  return (
    <ListDataTable
      isLoading={isLoading}
      data={dataAttributes}
      onClick={onClick}
      columns={[
        {
          flex: 1,
          child: <TitleText tableHeader>{translate("label")}</TitleText>,
        },
        {
          flex: 1,
          child: <TitleText tableHeader>{translate("fieldID")}</TitleText>,
        },
        {
          flex: 1,
          child: <TitleText tableHeader>{translate("type")}</TitleText>,
        },
        {
          flex: 1,
          child: (
            <TitleText tableHeader>{translate("required.label")}</TitleText>
          ),
        },
      ]}
      dataTableItemBuilder={(dataItem: DataAttribute, index: number) => {
        return {
          key: `attribute-${dataItem?._id}${index}`,
          children: [
            {
              child: <RunningText>{dataItem?.label}</RunningText>,
              className: "mr-10",
            },
            {
              child: <RunningText>{dataItem?.fieldID}</RunningText>,
              className: "mr-10",
            },

            {
              child: <RunningText>{dataItem?.dataAttributeType}</RunningText>,
              className: "mr-10",
            },
            {
              child: (
                <RunningText>
                  {dataItem?.required
                    ? translate("required.true")
                    : translate("required.false")}
                </RunningText>
              ),
              className: "mr-10",
            },
          ],
        };
      }}
      gap={30}
    />
  );
};

export default DataAttributeList;
