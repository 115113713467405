import React from "react";
import Column from "components/general.compoenents/column.component/column.component";
import MarketplaceAddOnPage from "../merketplace.add.on.page/merketplace.add.on.page";
import MarketplaceIntegrationPage from "../marketplace.integration.page/marketplace.integration.page";
import MarketplaceContentLibraryPage from "../marketplace.content.library.page/marketplace.content.library.page";

const MarketplaceBrowsePage = (): JSX.Element => {
  return (
    <Column>
      <MarketplaceAddOnPage showHeader />

      <MarketplaceIntegrationPage showHeader />

      <MarketplaceContentLibraryPage showHeader />
    </Column>
  );
};

export default MarketplaceBrowsePage;
