import { makeAutoObservable } from "mobx";
import { NavigateFunction } from "react-router";
import { SearchTopic } from "./global.search.store";
import RootStore from "./root.store";
import { createTranslate } from "globals/helpers/global.helper";
import { TFunction } from "i18next";

export enum ModalType {
  INVISIBLE = "INVISIBLE",
  PLAN_EXERCISE_SELECTION_MODAL = "PLAN_EXERCISE_SELECTION_MODAL",
  INVITE_TEAM_MEMBER_MODAL = "INVITE_TEAM_MEMBER_MODAL",
  CHANGE_PASSWORD_MODAL = "CHANGE_PASSWORD_MODAL",
  CONFIRM_MODAL = "CONFIRM_MODAL",
  SUBSCRIPTION = "SUBSCRIPTION",
  ADD_BOOKING_FOR_USER_MODAL = "ADD_BOOKING_FOR_USER_MODAL",
  EDIT_PAYMENT_ADDRESS_MODAL = "EDIT_PAYMENT_ADDRESS_MODAL",
  TRAINING_PLAN_SELECTION_MODAL = "TRAINING_PLAN_SELECTION_MODAL",
  TEMPLATE_LIBRARY_MODAL = "TEMPLATE_LIBRARY_MODAL",
  ASK_TO_NAVIGATE = "ASK_TO_NAVIGATE",
  DATA_ATTRIBUTE_FORM_MODAL = "DATA_ATTRIBUTE_FORM_MODAL",
  DATA_ATTRIBUTE_COLLECTION_FORM_MODAL = "DATA_ATTRIBUTE_COLLECTION_FORM_MODAL",
  GLOBAL_SEARCH_MODAL = "GLOBAL_SEARCH_MODAL",
  ASSET_SELECTION_MODAL = "ASSET_SELECTION_MODAL",
}

export enum ModalSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  SEMI_MEDIUM = "semi-medium",
}

interface NavigateData {
  path: string;
  navigate: NavigateFunction;
  title: string;
  description: string;
}

export class ModalStore {
  private stores: RootStore;

  // Properties
  currentModal: ModalType = ModalType.INVISIBLE;
  size: ModalSize = ModalSize.MEDIUM;
  customData: any;
  navigateData: NavigateData | undefined;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  // Methods
  openModal = (
    type: ModalType,
    size: ModalSize = ModalSize.MEDIUM,
    customData?: any
  ): void => {
    this.currentModal = type;
    this.customData = customData;
    this.size = size;
  };

  closeModal = (): void => {
    this.currentModal = ModalType.INVISIBLE;
  };

  openNavigateModal = (navigateData: NavigateData): void => {
    const askToNavigate = localStorage.getItem("askToNavigate");

    // if askToNavigate is false, navigate without showing the modal
    if (askToNavigate === "false" && navigateData?.navigate != null) {
      const navigate = navigateData?.navigate;
      const path = navigateData?.path;

      // navigate to the path
      navigate(path);
      return;
    }

    this.currentModal = ModalType.ASK_TO_NAVIGATE;
    this.navigateData = navigateData;
    this.size = ModalSize.SMALL;
  };

  openGlobalSearchModal = (topic: SearchTopic): void => {
    this.currentModal = ModalType.GLOBAL_SEARCH_MODAL;
    this.size = ModalSize.SEMI_MEDIUM;
    this.customData = {
      topic,
    };
  };

  showConfirmAlert = (args: {
    onConfirm: () => void | Promise<void>;
    confirmLabel: string;
    title: string;
    description: string;
    onCancel?: () => void;
    cancelLabel?: string;
  }): void => {
    this.currentModal = ModalType.CONFIRM_MODAL;
    this.customData = {
      onConfirm: args.onConfirm,
      confirmLabel: args.confirmLabel,
      title: args.title,
      description: args.description,
      onCancel: args.onCancel,
      cancelLabel: args.cancelLabel,
    };
    this.size = ModalSize.SMALL;
  };

  showTemplateModal = (args: {
    onSave: (data: any) => void | Promise<void>;
  }): void => {
    this.currentModal = ModalType.TEMPLATE_LIBRARY_MODAL;
    this.customData = {
      onSave: args.onSave,
    };
    this.size = ModalSize.MEDIUM;
  };

  showDeleteAlert = (args: {
    t: TFunction;
    onConfirm: () => void | Promise<void>;
    confirmLabel?: string;
    title?: string;
    description?: string;
    cancelLabel?: string;
    topic?: string;
    onCancel?: () => void;
  }): void => {
    const translate = createTranslate(args.t, "general.deleteModal");

    this.currentModal = ModalType.CONFIRM_MODAL;
    this.customData = {
      onConfirm: args.onConfirm,
      onCancel: args.onCancel,
      title: args.title ?? translate("title", { topic: args.topic }),
      description: args.description ?? translate("description"),
      cancelLabel: args.cancelLabel ?? translate("cancelLabel"),
      confirmLabel:
        args.confirmLabel ?? translate("confirmLabel", { topic: args.topic }),
    };
    this.size = ModalSize.SMALL;
  };
}
