import React, { useState } from "react";
import "./tag.filter.bar.component.scss";
import classNames from "classnames";

export interface FilterTag {
  key: string;
  label: string;
}

interface TagFilterBarComponentProps {
  tags: FilterTag[];
  onTagSelectionChange: (selectedTags: FilterTag[]) => void; // Ändere den Typ des Callback-Parameters
  className?: string;
  singleSelection?: boolean;
  initialSelectedTags?: FilterTag[];
}

const TagFilterBarComponent = ({
  tags,
  onTagSelectionChange,
  className,
  singleSelection = false,
  initialSelectedTags = [],
}: TagFilterBarComponentProps): JSX.Element => {
  const initialKeys = new Set(initialSelectedTags.map((tag) => tag.key));
  const [selectedKeys, setSelectedKeys] = useState<Set<string>>(initialKeys);

  const tagFilterBarClassName = classNames("tag-filter-bar", className);

  const onTagClick = (key: string): void => {
    let newSelectedKeys = new Set<string>(selectedKeys);
    if (singleSelection) {
      if (selectedKeys.has(key)) {
        newSelectedKeys.delete(key);
      } else {
        newSelectedKeys = new Set([key]); // Clear all others and add the new key
      }
    } else {
      if (selectedKeys.has(key)) {
        newSelectedKeys.delete(key);
      } else {
        newSelectedKeys.add(key);
      }
    }
    setSelectedKeys(newSelectedKeys);
    // Konvertiere die Keys zurück in Tag-Objekte, bevor der Zustand weitergegeben wird
    const selectedTags = tags.filter((tag) => newSelectedKeys.has(tag.key));
    onTagSelectionChange(selectedTags);
  };

  return (
    <div className={tagFilterBarClassName}>
      {tags.map((tag) => (
        <button
          key={tag.key}
          className={`tag-btn ${
            selectedKeys.has(tag.key) ? "tag-btn--selected" : ""
          }`}
          onClick={() => onTagClick(tag.key)}
        >
          {tag.label}
        </button>
      ))}
    </div>
  );
};

export default TagFilterBarComponent;
