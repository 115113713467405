import { Logging } from "globals/helpers/logging.helper";
import {
  PaginationDataList,
  getSkipAndLimitFromPage,
} from "globals/intefaces/pageination.data.list.interface";
import { makeAutoObservable } from "mobx";
import { Page } from "schemas/metool.schemas/page.schema";
import { HttpPageService } from "services/httpClients/metool.clients/http.page.client";
import RootStore from "./root.store";

class MetoolStore {
  private stores: RootStore;

  //! Properties
  private _pagesDataList: PaginationDataList<Page> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setters

  setPages = (pages: Page[]): void => {
    this._pagesDataList.data = pages;
  };

  //! Getters

  get pages(): PaginationDataList<Page> | undefined {
    return this._pagesDataList;
  }

  //! Methods

  fetchAndSetPages = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._pagesDataList != null &&
        this._pagesDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._pagesDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._pagesDataList.pageIndex += 1;
      } else {
        this._pagesDataList.pageIndex = 0;
        this._pagesDataList.data = [];
      }

      this._pagesDataList.isLoading = true;

      const pages = await HttpPageService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._pagesDataList.pageIndex,
            itemsInPage: this._pagesDataList.itemsInPage,
          }),
        },
      });

      if (pages == null) {
        this._pagesDataList.isLoading = false;
        return;
      }

      this.setPages(pages);
      this._pagesDataList.isLoading = false;
    } catch (err) {
      this._pagesDataList.isLoading = false;
      Logging.error({
        className: "MetoolStore",
        methodName: "fetchAndSetPages",
        message: "Pages konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default MetoolStore;
