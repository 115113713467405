import { WidgetConfig } from "@vondot-development/metool_lib";
import OrderWidgetIcon from "./order.svg";
import OrderDataTableWidget from "./order.data.table.widget.component/order.data.table.widget.component";

export const OrderDataWidgetConfig: WidgetConfig = {
  name: "Order Data Widget",
  type: "ORDER_DATA_WIDGET",
  component: OrderDataTableWidget,
  icon: OrderWidgetIcon,
  canHaveChildren: false,
};
