import { Logging } from "globals/helpers/logging.helper";
import {
  DataItem,
  PaginationDataList,
} from "globals/intefaces/pageination.data.list.interface";
import { makeAutoObservable } from "mobx";
import {
  TrainingPlan,
  trainingPlanToJson,
} from "schemas/training.plan.schemas/training.plan.schema";
import { HttpCustomerService } from "services/httpClients/http.customer.client";
import { HttpTrainingPlanService } from "services/httpClients/http.training.plan.client";
import { toast } from "react-toastify";
import { ChartData } from "schemas/chart.schemas/chart.data.schema";
import { HttpStatisticService } from "services/httpClients/http.statistic.client";
import { WorkoutStats } from "schemas/workout.stats.schemas/workout.stats.schema";
import { HttpWorkoutStatsService } from "services/httpClients/http.workout.stats.client";
import { Customer } from "schemas/customer.schemas/customer.schema";
import RootStore from "./root.store";

class CustomerStore {
  private stores: RootStore;

  // Properties
  private _customersDataList: PaginationDataList<Customer> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _customerWorkoutStatsDataList: PaginationDataList<WorkoutStats> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _customersDataListSearchResult: Customer[] = [];

  private _currentSelectedCustomers?: Customer[] = [];

  private _currentCustomer: DataItem<Customer> = {
    data: undefined,
    isLoading: false,
  };

  private _customerTrainingPlanDataList: PaginationDataList<TrainingPlan> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _studioTimeStatistic: DataItem<ChartData> = {
    data: undefined,
    isLoading: false,
  };

  private _workoutStatistic: DataItem<ChartData> = {
    data: undefined,
    isLoading: false,
  };

  private _bodyStatistic: DataItem<ChartData> = {
    data: undefined,
    isLoading: false,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setter
  setCustomers = (customers: Customer[]): void => {
    this._customersDataList.data = customers;
  };

  setCustomerWorkoutStats = (workoutStats: WorkoutStats[]): void => {
    this._customerWorkoutStatsDataList.data = workoutStats;
  };

  setCustomersSearchResult = (customers: Customer[]): void => {
    this._customersDataListSearchResult = customers;
  };

  setCurrentSelectedCustomers = (users: Customer[] | undefined): void => {
    this._currentSelectedCustomers = users;
  };

  setCurrentCustomer = (customer: Customer): void => {
    this._currentCustomer.data = customer;
  };

  setCustomerTrainingPlans = (trainingPlans: TrainingPlan[]): void => {
    this._customerTrainingPlanDataList.data = trainingPlans;
  };

  setStudioTimeStatistic = (chartData: ChartData): void => {
    this._studioTimeStatistic.data = chartData;
  };

  setWorkoutStatistic = (chartData: ChartData): void => {
    this._workoutStatistic.data = chartData;
  };

  setBodyStatistic = (chartData: ChartData): void => {
    this._bodyStatistic.data = chartData;
  };

  //! Getters
  get customers(): PaginationDataList<Customer> | undefined {
    if (this._customersDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._customersDataList));
  }

  get customersSearchResult(): Customer[] | undefined {
    if (this._customersDataListSearchResult == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._customersDataListSearchResult));
  }

  get customerWorkoutStats(): PaginationDataList<WorkoutStats> | undefined {
    if (this._customerWorkoutStatsDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._customerWorkoutStatsDataList));
  }

  get currentCustomer(): DataItem<Customer> | undefined {
    return this._currentCustomer;
  }

  get currentCustomerFullName(): string {
    if (
      this._currentCustomer?.data == null ||
      this._currentCustomer?.data?.firstName == null ||
      this._currentCustomer?.data?.lastName == null
    ) {
      return "";
    }

    return `${this._currentCustomer?.data?.firstName} ${this._currentCustomer?.data?.lastName}`;
  }

  get customerTrainingPlans(): PaginationDataList<TrainingPlan> | undefined {
    if (this._customerTrainingPlanDataList == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._customerTrainingPlanDataList));
  }

  get currentSelectedCustomers(): Customer[] | undefined {
    if (this._currentSelectedCustomers == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._currentSelectedCustomers));
  }

  get studioTimeStatistic(): DataItem<ChartData> | undefined {
    return this._studioTimeStatistic;
  }

  get workoutStatistic(): DataItem<ChartData> | undefined {
    return this._workoutStatistic;
  }

  get bodyStatistic(): DataItem<ChartData> | undefined {
    return this._bodyStatistic;
  }

  //! Methods
  fetchAndSetCustomers = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._customersDataList != null &&
        this._customersDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._customersDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._customersDataList.pageIndex += 1;
      } else {
        this._customersDataList.pageIndex = 0;
        this._customersDataList.data = [];
      }

      this._customersDataList.isLoading = true;

      const customers = await HttpCustomerService.getInstance().find({});

      if (customers == null) {
        this._customersDataList.isLoading = false;
        return;
      }

      this.setCustomers(customers);

      this._customersDataList.isLoading = false;
    } catch (err) {
      this._customersDataList.isLoading = false;

      Logging.error({
        className: "CustomerStore",
        methodName: "fetchAndSetCustomers",
        message: "Kunden konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetCustomer = async (args: { customerID: string }): Promise<void> => {
    try {
      this._currentCustomer.isLoading = true;

      const customer = await HttpCustomerService.getInstance().findOne({
        id: args.customerID,
      });

      if (customer == null) {
        this._currentCustomer.isLoading = false;
        return;
      }

      this.setCurrentCustomer(customer);
      this.setCustomerTrainingPlans([]);
      this._currentCustomer.isLoading = false;
    } catch (err) {
      this._currentCustomer.isLoading = false;

      Logging.error({
        className: "CustomerStore",
        methodName: "fetchAndSetCustomer",
        message: "Kunde konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetCustomerTrainingPlans = async (args: {
    customerID: string;
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      const appUser = this._currentCustomer.data?.appUser;

      if (appUser == null) {
        return;
      }

      if (
        args.loadMore !== true &&
        this._customerTrainingPlanDataList != null &&
        this._customerTrainingPlanDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._customerTrainingPlanDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._customerTrainingPlanDataList.pageIndex += 1;
      } else {
        this._customerTrainingPlanDataList.pageIndex = 0;
        this._customerTrainingPlanDataList.data = [];
      }

      this._customerTrainingPlanDataList.isLoading = true;

      const trainingPlans = await HttpTrainingPlanService.getInstance().find({
        query: {
          filter: JSON.stringify({
            $or: [{ type: "CUSTOMER" }, { type: "USER" }],
            user: appUser._id,
          }),
        },
      });

      if (trainingPlans == null) {
        this._customerTrainingPlanDataList.isLoading = false;
        return;
      }

      this.setCustomerTrainingPlans(trainingPlans);
      this._customerTrainingPlanDataList.isLoading = false;
    } catch (err) {
      this._customerTrainingPlanDataList.isLoading = false;

      Logging.error({
        className: "CustomerStore",
        methodName: "fetchAndSetCustomerTrainingPlans",
        message: "Training Pläne konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateCustomer = async (args: {
    id: string;
    customer: Customer;
  }): Promise<void> => {
    try {
      this._currentCustomer.isLoading = true;

      const updatedCustomer = await HttpCustomerService.getInstance().updateOne(
        {
          id: args.id,
          data: args.customer,
        }
      );

      if (updatedCustomer == null) {
        this._currentCustomer.isLoading = false;
        return;
      }

      this.setCurrentCustomer(updatedCustomer);

      // filter item in this._customersDataList.data and update it
      const feedConfigListWithoutUpdatedItem =
        this._customersDataList.data.filter(
          (item) => item._id !== updatedCustomer._id
        );

      this._customersDataList.data = [
        updatedCustomer,
        ...feedConfigListWithoutUpdatedItem,
      ];

      this._currentCustomer.isLoading = false;
      toast.success("Kunde wurde erfolgreich aktualisiert");
    } catch (err) {
      Logging.error({
        className: "CustomerStore",
        methodName: "updateCustomer",
        message: "Customer konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateCustomerTrainingPlan = async (args: {
    id: string;
    trainingPlan: TrainingPlan;
    notifyCustomer?: boolean;
  }): Promise<TrainingPlan | undefined> => {
    try {
      this._customerTrainingPlanDataList.isLoading = true;

      // if notifyCustomer is defined add it to the query
      const query = args.notifyCustomer ? { notifyCustomer: true } : {};

      const updatedPlan = await HttpTrainingPlanService.getInstance().updateOne(
        {
          id: args.id,
          data: trainingPlanToJson(args.trainingPlan),
          query,
        }
      );

      if (updatedPlan == null) {
        this._customerTrainingPlanDataList.isLoading = false;
        return;
      }

      // find updated plan in this._trainingPlanDataList.data and replace it
      // and set plan as first item in list
      const plansWithoutUpdatedItem =
        this._customerTrainingPlanDataList.data.filter(
          (item) => item._id !== updatedPlan._id
        );

      this._customerTrainingPlanDataList.data = [
        updatedPlan,
        ...plansWithoutUpdatedItem,
      ];

      this.stores.trainingPlanStore.setCurrentTrainingPlan(updatedPlan);
      this._customerTrainingPlanDataList.isLoading = false;
      toast.success("TrainingPlan wurde erfolgreich aktualisiert");
      return updatedPlan;
    } catch (err) {
      this._customerTrainingPlanDataList.isLoading = false;

      Logging.error({
        className: "TrainingPlanStore",
        methodName: "updateTrainingPlan",
        message: "TrainingPlan konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createCustomerTrainingPlan = async (args: {
    trainingPlan: TrainingPlan;
    fromTemplate?: string;
    notifyCustomer?: boolean;
  }): Promise<TrainingPlan | undefined> => {
    try {
      this._customerTrainingPlanDataList.isLoading = true;

      // if notifyCustomer or fromTemplate is true add it to the query
      const query = {
        ...(args.notifyCustomer != null && {
          notifyCustomer: args.notifyCustomer,
        }),
        ...(args.fromTemplate != null && {
          fromTemplate: args.fromTemplate,
        }),
      };

      const newPlan = await HttpTrainingPlanService.getInstance().create({
        data: trainingPlanToJson(args.trainingPlan),
        query,
      });

      if (newPlan == null) {
        this._customerTrainingPlanDataList.isLoading = false;
        return;
      }

      if (this._customerTrainingPlanDataList.data != null) {
        this._customerTrainingPlanDataList.data.unshift(newPlan);
      }

      this.stores.trainingPlanStore.setCurrentTrainingPlan(newPlan);

      this._customerTrainingPlanDataList.isLoading = false;
      toast.success("TrainingPlan wurde erfolgreich erstellt");
      return newPlan;
    } catch (err) {
      this._customerTrainingPlanDataList.isLoading = false;

      Logging.error({
        className: "TrainingPlanStore",
        methodName: "createTrainingPlan",
        message: "TrainingPlan konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  //! METHODS TO IMPROVE (OLD STORE METHODS) BELOW:

  searchAndSetCustomers = async (
    searchTerm: string
  ): Promise<Customer | undefined> => {
    try {
      if (this._customersDataList.isLoading) {
        return;
      }

      if (searchTerm == null || searchTerm.trim().length === 0) {
        this.fetchAndSetCustomers({ refresh: true });
        return;
      }

      this._customersDataList.isLoading = true;

      const exercises = await HttpCustomerService.getInstance().search({
        searchTerm: searchTerm.trim(),
      });

      if (exercises == null) {
        this._customersDataList.isLoading = false;
        return;
      }

      this.setCustomersSearchResult(exercises);
      this._customersDataList.isLoading = false;
    } catch (err) {
      this._customersDataList.isLoading = false;

      Logging.error({
        className: "CustomerStore",
        methodName: "searchAndSetCustomers",
        message: "Suche konnte nicht durchgeführt werden",
        exception: err,
      });
    }
  };

  getStudioTimeStatistics = async (): Promise<ChartData | undefined> => {
    try {
      if (
        this._studioTimeStatistic.isLoading ||
        this._currentCustomer == null ||
        this._currentCustomer.data == null
      ) {
        return;
      }

      this._studioTimeStatistic.isLoading = true;

      const statistics =
        await HttpStatisticService.getInstance().getStatisticsForTimeInStudio({
          userID: this._currentCustomer.data._id,
        });

      if (statistics == null) {
        this._studioTimeStatistic.isLoading = false;
        return;
      }

      this.setStudioTimeStatistic(statistics);
      this._studioTimeStatistic.isLoading = false;

      return statistics;
    } catch (err) {
      this._studioTimeStatistic.isLoading = false;

      Logging.error({
        className: "CustomerStore",
        methodName: "getStudioTimeStatistics",
        message: "Studio Zeit Statistik konnte nicht geladen werden",
        exception: err,
      });
    }
  };

  getStatisticForWorkouts = async (): Promise<ChartData | undefined> => {
    try {
      if (
        this._workoutStatistic.isLoading ||
        this._currentCustomer == null ||
        this._currentCustomer.data == null
      ) {
        return;
      }

      this._workoutStatistic.isLoading = true;

      const statistics =
        await HttpStatisticService.getInstance().getStatisticForWorkouts({
          userID: this._currentCustomer.data._id,
        });

      if (statistics == null) {
        this._workoutStatistic.isLoading = false;
        return;
      }

      this.setWorkoutStatistic(statistics);
      this._workoutStatistic.isLoading = false;

      return statistics;
    } catch (err) {
      this._workoutStatistic.isLoading = false;

      Logging.error({
        className: "CustomerStore",
        methodName: "getStatisticForWorkouts",
        message: "Workout Statistik konnte nicht geladen werden",
        exception: err,
      });
    }
  };

  getStatisticForBodyStats = async (): Promise<ChartData | undefined> => {
    try {
      if (
        this._bodyStatistic.isLoading ||
        this._currentCustomer == null ||
        this._currentCustomer.data == null
      ) {
        return;
      }

      this._bodyStatistic.isLoading = true;

      const statistics =
        await HttpStatisticService.getInstance().getStatisticForBodyStat({
          userID: this._currentCustomer.data._id,
          type: "BODY_WEIGHT",
        });

      if (statistics == null) {
        this._bodyStatistic.isLoading = false;
        return;
      }

      this.setBodyStatistic(statistics);
      this._bodyStatistic.isLoading = false;

      return statistics;
    } catch (err) {
      this._bodyStatistic.isLoading = false;

      Logging.error({
        className: "CustomerStore",
        methodName: "getStatisticForBodyStats",
        message: "Body Statistik konnte nicht geladen werden",
        exception: err,
      });
    }
  };

  fetchAndSetCustomerWorkoutStats = async (args: {
    customerID: string;
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._customerWorkoutStatsDataList != null &&
        this._customerWorkoutStatsDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._customerWorkoutStatsDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._customerWorkoutStatsDataList.pageIndex += 1;
      } else {
        this._customerWorkoutStatsDataList.pageIndex = 0;
        this._customerWorkoutStatsDataList.data = [];
      }

      this._customerWorkoutStatsDataList.isLoading = true;

      const workoutStats = await HttpWorkoutStatsService.getInstance().find({
        query: {
          filter: JSON.stringify({
            user: args.customerID,
          }),
        },
      });

      if (workoutStats == null) {
        this._customerWorkoutStatsDataList.isLoading = false;
        return;
      }

      this.setCustomerWorkoutStats(workoutStats);
      this._customerWorkoutStatsDataList.isLoading = false;
    } catch (err) {
      this._customerWorkoutStatsDataList.isLoading = false;

      Logging.error({
        className: "CustomerStore",
        methodName: "fetchAndSetCustomerWorkoutStats",
        message: "Workouts konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default CustomerStore;
