import SidebarNavigation from "components/navigation.components/sidebar.component/sidebar.component";
import MainLayout from "layouts/main.layout/main.layout";
import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import StudioStore from "stores/studio.store";

interface DashboardPageProps {
  studioStore?: StudioStore;
}

const DashboardPage = ({ studioStore }: DashboardPageProps): JSX.Element => {
  const { studioID } = useParams();

  useEffect(() => {
    studioStore?.getUserStudios();
  }, [studioID]);

  return (
    <MainLayout sideBars={[SidebarNavigation({})]}>
      <Outlet />
    </MainLayout>
  );
};

export default inject("studioStore")(observer(DashboardPage));
