import React, { useState, useEffect } from "react";
import "./asset.selection.modal.component.scss";
import { inject, observer } from "mobx-react";
import { ModalStore } from "stores/modal.store";
import AssetStore from "stores/asset.store";
import { Asset } from "schemas/asset.schemas/asset.schema";
import classNames from "classnames";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { v4 as uuidv4 } from "uuid";

import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import Image from "components/image.component/image.component";
import TagFilterBarComponent, {
  FilterTag,
} from "components/input.components/tag.filter.bar.component/tag.filter.bar.component";

enum LibraryTypes {
  IMAGES = "IMAGES",
  UPLOAD = "UPLOAD",
}

enum AssetSet {
  ALL = "ALL",
  PERSONAL = "PERSONAL",
}

interface AssetSelectionModalProps {
  modalStore?: ModalStore;
  assetStore?: AssetStore;
}

const AssetSelectionModal = ({
  modalStore,
  assetStore,
}: AssetSelectionModalProps): JSX.Element => {
  const assets = assetStore?.assets?.data;
  const selectedAssets = assetStore?.currentSelectedAssets;
  const uploadedAssets = assetStore?.uploadedAssets;
  const selectedUploadedAssets = assetStore?.selectedUploadedAssets;

  const { onSave, folder, assetSelectionLimit } = modalStore?.customData || {};
  const { t } = useTranslation();
  const translate = createTranslate(t, "assetSelectionModal");

  const [libType, setLibType] = useState(LibraryTypes.IMAGES);
  const [assetTypeSet, setAssetTypeSet] = useState(AssetSet.ALL);

  const buttonTags: FilterTag[] = [
    { key: AssetSet.ALL, label: translate("all") },
    { key: AssetSet.PERSONAL, label: translate("myImages") },
  ];

  useEffect(() => {
    if (libType === LibraryTypes.IMAGES) {
      getAssets();
    }
  }, [libType, assetTypeSet]);

  useEffect(() => {
    return () => {
      assetStore?.clearAllAssets();
    };
  }, []);

  const getButtonTags = (): FilterTag[] => {
    return buttonTags;
  };

  const getAssets = async (): Promise<void> => {
    const isAll = assetTypeSet === AssetSet.ALL;
    await assetStore?.fetchAndSetAssets({ isAll });
  };

  const removeAsset = async (assetId: string): Promise<void> => {
    const isAll = assetTypeSet === AssetSet.ALL;
    await assetStore?.removeAsset({ assetId, isAll });
  };

  const transformToAssets = (files: any): Asset[] => {
    return files.map((file: any) => {
      return {
        _id: uuidv4(),
        url: file?.path,
        folder,
      };
    });
  };

  const handleButtonTagsChange = (newButtonTags: FilterTag[]): void => {
    setAssetTypeSet(newButtonTags[0].key as AssetSet);
  };

  const setInitialButtonTags = (): FilterTag[] => {
    return buttonTags.filter((tag: FilterTag) => tag.key === assetTypeSet);
  };

  const addSelectedAsset = (asset: Asset): void => {
    if (!selectedAssets) {
      assetStore?.setCurrentSelectedAssets([asset]);
    } else {
      if (selectedAssets.length === assetSelectionLimit) {
        return;
      }

      selectedAssets.push(asset);
      assetStore?.setCurrentSelectedAssets(selectedAssets);
    }
  };

  const removeSelectedAsset = (asset: Asset): void => {
    const selectedAssetIndex: number | undefined = selectedAssets?.findIndex(
      (item: Asset) => item._id === asset._id
    );

    if (selectedAssetIndex !== undefined) {
      selectedAssets?.splice(selectedAssetIndex, 1);
      assetStore?.setCurrentSelectedAssets(selectedAssets as Asset[]);
    }
  };

  const checkAssetSelected = (asset: Asset): boolean => {
    return !!selectedAssets?.find((item: Asset) => item._id === asset._id);
  };

  const setSelectedAssetsNumber = (): string => {
    if (selectedAssets?.length) {
      return `(${selectedAssets.length.toString()})`;
    }
    if (selectedUploadedAssets?.length) {
      return `(${selectedUploadedAssets.length.toString()})`;
    }
    return "";
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        showBorder
        tabs={[
          <Tab
            label={translate("myImages")}
            key="images-tab"
            active={libType === LibraryTypes.IMAGES}
            onClick={() => {
              setLibType(LibraryTypes.IMAGES);
              assetStore?.clearSelectedUploadedAssets();
            }}
          />,
          <Tab
            label={translate("upload")}
            key="upload-tab"
            active={libType === LibraryTypes.UPLOAD}
            onClick={() => {
              setLibType(LibraryTypes.UPLOAD);
              assetStore?.clearSelectedAssets();
            }}
          />,
        ]}
      ></LinkTabs>
    );
  };

  const _buildMyImagesContainer = (): JSX.Element => {
    return (
      <Column className="container-wrapper">
        <TagFilterBarComponent
          tags={getButtonTags()}
          onTagSelectionChange={handleButtonTagsChange}
          singleSelection={true}
          initialSelectedTags={setInitialButtonTags()}
          className="button-tags-bar"
        />
        <PageContainer className="content-container">
          <Wrap justifyContent="flex-start" gap={15}>
            {assets?.map((item: any, index: number) => {
              return _buildTemplateItem(item, index);
            })}
          </Wrap>
        </PageContainer>
      </Column>
    );
  };

  const _buildTemplateItem = (item: any, index: number): JSX.Element => {
    const templateImageClassName = classNames({
      "template-image-container": true,
      "template-image-container--selected": checkAssetSelected(item),
    });
    const itemIconClassName = classNames({
      "templait-item-icon": true,
      "templait-item-icon--close": !checkAssetSelected(item),
      "templait-item-icon--check": checkAssetSelected(item),
    });

    return (
      <div
        key={item._id}
        className={templateImageClassName}
        onClick={() => {
          if (checkAssetSelected(item)) {
            removeSelectedAsset(item);
          } else {
            addSelectedAsset(item);
          }
        }}
      >
        {checkAssetSelected(item) ? (
          <FontAwesomeIcon className={itemIconClassName} icon={faCheck} />
        ) : (
          <FontAwesomeIcon
            onClick={(event) => {
              event.stopPropagation();
              modalStore?.showDeleteAlert({
                t,
                onConfirm: async () => await removeAsset(item._id),
              });
            }}
            className={itemIconClassName}
            icon={faXmark}
          />
        )}
        <Image className="template-image" imageUrl={item.url} />
      </div>
    );
  };

  const _buildUploadContainer = (): JSX.Element => {
    return (
      <Column className="container-wrapper">
        <Row alignItems="flex-start" className="mt-10 mb-20">
          <FileUpload
            className="upload-container"
            aspectRatio={1 / 1}
            folder={folder}
            fileUrl={getProperty({}, "coverImageUrl")}
            onFilesUploaded={(files) => {
              if (files && files.length > 0) {
                const assets = transformToAssets(files);

                if (uploadedAssets) {
                  let uploaded = assetStore?.uploadedAssets;
                  uploaded = [...uploaded, ...assets];
                  assetStore?.setUploadedAssets(uploaded);
                } else {
                  assetStore?.setUploadedAssets(assets);
                }
              }
            }}
            isAssetsList={true}
            assetSelectionLimit={assetSelectionLimit}
          />
        </Row>
      </Column>
    );
  };

  return (
    <ComponentWrapper title="Library" className="asset-modal-wrapper">
      <FormWrapper className="form-wrapper">
        {_buildTabs()}

        {libType === LibraryTypes.IMAGES && _buildMyImagesContainer()}
        {libType === LibraryTypes.UPLOAD && _buildUploadContainer()}
      </FormWrapper>
      <Row alignItems="flex-start" className="mt-20 mb-10">
        <FilledButton
          className="asset-modal-add-button"
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          disabled={!(selectedAssets?.length || selectedUploadedAssets?.length)}
          type="button"
          label={
            !assetSelectionLimit || assetSelectionLimit > 1
              ? `${setSelectedAssetsNumber()} ${translate("add")}`
              : translate("add")
          }
          color="primary"
          onClick={() => {
            if (selectedAssets?.length) {
              onSave(selectedAssets[0]);
            }
            if (selectedUploadedAssets?.length) {
              onSave(selectedUploadedAssets[0]);
            }
            assetStore?.clearAllAssets();
            modalStore?.closeModal();
          }}
        ></FilledButton>
      </Row>
    </ComponentWrapper>
  );
};

export default inject(
  "modalStore",
  "assetStore"
)(observer(AssetSelectionModal));
