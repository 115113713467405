import React from "react";
import Column from "components/general.compoenents/column.component/column.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import GlobalTrainingPlanList from "components/table.components/global.training.plan.list.component/global.training.plan.list.component";
import { ContainerSizes } from "globals/enums/global.enum";
import { filterAndSortTrainingPlans } from "globals/helpers/global.helper";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import CustomerStore from "stores/customer.store";
import { ModalStore, ModalType } from "stores/modal.store";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import { navigationHelper } from "globals/helpers/navigation.helper";
import LinkButton from "components/input.components/link.button.component/link.button.component";

interface TrainingPlansOverViewTabProps {
  customerStore?: CustomerStore;
  modalStore?: ModalStore;
}

const TrainingPlansOverViewTab = ({
  customerStore,
  modalStore,
}: TrainingPlansOverViewTabProps): JSX.Element => {
  const navigate = navigationHelper("management/members");
  const { studioID, customerID } = useParams();

  const [activePlanTab, setActivePlanTab] = React.useState("TRAINER_TAB");
  const trainingPlans = customerStore?.customerTrainingPlans?.data ?? [];

  // filter training plans by type (user or customer) and sort them by modified date
  const customerTrainingPlans = filterAndSortTrainingPlans(
    trainingPlans,
    "CUSTOMER"
  );
  const userTrainingPlans = filterAndSortTrainingPlans(trainingPlans, "USER");

  const _buildTrainingPlanList = (): JSX.Element => {
    return (
      <ComponentWrapper
        className="mb-25"
        title="Training Plans"
        actions={
          <SizedContainer size={ContainerSizes.L}>
            <Row justifyContent="flex-end" className="mobile-row-break">
              <LinkButton
                className="mr-20"
                noPadding
                label="Hinzufügen"
                onClick={() => {
                  if (studioID != null && customerID != null) {
                    navigate(`${customerID}/training-plans/new`);
                  }
                }}
              />
              <LinkButton
                noPadding
                label="Aus Vorlage erstellen"
                onClick={() => {
                  modalStore?.openModal(
                    ModalType.TRAINING_PLAN_SELECTION_MODAL
                  );
                }}
              />
            </Row>
          </SizedContainer>
        }
      >
        <FormWrapper className="p-10">
          <LinkTabs
            tabs={[
              <Tab
                active={activePlanTab === "TRAINER_TAB"}
                label={"Trainer Pläne"}
                onClick={() => {
                  setActivePlanTab("TRAINER_TAB");
                }}
                key={"TRAINER_TAB"}
              />,
              <Tab
                active={activePlanTab === "USER_TAB"}
                label={"User Pläne"}
                onClick={() => {
                  setActivePlanTab("USER_TAB");
                }}
                key={"USER_TAB"}
              />,
            ]}
          />
        </FormWrapper>

        <GlobalTrainingPlanList
          disableHeader
          noDataMessage="Vom Kunden wurden noch keine Trainingspläne erstellt"
          trainingPlans={
            activePlanTab === "TRAINER_TAB"
              ? customerTrainingPlans
              : userTrainingPlans
          }
          onClick={(trainingPlan: TrainingPlan) => {
            if (studioID != null && customerID != null) {
              navigate(`${customerID}/training-plans/${trainingPlan._id}`);
            }
          }}
        />
      </ComponentWrapper>
    );
  };

  return <Column>{_buildTrainingPlanList()}</Column>;
};

export default inject(
  "customerStore",
  "modalStore"
)(observer(TrainingPlansOverViewTab));
