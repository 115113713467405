import { Logging } from "globals/helpers/logging.helper";
import { DataItem } from "globals/intefaces/pageination.data.list.interface";
import { makeAutoObservable } from "mobx";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import { Feed } from "schemas/feed.schemas/feed.schema";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import { Customer } from "schemas/customer.schemas/customer.schema";
import { HttpExerciseService } from "services/httpClients/http.exercise.client";
import { HttpFeedService } from "services/httpClients/http.feed.client";
import { HttpTrainingPlanService } from "services/httpClients/http.training.plan.client";
import { HttpCustomerService } from "services/httpClients/http.customer.client";
import RootStore from "./root.store";

export enum SearchTopic {
  ALL = "all",
  EXERCISE = "exercise",
  TRAINING_PLAN = "trainingPlan",
  FEED = "feed",
  CUSTOMER = "customer",
}

export interface GlobalSearchData {
  [SearchTopic.EXERCISE]: DataItem<Exercise>;
  [SearchTopic.FEED]: DataItem<Feed>;
  [SearchTopic.TRAINING_PLAN]: DataItem<TrainingPlan>;
  [SearchTopic.CUSTOMER]: DataItem<Customer>;
}

export interface GlobalSearchTerm {
  [SearchTopic.EXERCISE]: string;
  [SearchTopic.FEED]: string;
  [SearchTopic.TRAINING_PLAN]: string;
  [SearchTopic.CUSTOMER]: string;
  [SearchTopic.ALL]: string;
}

class GlobalSearchStore {
  private stores: RootStore;

  // Properties
  private _searchData: GlobalSearchData = {
    [SearchTopic.EXERCISE]: {
      data: undefined,
      isLoading: false,
    },
    [SearchTopic.TRAINING_PLAN]: {
      data: undefined,
      isLoading: false,
    },
    [SearchTopic.FEED]: {
      data: undefined,
      isLoading: false,
    },
    [SearchTopic.CUSTOMER]: {
      data: undefined,
      isLoading: false,
    },
  };

  private _searchTerm: GlobalSearchTerm = {
    [SearchTopic.EXERCISE]: "",
    [SearchTopic.TRAINING_PLAN]: "",
    [SearchTopic.FEED]: "",
    [SearchTopic.CUSTOMER]: "",
    [SearchTopic.ALL]: "",
  };

  private _globalIsLoading: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setters

  setSearchDataForTopic = (topic: SearchTopic, data: any): void => {
    if (topic !== SearchTopic.ALL) {
      this._searchData[topic].data = data;
    }
  };

  setSearchTermForTopic = (topic: SearchTopic, searchTerm: string): void => {
    if (topic !== SearchTopic.ALL) {
      this._searchTerm[topic] = searchTerm ?? "";
    }
  };

  setIsLoading(value: boolean): void {
    if (!value) {
      // wait a bit before setting the value
      setTimeout(() => {
        this._globalIsLoading = value;
      }, 1000);
    } else {
      this._globalIsLoading = value;
    }
  }

  //! Getters

  get isLoading(): boolean {
    return this._globalIsLoading;
  }

  get searchData(): GlobalSearchData {
    return this._searchData;
  }

  getSearchedDataForTopic = (topic: SearchTopic): DataItem<any> | undefined => {
    if (topic !== SearchTopic.ALL) {
      return this._searchData[topic];
    }
  };

  getSearchedTermForTopic = (topic: SearchTopic): string | undefined => {
    if (topic !== SearchTopic.ALL) {
      return this._searchTerm[topic] ?? "";
    }
  };

  //! Methods

  globalSearch = async (
    searchTerm: string,
    topic: SearchTopic
  ): Promise<void> => {
    try {
      this.setIsLoading(true);

      if (topic !== SearchTopic.ALL) {
        switch (topic) {
          case "exercise":
            await this.searchGlobalForExercises(searchTerm);
            this.setIsLoading(false);
            break;
          case "trainingPlan":
            await this.searchGlobalForTrainingPlans(searchTerm);
            this.setIsLoading(false);
            break;
          case "feed":
            await this.searchGlobalForFeeds(searchTerm);
            this.setIsLoading(false);
            break;
          case "customer":
            await this.searchGlobalForCustomers(searchTerm);
            this.setIsLoading(false);
            break;
        }
      } else {
        this.setSearchTermForTopic(SearchTopic.ALL, searchTerm);

        await this.searchGlobalForTrainingPlans(searchTerm, true);
        await this.searchGlobalForExercises(searchTerm, true);
        await this.searchGlobalForFeeds(searchTerm, true);
        await this.setIsLoading(false);
      }
    } catch (_) {}
  };

  private searchGlobalForTrainingPlans = async (
    searchTerm: string,
    skipSetSearchTerm?: boolean
  ): Promise<void> => {
    try {
      if (this._searchData.trainingPlan.isLoading) return;
      if (searchTerm == null || searchTerm.trim()?.length === 0) return;

      this._searchData.trainingPlan.isLoading = true;

      const plans = await HttpTrainingPlanService.getInstance().search({
        searchTerm: searchTerm.trim(),
      });

      if (plans == null) {
        this._searchData.trainingPlan.isLoading = false;
        return;
      }

      this.setSearchDataForTopic(SearchTopic.TRAINING_PLAN, plans);

      if (!skipSetSearchTerm && plans?.length > 0) {
        this.setSearchTermForTopic(SearchTopic.TRAINING_PLAN, searchTerm);
      }

      this._searchData.trainingPlan.isLoading = false;
    } catch (err) {
      this._searchData.trainingPlan.isLoading = false;

      Logging.error({
        className: "GlobalSearchStore",
        methodName: "searchGlobalForTrainingPlans",
        message: "Suche konnte nicht durchgeführt werden",
        exception: err,
      });
    }
  };

  private searchGlobalForExercises = async (
    searchTerm: string,
    skipSetSearchTerm?: boolean
  ): Promise<Exercise | undefined> => {
    try {
      if (this._searchData.exercise.isLoading) return;
      if (searchTerm == null || searchTerm.trim()?.length === 0) return;

      this._searchData.exercise.isLoading = true;

      const exercises = await HttpExerciseService.getInstance().search({
        searchTerm: searchTerm.trim(),
      });

      if (exercises == null) {
        this._searchData.exercise.isLoading = false;
        return;
      }

      this.setSearchDataForTopic(SearchTopic.EXERCISE, exercises);

      if (!skipSetSearchTerm && exercises?.length > 0) {
        this.setSearchTermForTopic(SearchTopic.EXERCISE, searchTerm);
      }
      this._searchData.exercise.isLoading = false;
    } catch (err) {
      this._searchData.exercise.isLoading = false;

      Logging.error({
        className: "GlobalSearchStore",
        methodName: "searchGlobalForExercises",
        message: "Suche konnte nicht durchgeführt werden",
        exception: err,
      });
    }
  };

  private searchGlobalForFeeds = async (
    searchTerm: string,
    skipSetSearchTerm?: boolean
  ): Promise<Feed | undefined> => {
    try {
      if (this._searchData.feed.isLoading) return;
      if (searchTerm == null || searchTerm.trim()?.length === 0) return;

      this._searchData.feed.isLoading = true;

      const feeds = await HttpFeedService.getInstance().search({
        searchTerm: searchTerm.trim(),
      });

      if (feeds == null) {
        this._searchData.feed.isLoading = false;
        return;
      }

      this.setSearchDataForTopic(SearchTopic.FEED, feeds);

      if (!skipSetSearchTerm && feeds?.length > 0) {
        this.setSearchTermForTopic(SearchTopic.FEED, searchTerm);
      }
      this._searchData.feed.isLoading = false;
    } catch (err) {
      this._searchData.feed.isLoading = false;

      Logging.error({
        className: "GlobalSearchStore",
        methodName: "searchGlobalForFeeds",
        message: "Suche konnte nicht durchgeführt werden",
        exception: err,
      });
    }
  };

  private searchGlobalForCustomers = async (
    searchTerm: string,
    skipSetSearchTerm?: boolean
  ): Promise<Customer | undefined> => {
    try {
      if (this._searchData.feed.isLoading) return;
      if (searchTerm == null || searchTerm.trim()?.length === 0) return;

      this._searchData.feed.isLoading = true;

      const customers = await HttpCustomerService.getInstance().search({
        searchTerm: searchTerm.trim(),
      });

      if (customers == null) {
        this._searchData.feed.isLoading = false;
        return;
      }

      this.setSearchDataForTopic(SearchTopic.CUSTOMER, customers);

      if (!skipSetSearchTerm && customers?.length > 0) {
        this.setSearchTermForTopic(SearchTopic.CUSTOMER, searchTerm);
      }

      this._searchData.feed.isLoading = false;
    } catch (err) {
      this._searchData.feed.isLoading = false;

      Logging.error({
        className: "GlobalSearchStore",
        methodName: "searchGlobalForFeeds",
        message: "Suche konnte nicht durchgeführt werden",
        exception: err,
      });
    }
  };
}

export default GlobalSearchStore;
