import { WidgetConfig } from "@vondot-development/metool_lib";
import FormWidgetIcon from "./form.svg";
import CustomerTrainingDataFormWidget from "./customer.training.data.form.widget.component/customer.training.data.form.widget.component";
import CustomerTrainingDataFormOptionSidebar from "./sidebar.component/customer.training.data.form.sidebar.component";

export const CustomerTrainingDataFormWidgetConfig: WidgetConfig = {
  name: "Training Data Form",
  type: "CUSTOMER_TRAINING_DATA_WIDGET",
  component: CustomerTrainingDataFormWidget,
  icon: FormWidgetIcon,
  canHaveChildren: false,
  sidebarComponent: CustomerTrainingDataFormOptionSidebar,
};
