import React from "react";
import previewIcon from "assets/illustrations/gymo_app_preview.png";
import gymoIcon from "assets/icons/gymo_logo_with_lettering.png";
import { useLocation } from "react-router-dom";
import AuthForm from "components/auth.components/auth.form.component/auth.form.component";
import "./invitation.page.scss";
import Headline from "components/text.components/headline.component/headline.component";
import Row from "components/general.compoenents/row.component/row.component";
import { useTranslation } from "react-i18next";

const InvitationPage = (): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();
  const params = new URLSearchParams(location.search);

  const recipientEmail = params.get("recipientEmail")?.toString();
  const senderName = params.get("senderName")?.toString() ?? "Jemand";
  const studioName = params.get("studioName")?.toString() ?? "Gymo";

  const preparedTitle = t("invitationPage.invitationText", {
    senderName,
    studioName,
  });

  const _buildPreview = (): JSX.Element => {
    return (
      <div className="invitation-preview-container">
        <img className="gymo-logo" src={gymoIcon} />
        <Headline className="invitation-header">{`The biggest smallest\nFitness Software.`}</Headline>
        <img className="invitation-preview-image" src={previewIcon} />
      </div>
    );
  };

  return (
    <Row className="invitation-page">
      <div className="invitation-page-wrapper">
        {_buildPreview()}
        <div className="invitation-form-wrapper">
          <AuthForm
            prefillEmail={recipientEmail}
            title={preparedTitle}
            subTitle={t("invitationPage.instructionText") ?? ""}
          />
        </div>
      </div>
    </Row>
  );
};

export default InvitationPage;
