import { Studio } from "schemas/studio.schemas/studio.schema";
import { systemSchema } from "schemas/system.schema";
import * as yup from "yup";

export const dataAttributeCollectionSchema = yup.object().shape({
  _id: yup.string().notRequired(),
  title: yup.string().required(),
  system: systemSchema.notRequired().default(undefined),
});

export interface DataAttributeCollection
  extends yup.InferType<typeof dataAttributeCollectionSchema> {
  isRuntime: boolean;
  studio: Studio;
}
