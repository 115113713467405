import { Asset } from "schemas/asset.schemas/asset.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpAssetService extends GenericHttpClient<Asset> {
  static _instance: HttpAssetService;
  static getInstance(): HttpAssetService {
    if (this._instance == null) {
      this._instance = new HttpAssetService("/assets");
    }
    return this._instance;
  }
}
