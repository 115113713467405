import { GenericHttpClient } from "./config/http.generic.client";
import { Customer } from "schemas/customer.schemas/customer.schema";

export class HttpCustomerService extends GenericHttpClient<Customer> {
  static _instance: HttpCustomerService;
  static getInstance(): HttpCustomerService {
    if (this._instance == null) {
      this._instance = new HttpCustomerService("/customers");
    }
    return this._instance;
  }
}
