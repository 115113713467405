/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import CustomerStore from "stores/customer.store";
import StudioStore from "stores/studio.store";
import { inject, observer } from "mobx-react";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Column from "components/general.compoenents/column.component/column.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import TextEditor from "components/input.components/text.editor.components/text.editor.component/text.editor.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import { useFormContext } from "react-hook-form";

interface CustomerInfoTabProps {
  customerStore?: CustomerStore;
  studioStore?: StudioStore;
}

const CustomerInfoTab = ({
  customerStore,
  studioStore,
}: CustomerInfoTabProps): JSX.Element => {
  const currentCustomer = customerStore?.currentCustomer?.data;

  const {
    register,
    formState: { errors, isDirty },
  } = useFormContext();

  if (!currentCustomer) return <></>;

  // TODO: Replace with real data

  const sexOptions = [
    {
      label: "Weiblich",
      value: "FEMALE",
    },
    {
      label: "Männlich",
      value: "MALE",
    },
    {
      label: "Divers",
      value: "OTHER",
    },
  ];

  const _buildCustomerDataSection = (): JSX.Element => {
    return (
      <ComponentWrapper title="Kundendaten" className="mb-20">
        <FormWrapper className="mb-20">
          <Row gap={20} className="mobile-row-break">
            <SizedContainer size={ContainerSizes.CUSTOM} customSize={390}>
              <FileUpload
                minHeight={390}
                aspectRatio={undefined}
                folder="profile-images"
                fileUrl={currentCustomer?.profileImageUrl ?? ""}
                validationMessage=""
              />
            </SizedContainer>
            <TextEditor
              minHeight={undefined}
              content={"Kunde bemerkungen..."}
              inputContentChanged={(content: any) => {}}
              className="customer-info-editor"
            />
          </Row>
        </FormWrapper>

        <FormWrapper title="Kontaktdaten">
          <Row gap={20}>
            <OutlinedTextInput
              inputRef={register("firstName")}
              label="Vorname"
            />
            <OutlinedTextInput
              inputRef={register("lastName")}
              label="Nachname"
            />
          </Row>

          <Row gap={20}>
            <OutlinedTextInput label="Titel" />
            <SelectDropDown
              label="Geschlecht"
              items={sexOptions}
              selectedItem={{
                value: "MALE",
                label: "Männlich",
              }}
            />
          </Row>

          <Row gap={20}>
            <OutlinedTextInput label="Organisation" />
            <OutlinedTextInput
              label="Geburtsdatum"
              type="date"
              initialValue={"02.02.1994"}
            />
          </Row>

          <Row gap={20}>
            <OutlinedTextInput inputRef={register("email")} label="Email" />
            <OutlinedTextInput label="Telefonnummer" />
          </Row>
        </FormWrapper>

        <FormWrapper title="Adresse" className="mt-20">
          <Row gap={20}>
            <OutlinedTextInput label="Postleitzahl" />
            <OutlinedTextInput label="Stadt" />
          </Row>

          <Row gap={20}>
            <OutlinedTextInput label="Straße & Hausnummer" />
            <OutlinedTextInput label="Land" />
          </Row>
        </FormWrapper>
      </ComponentWrapper>
    );
  };

  return <Column>{_buildCustomerDataSection()}</Column>;
};

export default inject(
  "customerStore",
  "studioStore"
)(observer(CustomerInfoTab));
