import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import React from "react";
import { useNavigate } from "react-router";

const demoData = [
  {
    _id: "1",
    name: "Kampagne: Neue Powerhouse App Features!",
    type: "EMAIL",
    sentAt: "01.03.2024",
  },
  {
    _id: "2",
    name: "Kampagne: Neue Produkte im Powerhouse!",
    type: "PUSH_NOTIFICATION",
    sentAt: "08.03.2024",
  },
  {
    _id: "3",
    name: "Kampagne: Frühlingsangebote",
    type: "EMAIL",
    sentAt: "15.03.2024",
  },
];

const CampainList = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <ListDataTable
      data={demoData}
      noDataMessage="No active campaigns"
      isLoading={false}
      onClick={(data: any) => {
        if (data.type === "EMAIL") {
          navigate("emails");
        } else {
          navigate("push-notifications");
        }
      }}
      columns={[
        {
          child: <TitleText>Name</TitleText>,
          flex: 2,
        },
        {
          child: <TitleText>Typ</TitleText>,
        },
        {
          child: <TitleText>Gesendet am</TitleText>,
        },
      ]}
      dataTableItemBuilder={(dataItem: any) => {
        return {
          key: dataItem._id,
          children: [
            {
              child: <TitleText>{dataItem.name}</TitleText>,
            },
            {
              child: (
                <RunningText>
                  {dataItem.type === "EMAIL" ? "Email" : "Push Notification"}
                </RunningText>
              ),
            },
            {
              child: <RunningText>{dataItem.sentAt}</RunningText>,
            },
          ],
        } as any;
      }}
    />
  );
};

export default CampainList;
