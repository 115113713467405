import React from "react";
import "./sidebar.component.scss";
import classNames from "classnames";
import SidebarNavigationItem from "../sidebar.item.comonent/sidebar.item.component";
import SidebarItemGroup from "../sidebar.item.group.component/sidebar.item.group.component";
import {
  faClose,
  faUsers,
  faMobileNotch,
  faNewspaper,
  faClipboard,
  faChartNetwork,
  faTags,
  faShop,
  faUserGroup,
  faCalendar,
  faMegaphone,
  faRocket,
} from "@fortawesome/pro-regular-svg-icons";
import GymoLogo from "components/general.compoenents/gymo.logo.component/gymo.logo.component";
import { Navigate, useParams } from "react-router";
import LoggedInUserDropdown from "components/input.components/dropdown.components/logged.in.user.dropdown.component/logged.in.user.dropdown.component";
import Row from "components/general.compoenents/row.component/row.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Column from "components/general.compoenents/column.component/column.component";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";

interface SidebarNavigationProps {
  className?: string;
}

const SidebarNavigation = ({
  className,
}: SidebarNavigationProps): JSX.Element => {
  const { studioID } = useParams();
  const { t } = useTranslation();
  const translate = createTranslate(t, "sidebar");

  const sidebarNavigationClassName = classNames(
    {
      "sidebar-navigation-wrapper": true,
    },
    className
  );

  if (studioID == null) {
    return <Navigate to="/" replace />;
  }

  const getPath = (path: string): string => {
    return `/studios/${studioID}/${path}`;
  };

  const closeMobileNavigation = (): void => {
    const navigation = document.getElementById("navigation-bar");
    if (navigation == null) {
      return;
    }

    navigation.style.display = "none";
  };

  return (
    <div className={sidebarNavigationClassName} id="navigation-bar">
      <div className="sidebar-navigation-body">
        <Row justifyContent="space-between">
          <GymoLogo className="sidebar-header-logo ml-10" size={30} />
          <FontAwesomeIcon
            className="sidebar-header-close hide-on-desktop"
            icon={faClose}
            onClick={closeMobileNavigation}
          />
        </Row>
        <div className="sidebar-navigation-content">
          <SidebarItemGroup label="Studio">
            {/* SUPER ADMIN  */}
            <SidebarNavigationItem
              icon={faTags}
              path={getPath("properties")}
              permissionAlias="DASHBOARD_PROPERTIES"
            >
              Properties
            </SidebarNavigationItem>

            <SidebarNavigationItem
              icon={faUserGroup}
              path={getPath("gymo-customers")}
              permissionAlias="DASHBOARD_GYMO_CUSTOMERS"
            >
              Kunden
            </SidebarNavigationItem>
            {/* -------- */}

            <SidebarNavigationItem
              id="intro-exercises-and-devices"
              icon={faChartNetwork}
              path={getPath("inventory")}
              permissionAlias="DASHBOARD_EXERCISES"
              redirectTo="inventory/exercises"
            >
              {translate("studio.inventory")}
            </SidebarNavigationItem>

            <SidebarNavigationItem
              id="intro-training-plans"
              icon={faClipboard}
              path={getPath("training-plans")}
              permissionAlias="DASHBOARD_PLANS"
            >
              {translate("studio.trainingPlans")}
            </SidebarNavigationItem>
          </SidebarItemGroup>

          <SidebarItemGroup label="App">
            <SidebarNavigationItem
              id="intro-app-configurator"
              icon={faMobileNotch}
              path={getPath("app")}
              permissionAlias="DASHBOARD_APP_CONFIGURATION"
            >
              {translate("app.appConfigurator")}
            </SidebarNavigationItem>

            <SidebarNavigationItem
              id="intro-feed"
              icon={faNewspaper}
              path={getPath("feed")}
              permissionAlias="DASHBOARD_FEEDS"
            >
              {translate("app.feed")}
            </SidebarNavigationItem>
          </SidebarItemGroup>

          <SidebarItemGroup label="Verwalten">
            <SidebarNavigationItem
              id="intro-customers"
              icon={faUsers}
              path={getPath("management")}
              permissionAlias="DASHBOARD_CUSTOMERS"
              redirectTo="management/members"
            >
              {translate("manage.customers")}
            </SidebarNavigationItem>

            <SidebarNavigationItem
              id="intro-customers"
              icon={faRocket}
              path={getPath("leads")}
              permissionAlias="DASHBOARD_CUSTOMERS"
            >
              {translate("manage.leadManagement")}
            </SidebarNavigationItem>

            <SidebarNavigationItem
              id="intro-customers"
              icon={faShop}
              path={getPath("marketplace")}
              redirectTo="marketplace/browse"
              permissionAlias="DASHBOARD_MARKETPLACE"
            >
              {translate("manage.marketplace")}
            </SidebarNavigationItem>
            <SidebarNavigationItem
              id="intro-customers"
              icon={faMegaphone}
              path={getPath("marketing")}
              permissionAlias="DASHBOARD_MARKETPLACE"
            >
              {translate("manage.marketing")}
            </SidebarNavigationItem>
            <SidebarNavigationItem
              onClick={() => {
                window.location.href =
                  "https://booking.gymo.io/projects/665776ae9a6f35547193fdba/calendar";
              }}
              id="intro-customers"
              icon={faCalendar}
              path={getPath("bookings")}
              permissionAlias="DASHBOARD_MARKETPLACE"
            >
              {translate("manage.bookingSystem")}
            </SidebarNavigationItem>
          </SidebarItemGroup>
        </div>
      </div>
      <Column>
        <LoggedInUserDropdown />
      </Column>
    </div>
  );
};

export default SidebarNavigation;
