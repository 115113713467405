import React from "react";
import InfoBox from "components/general.compoenents/info.box.component/info.box.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { ContainerSizes } from "globals/enums/global.enum";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import StudioStore from "stores/studio.store";
import { inject, observer } from "mobx-react";
import Column from "components/general.compoenents/column.component/column.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import { ModalSize, ModalStore, ModalType } from "stores/modal.store";
import { Upload } from "schemas/upload.schema";
import { subscriptionPlans } from "globals/data/subscription.data";
import UserStore from "stores/user.store";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import ProgressBar from "components/general.compoenents/progress.bar.component/progress.bar.component";
import SplitLayout from "layouts/split.layout/split.layout";
import PublishingPreview from "./components/publishing.preview.component";
import Center from "components/general.compoenents/center.component/center.component";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import {
  getProperty,
  setProperty,
} from "globals/helpers/assign.object.keys.helper";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";

interface PublishingTabProps {
  studioStore?: StudioStore;
  modalStore?: ModalStore;
  userStore?: UserStore;
}

const PublishingTab = ({
  studioStore,
  modalStore,
  userStore,
}: PublishingTabProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "appConfiguratorPage.publishing");

  const editingStudio = studioStore?.editingStudio;

  const releaseInProgress = editingStudio?.releaseHistory?.some(
    (release) => release.status !== "RELEASED"
  );

  const saveAppConfig = (): void => {
    studioStore?.saveEditingProject();
  };

  const _buildAppstoreConfiguration = (): JSX.Element => {
    if (studioStore == null || editingStudio == null) {
      return <></>;
    }

    return (
      <form className="studio-setup-form mb-15" noValidate>
        <PageHeader label={translate("pageTitle")}>
          <FilledButton
            key={"publish"}
            label={translate("publishButtonLabel")}
            disabled={releaseInProgress}
            onClick={() => {
              if (
                userStore?.checkIfUserHasPermission({
                  alias: "DASHBOARD_PUBLISH_BRANDED_APP_BUTTON",
                })
              ) {
                studioStore?.publishApp();
              } else {
                modalStore?.openModal(ModalType.SUBSCRIPTION, ModalSize.LARGE, {
                  plan: subscriptionPlans.basic,
                });
              }
            }}
          />
        </PageHeader>
        <ComponentWrapper
          actions={
            <FilledButton
              label={translate("saveButtonLabel")}
              onClick={saveAppConfig}
            />
          }
        >
          <SizedContainer size={ContainerSizes.XL}>
            <FormWrapper className="mb-20">
              <OutlinedTextInput
                label={translate("appNameInputLabel")}
                value={editingStudio.appConfiguration?.name}
                onChange={(value) => {
                  setProperty(editingStudio, "appConfiguration.name", value);

                  studioStore.setEditingStudio({ ...editingStudio });
                }}
              />
            </FormWrapper>
            <FormWrapper>
              <Wrap>
                <SizedContainer className="mr-30" size={ContainerSizes.S}>
                  <FileUpload
                    label={translate("appIconInputLabel")}
                    className="upload-wrap-item mt-15"
                    aspectRatio={1 / 1}
                    folder="logos"
                    fileUrl={getProperty(
                      editingStudio,
                      "appConfiguration.iconUrl"
                    )}
                    onFilesUploaded={(files?: Upload[]) => {
                      if (files != null && files.length > 0) {
                        setProperty(
                          editingStudio,
                          "appConfiguration.iconUrl",
                          files[0].path
                        );

                        studioStore.setEditingStudio({ ...editingStudio });
                      }
                    }}
                  />
                </SizedContainer>
                <SizedContainer size={ContainerSizes.S}>
                  <FileUpload
                    label={translate("splashIconInputLabel")}
                    className="upload-wrap-item mt-15"
                    aspectRatio={1 / 1}
                    folder="logos"
                    fileUrl={getProperty(
                      editingStudio,
                      "appConfiguration.splashIconUrl"
                    )}
                    onFilesUploaded={(files?: Upload[]) => {
                      if (files != null && files.length > 0) {
                        setProperty(
                          editingStudio,
                          "appConfiguration.splashIconUrl",
                          files[0].path
                        );

                        studioStore.setEditingStudio({ ...editingStudio });
                      }
                    }}
                  />
                </SizedContainer>
              </Wrap>
              <InfoBox className="mt-15">{translate("iconInfo")}</InfoBox>
            </FormWrapper>
          </SizedContainer>
        </ComponentWrapper>
      </form>
    );
  };

  const _buildPublishProgress = (): JSX.Element => {
    if (!releaseInProgress) {
      return <></>;
    }

    const currentRelease = editingStudio?.releaseHistory?.find(
      (release) => release.status !== "RELEASED"
    );

    return (
      <ComponentWrapper title={translate("publishingTimeline.title")}>
        <ProgressBar
          items={[
            {
              label: translate("publishingTimeline.waitingForInternalApproval"),
              active:
                currentRelease?.status === "WAITING_FOR_INTERNAL_APPROVAL",
            },
            {
              label: translate("publishingTimeline.inInternalApproval"),
              active: currentRelease?.status === "IN_INTERNAL_APPROVAL",
            },
            {
              label: translate("publishingTimeline.waitingForExternalApproval"),
              active:
                currentRelease?.status === "WAITING_FOR_EXTERNAL_APPROVAL",
            },
            {
              label: translate("publishingTimeline.released"),
              active: currentRelease?.status === "RELEASED",
            },
          ]}
        />

        <InfoBox className="mt-20">
          {translate("publishingTimeline.info")}
        </InfoBox>
      </ComponentWrapper>
    );
  };

  return (
    <SplitLayout
      leftGrow={2}
      leftChild={
        <Column>
          {_buildAppstoreConfiguration()}
          {_buildPublishProgress()}
        </Column>
      }
      rightChild={
        <Center className="hide-on-mobile">
          <PublishingPreview />
        </Center>
      }
    />
  );
};

export default inject(
  "studioStore",
  "modalStore",
  "userStore"
)(observer(PublishingTab));
