import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { toast } from "react-toastify";
import DataAttributeStore from "stores/data.attribute.store";
import { DataAttribute } from "schemas/data.attribute.schemas/data.attribute.schema";
import { inject, observer } from "mobx-react";
import { getAcceptProp } from "globals/helpers/data.attribute.helper";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import FilledCheckbox from "components/input.components/filled.checkbox.component/filled.checkbox.component";
import { useCustomContext } from "../attribute.form.context.component/attribute.form.context.component";
import { get } from "lodash";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";

interface DataAttributeFormProps {
  dataAttributeStore?: DataAttributeStore;
  onSubmit?: (data: any) => void;
  onUploadStatusChange?: (isUploading: boolean) => void;
  formID?: string;
  renderOnlyFields?: string[];
  fieldsToIgnore?: string[];
  title?: string;
}

const DataAttributeForm = ({
  onSubmit,
  onUploadStatusChange,
  dataAttributeStore,
  formID,
  renderOnlyFields,
  fieldsToIgnore = [],
  title,
}: DataAttributeFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    clearErrors,
    formState: { errors, isDirty },
  } = useFormContext();

  const { attributes, isLoading } = useCustomContext();

  const filteredAttributes = renderOnlyFields
    ? attributes.data.filter((attribute) =>
        renderOnlyFields.includes(attribute.fieldID)
      )
    : attributes.data;

  const [isUploading, setIsUploading] = useState(false);

  const buildFieldPath = (attribute: DataAttribute, basePath = ""): string => {
    let pathSegment = attribute.fieldID;

    if (attribute?.path != null && attribute.path !== "") {
      pathSegment = `${attribute.path}.${pathSegment}`;
    }

    if (basePath != null && basePath !== "") {
      return `${basePath}.${pathSegment}`;
    }

    return pathSegment;
  };

  const registerInput = (attribute: DataAttribute, basePath = ""): any => {
    const fieldPath = buildFieldPath(attribute, basePath);
    return register(fieldPath);
  };

  const setValueAndMarkDirty = (
    attribute: DataAttribute,
    value: any,
    basePath = ""
  ): void => {
    const path = buildFieldPath(attribute, basePath);
    setValue(path, value, { shouldDirty: true });
    trigger(path);
  };

  const getFieldError = (
    attribute: DataAttribute,
    basePath = ""
  ): string | undefined => {
    const fieldPath = buildFieldPath(attribute, basePath);
    const error = get(errors, `${fieldPath}.message`) as string | undefined;
    return error;
  };

  const handleUploadStatusChange = (isUploading: boolean): void => {
    setIsUploading(isUploading);
    if (onUploadStatusChange) onUploadStatusChange(isUploading);
  };

  const handleOnSubmit = (data: any): void => {
    // pass the formatted data to the onSubmit callback
    if (onSubmit) {
      onSubmit(data);
    }
  };

  // render the input field based on the dataAttributeType
  const renderInputField = (
    attribute: DataAttribute,
    basePath = ""
  ): JSX.Element => {
    // prevent rendering of fields that should only be shown in a new tab
    if (
      fieldsToIgnore?.includes(attribute.fieldID) ||
      (renderOnlyFields == null &&
        (attribute?.attributeSettings as any)?.showInNewTab)
    ) {
      return <></>;
    }

    switch (attribute.dataAttributeType) {
      case "string":
      case "number":
      case "date": {
        const type =
          attribute.dataAttributeType === "string"
            ? "text"
            : attribute.dataAttributeType;
        return (
          <OutlinedTextInput
            type={type}
            disabled={attribute?.readOnly ?? false}
            inputRef={registerInput(attribute, basePath)}
            label={attribute.label}
            validationMessage={getFieldError(attribute, basePath)}
          />
        );
      }
      case "upload":
        return (
          // TODO fix this behaviour -> Upload loses state if change tab

          <FileUpload
            label={attribute.label}
            folder="device-brands" // TODO
            isUploading={handleUploadStatusChange}
            accept={getAcceptProp(
              (attribute?.attributeSettings as any)?.restrictions ?? undefined
            )}
            inputRef={registerInput(attribute, basePath)}
            validationMessage={getFieldError(attribute, basePath)}
            onFilesUploaded={(files) => {
              if (files && files.length > 0) {
                setValueAndMarkDirty(attribute, files[0].path);
                clearErrors(`${attribute.fieldID}`);
              } else {
                setValueAndMarkDirty(attribute, undefined);
              }
            }}
          />
        );

      case "boolean": {
        return (
          <FilledCheckbox
            isDisabled={attribute?.readOnly ?? false}
            {...registerInput(attribute, basePath)}
            label={attribute.label}
          />
        );
      }

      case "object": {
        const nestedAttributes = dataAttributeStore?.getCollectionAttributes(
          (attribute.attributeSettings as any).referenceTo?._id
        )?.data;

        const nestedFieldPath = buildFieldPath(attribute, basePath);

        return (
          <div>
            {nestedAttributes?.map((nestedAttr: DataAttribute) =>
              renderInputField(nestedAttr, nestedFieldPath)
            )}
          </div>
        );
      }

      case "array": {
        const nestedAttributes = dataAttributeStore?.getCollectionAttributes(
          (attribute.attributeSettings as any).referenceTo?._id
        )?.data;

        const nestedFieldPath = buildFieldPath(attribute, basePath);

        return (
          <div>
            {nestedAttributes?.map((nestedAttr: DataAttribute) =>
              renderInputField(nestedAttr, nestedFieldPath)
            )}
          </div>
        );
      }

      default:
        return <div>Unsupported type {attribute.dataAttributeType}</div>;
    }
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <SizedContainer size={ContainerSizes.XXL}>
      <ComponentWrapper title={title}>
        <FormWrapper className="mt-25">
          <SizedContainer size={ContainerSizes.L}>
            <form
              id={formID}
              onSubmit={handleSubmit(handleOnSubmit, (errors) => {
                toast.error("error"); // TODO
              })}
            >
              {filteredAttributes?.map((attribute: DataAttribute) =>
                renderInputField(attribute)
              )}

              {formID == null && (
                <FilledButton
                  type="submit"
                  label="Submit"
                  disabled={isUploading || !isDirty}
                />
              )}
            </form>
          </SizedContainer>
        </FormWrapper>
      </ComponentWrapper>
    </SizedContainer>
  );
};

export default inject("dataAttributeStore")(observer(DataAttributeForm));
