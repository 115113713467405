import React from "react";
import Column from "components/general.compoenents/column.component/column.component";
import { createTranslate } from "globals/helpers/global.helper";
import { useTranslation } from "react-i18next";
import ContentLibraryStore from "stores/content.library.store";
import { inject, observer } from "mobx-react";
import { ContentLibrary } from "schemas/content.library.schemas/content.library.schema";
import { navigationHelper } from "globals/helpers/navigation.helper";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import BigMarketPlaceCard, {
  BigMarketPlaceCardSkeleton,
} from "pages/marketplace.pages/components/big.marketplace.cardcomponent/big.marketplace.card.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";

interface MarketplaceContentLibraryPageProps {
  showHeader?: boolean;
  contentLibraryStore?: ContentLibraryStore;
}

const MarketplaceContentLibraryPage = ({
  showHeader = false,
  contentLibraryStore,
}: MarketplaceContentLibraryPageProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, `marketplacePage.overview.content`);
  const navigate = navigationHelper();

  const handleAddOnClick = (contentLibraryID: string): void => {
    navigate(`marketplace/content/${contentLibraryID}`);
  };

  const _buildContentLibraryItems = (): JSX.Element => {
    if (contentLibraryStore?.contentLibraries.isLoading) {
      return <BigMarketPlaceCardSkeleton count={6} />;
    }

    return (
      <>
        {contentLibraryStore?.contentLibraries.data.map(
          (contentLibrary: ContentLibrary) => {
            return (
              <BigMarketPlaceCard
                key={contentLibrary._id}
                onClick={() => handleAddOnClick(contentLibrary?._id ?? "")}
                title={contentLibrary.title?.de}
                description={"description"}
                imageUrl={contentLibrary?.coverImageUrl}
              />
            );
          }
        )}
      </>
    );
  };

  return (
    <Column className="mt-30 mb-30">
      {showHeader && (
        <LinkButton
          width="fit-content"
          fontSize="headline"
          label={translate("introTitle")}
          color="secondary"
          icon={faChevronRight}
          noPadding
          wrapperClassName="mb-10"
          onClick={() => navigate("marketplace/content")}
        />
      )}
      <Wrap gap={20}>{_buildContentLibraryItems()}</Wrap>
    </Column>
  );
};

export default inject("contentLibraryStore")(
  observer(MarketplaceContentLibraryPage)
);
