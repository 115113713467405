import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { ModalStore } from "stores/modal.store";
import StudioStore from "stores/studio.store";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { useNavigate } from "react-router-dom";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import MetoolStore from "stores/metool.store";
import { Page } from "schemas/metool.schemas/page.schema";
import { useTranslation } from "react-i18next";
import Row from "components/general.compoenents/row.component/row.component";
import Headline from "components/text.components/headline.component/headline.component";

interface PageConfigurationTabPageProps {
  studioStore?: StudioStore;
  modalStore?: ModalStore;
  metoolStore?: MetoolStore;
}

const PageConfigurationTabPage = ({
  studioStore,
  modalStore,
  metoolStore,
}: PageConfigurationTabPageProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const pages = metoolStore?.pages?.data;

  useEffect(() => {
    metoolStore?.fetchAndSetPages({});
  }, []);

  return (
    <ComponentWrapper
      noPadding
      outsideActions={
        <Row
          className="mb-20"
          justifyContent="space-between"
          alignItems="center"
        >
          <Headline>localize me</Headline>
        </Row>
      }
    >
      <ListDataTable
        onClick={(page: Page) => {
          navigate(`/pages/${page?.pageID}/edit`);
        }}
        columns={[
          {
            child: <TitleText tableHeader>{t("metoolPages.title")}</TitleText>,
          },
          {
            child: <TitleText tableHeader>{t("metoolPages.views")}</TitleText>,
          },
          {
            child: <TitleText tableHeader>{t("metoolPages.layout")}</TitleText>,
          },
        ]}
        data={pages ?? []}
        dataTableItemBuilder={(page: Page) => {
          return {
            key: page?.pageID,
            children: [
              {
                child: <RunningText>{page?.name}</RunningText>,
              },
              {
                child: <RunningText>{page?.views?.length}</RunningText>,
              },
              {
                child: (
                  <RunningText>{page?.layoutConfig?.layoutID}</RunningText>
                ),
              },
            ],
          };
        }}
        noDataMessage={t("metoolPages.settings.noPages") ?? ""}
      />
    </ComponentWrapper>
  );
};

export default inject(
  "studioStore",
  "modalStore",
  "metoolStore"
)(observer(PageConfigurationTabPage));
