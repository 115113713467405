/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable react/react-in-jsx-scope */

import {
  ControlledBoard,
  moveCard,
  KanbanBoard,
  OnDragEndNotification,
  Card,
  Column,
} from "@caldwell619/react-kanban";
import "@caldwell619/react-kanban/dist/styles.css";
import { useEffect, useState } from "react";
import classNames from "classnames";
import "./kanban.board.component.scss";
import AutoSizer from "react-virtualized-auto-sizer";

interface AbstractLanbanBoardProps {
  className?: string;
  columns: {
    id: string;
    label: string;
    key: string;
  }[];
  columnsClassNames?: string[];
  items: {
    id: string;
    [k: string]: any;
  }[];
  relateField: string;
  itemBuilder: (card: Card) => JSX.Element;
  columnHeaderBuilder?: (
    column: Column<{
      id: string;
      [k: string]: any;
    }>
  ) => JSX.Element;
  onItemMoveCallback?: (
    item: {
      [k: string]: any;
      id: string;
    },
    source:
      | {
          fromPosition: number;
          fromColumnId?: string | number | undefined;
        }
      | undefined,
    destination:
      | {
          toPosition?: number | undefined;
          toColumnId?: string | number | undefined;
        }
      | undefined
  ) => void;
}

const AbstractKanbanBoard = ({
  className,
  columns,
  columnsClassNames,
  items,
  relateField,
  itemBuilder,
  columnHeaderBuilder,
  onItemMoveCallback,
}: AbstractLanbanBoardProps): JSX.Element => {
  const [board, setBoard] = useState<KanbanBoard<any>>();

  useEffect(() => {
    if (columns.length > 0 && items.length > 0) {
      setBoard(() => ({
        columns: columns.map(({ id, label, key }) => {
          return {
            id: id,
            title: label,
            cards: items.filter((data) => data[relateField] === key),
          };
        }),
      }));
    }
  }, [columns, items]);

  useEffect(() => {
    if (columnsClassNames && columnsClassNames.length > 0) {
      const kanbanColumns = document.getElementsByClassName(
        "react-kanban-column"
      );
      columnsClassNames.forEach((clsName, i) => {
        kanbanColumns[i]?.classList.add(clsName);
      });
    }
  }, [board]);

  const handleCardMove: OnDragEndNotification<{
    id: string;
    [k: string]: any;
  }> = (_card, source, destination) => {
    // NOTE: Update item here for 100% status represenation
    _card[relateField] = columns.find(
      ({ id }) => id === destination?.toColumnId
    )?.key;
    setBoard((b) => moveCard(b, source, destination));
    if (onItemMoveCallback) {
      onItemMoveCallback(_card, source, destination);
    }
  };

  const kanbanWrapperClasses = classNames("kanban-board-wrapper", className);

  return (
    <div className={kanbanWrapperClasses}>
      <AutoSizer>
        {({ width, height }: { width: number; height: number }) => (
          <div style={{ width, height }}>
            {board && (
              <ControlledBoard
                onCardDragEnd={handleCardMove}
                allowAddCard={false}
                allowRemoveCard={false}
                allowRemoveColumn={false}
                disableColumnDrag={true}
                renderColumnHeader={(column) => (
                  <div className="kanban-column-header-container">
                    {columnHeaderBuilder
                      ? columnHeaderBuilder(column)
                      : column.title}
                  </div>
                )}
                renderCard={(card) => (
                  <div className={`kanban-card-container`}>
                    {itemBuilder(card)}
                  </div>
                )}
              >
                {board}
              </ControlledBoard>
            )}
          </div>
        )}
      </AutoSizer>
    </div>
  );
};

export default AbstractKanbanBoard;
