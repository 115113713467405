import {
  faDisplay,
  faHandPointer,
  faHardDrive,
  faMobileNotch,
  faTabletScreenButton,
} from "@fortawesome/pro-regular-svg-icons";
import {
  CoreResource,
  CoreRestQuerConfig,
  DataSourceType,
} from "@vondot-development/metool_lib";
import { getAccessToken, getResource } from "globals/helpers/storage.helper";

export const coreResources: CoreResource[] = [
  {
    _id: "baseResource",
    resourceID: "baseResource",
    title: "Gymo Backend",
    type: DataSourceType.REST_API,
    baseUrl: "https://gymo-server-fibo.onrender.com/api/admin",
    core: true,
    defaultHeaders: [
      { key: "Content-Type", value: "application/json" },
      { key: "Accept", value: "application/json" },
    ],
    description: "This is the base resource",
    requestInterceptors: [
      {
        fulfilled: (config) => {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${getAccessToken()}`,
            gymo_resource: getResource(),
          };

          return config;
        },
      },
    ],
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const coreQueryConfig: CoreRestQuerConfig = {
  createQuery: {
    _id: "createQuery",
    queryID: "createQuery",
    title: "Create Query",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "POST",
    url: "/queries",
    core: true,
  },

  deleteQuery: {
    _id: "deleteQuery",
    queryID: "deleteQuery",
    resourceID: "baseResource",
    title: "Delete Query",
    type: DataSourceType.REST_API,
    method: "DELETE",
    url: "/queries",
    core: true,
  },
  getQueries: {
    _id: "getQueries",
    queryID: "getQueries",
    title: "Get Queries",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "GET",
    url: "/queries",
    core: true,
  },
  getQueriesById: {
    _id: "getQueriesById",
    queryID: "getQueriesById",
    resourceID: "baseResource",
    title: "Get Query by ID",
    type: DataSourceType.REST_API,
    method: "GET",
    url: "/queries/:queryID",
    core: true,
  },
  updateQuery: {
    _id: "updateQuery",
    queryID: "updateQuery",
    resourceID: "baseResource",
    title: "Update Query",
    type: DataSourceType.REST_API,
    method: "PUT",
    url: "/queries/:queryID",
    core: true,
  },

  getResources: {
    _id: "getResources",
    queryID: "getResources",
    resourceID: "baseResource",
    title: "Get Resources",
    type: DataSourceType.REST_API,
    method: "GET",
    url: "/resources",
    core: true,
  },

  getResourcesById: {
    _id: "getResourcesById",
    queryID: "getResourcesById",
    resourceID: "baseResource",
    title: "Get Resource by ID",
    type: DataSourceType.REST_API,
    method: "GET",
    url: "/resources/:resourceID",
    core: true,
  },

  updateResource: {
    _id: "updateResource",
    queryID: "updateResource",
    resourceID: "baseResource",
    title: "Update Resource",
    type: DataSourceType.REST_API,
    method: "PUT",
    url: "/resources/:resourceID",
    core: true,
  },

  deleteResource: {
    _id: "deleteResource",
    queryID: "deleteResource",
    title: "Delete Resource",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "DELETE",
    url: "/resources",
    core: true,
  },

  createResource: {
    _id: "createResource",
    queryID: "createResource",
    title: "Create Resource",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "POST",
    url: "/resources",
    core: true,
  },

  getWidgets: {
    _id: "getWidgets",
    queryID: "getWidgets",
    title: "Get Widgets",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "GET",
    url: "/widgets",
    core: true,
  },

  createWidget: {
    _id: "createWidget",
    queryID: "createWidget",
    title: "Create Widget",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "POST",
    url: "/widgets",
    core: true,
  },

  deleteWidget: {
    _id: "deleteWidget",
    queryID: "deleteWidget",
    title: "Delete Widget",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "DELETE",
    url: "/widgets/:widgetID",
    core: true,
  },

  getWidgetsById: {
    _id: "getWidgetsById",
    queryID: "getWidgetsById",
    title: "Get Widget by ID",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "GET",
    url: "/widgets/:widgetID",
    core: true,
  },

  updateWidget: {
    _id: "updateWidget",
    queryID: "updateWidget",
    title: "Update Widget",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "PUT",
    url: "/widgets/:widgetID",
    core: true,
  },

  getViews: {
    _id: "getViews",
    queryID: "getViews",
    title: "Get Views",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "GET",
    url: "/views",
    core: true,
  },

  getViewById: {
    _id: "getViewById",
    queryID: "getViewById",
    title: "Get View by ID",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "GET",
    url: "/views/:viewID",
    core: true,
  },

  deleteView: {
    _id: "deleteView",
    queryID: "deleteView",
    title: "Delete View",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "DELETE",
    url: "/views/:viewID",
    core: true,
  },

  updateView: {
    _id: "updateView",
    queryID: "updateView",
    title: "Update View",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "PUT",
    url: "/views/:viewID",
    core: true,
  },

  createView: {
    _id: "createView",
    queryID: "createView",
    title: "Create View",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "POST",
    url: "/views",
    core: true,
  },

  getPages: {
    _id: "getPages",
    queryID: "getPages",
    title: "Get Pages",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "GET",
    url: "/pages",
    core: true,
  },

  getPageById: {
    _id: "getPageById",
    queryID: "getPageById",
    title: "Get Page by ID",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "GET",
    url: "/pages/:pageID",
    core: true,
  },

  createPage: {
    _id: "createPage",
    queryID: "createPage",
    title: "Create Page",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "POST",
    url: "/pages",
    core: true,
  },

  deletePage: {
    _id: "deletePage",
    queryID: "deletePage",
    title: "Delete Page",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "DELETE",
    url: "/pages/:pageID",
    core: true,
  },

  updatePage: {
    _id: "updatePage",
    queryID: "updatePage",
    title: "Update Page",
    resourceID: "baseResource",
    type: DataSourceType.REST_API,
    method: "PUT",
    url: "/pages/:pageID",
    core: true,
  },
};

// TODO export layout config type
export const layoutConfig = {
  nested: {
    large: {
      cols: 48,
      rowHeight: 20,
      icon: faHandPointer,
      title: "Large",
      breakpoint: 1200,
    },
    medium: {
      cols: 24,
      rowHeight: 20,
      icon: faHardDrive,
      title: "Medium",
      breakpoint: 900,
    },
    small: {
      cols: 16,
      rowHeight: 20,
      icon: faHardDrive,
      title: "Small",
      breakpoint: 440,
    },
  },
  root: {
    large: {
      cols: 24,
      rowHeight: 20,
      icon: faDisplay,
      title: "Large",
      breakpoint: 1200,
    },
    medium: {
      cols: 12,
      rowHeight: 20,
      icon: faTabletScreenButton,
      title: "Medium",
      breakpoint: 900,
    },
    small: {
      cols: 8,
      rowHeight: 20,
      icon: faMobileNotch,
      title: "Small",
      breakpoint: 440,
    },
  },
};
