import { inject, observer } from "mobx-react";
import { Navigate, Outlet } from "react-router-dom";
import UserStore from "../stores/user.store";
import React, { useEffect, useState } from "react";

interface ProtectedRouteProps {
  userStore?: UserStore;
}

const ProtectedRoute = ({
  userStore,
}: ProtectedRouteProps): React.ReactElement | null => {
  const [isCheckingUser, setIsCheckingUser] = useState(true);
  const user = userStore?.user;

  useEffect(() => {
    initialize();
  }, [user, userStore]);

  const initialize = async (): Promise<void> => {
    if (!user) {
      await userStore?.fetchAndSetCurrentUser();
    }
    setIsCheckingUser(false);
  };

  if (isCheckingUser) {
    return null;
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default inject("userStore")(observer(ProtectedRoute));
