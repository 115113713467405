import { get, set } from "lodash";
import { Accept } from "react-dropzone";
import { DataAttribute } from "schemas/data.attribute.schemas/data.attribute.schema";
import DataAttributeStore from "stores/data.attribute.store";
import * as yup from "yup";

export const generateValidationSchemaNew = (
  attributes: DataAttribute[],
  dataAttributeStore: DataAttributeStore
): yup.ObjectSchema<any> => {
  const buildSchema = (
    attributes: DataAttribute[],
    parentPath: string = ""
  ): Record<string, yup.Schema<any, any>> => {
    const schemaFields: Record<string, yup.Schema<any, any>> = {};

    attributes.forEach((attr) => {
      let schema: yup.Schema<any, any> = yup.mixed().nullable();
      // Create full field path only if there's a parent path
      const fieldPath = parentPath
        ? `${parentPath}.${attr.fieldID}`
        : attr.fieldID;
      const settings = attr.attributeSettings as any;

      switch (attr.dataAttributeType) {
        case "number":
          schema = yup.number();
          break;
        case "boolean":
          schema = yup.boolean();
          break;
        case "date":
          schema = yup.date();
          break;
        case "string":
          schema = yup.string();
          if (
            settings?.validation &&
            yup.string().isValidSync(settings.validation)
          ) {
            if (settings?.validation && schema instanceof yup.StringSchema) {
              schema = schema.matches(
                new RegExp(settings.validation),
                `Must match pattern: ${settings.validation}`
              );
            }
          }
          break;
        case "object":
        case "array":
          if (settings?.referenceTo != null) {
            const nestedAttributes = dataAttributeStore.getCollectionAttributes(
              settings.referenceTo._id
            )?.data;
            if (nestedAttributes) {
              schema =
                attr.dataAttributeType === "array"
                  ? yup
                      .array()
                      .of(yup.object().shape(buildSchema(nestedAttributes)))
                  : yup.object().shape(buildSchema(nestedAttributes));
            }
          }
          break;
        default:
          schema = yup.mixed().nullable();
          break;
      }

      if (attr?.required) {
        schema = schema.required("This field is required");
      }

      // if (attr.path && attr.path != null) {
      //   // Create or extend the nested schema at the path
      //   const existingSchema =
      //     (schemaFields[attr.path] as yup.ObjectSchema<any>) ||
      //     yup.object().shape({});
      //   schemaFields[attr.path] = existingSchema.shape({
      //     [attr.fieldID]: schema,
      //   });
      // } else {
      //   // Directly assign the schema
      //   schemaFields[fieldPath] = schema;
      // }

      // Beispiel für eine verbesserte Schema-Erstellung
      if (attr.path) {
        const existingPathSchema = get(
          schemaFields,
          attr.path,
          yup.object().shape({})
        ) as yup.ObjectSchema<any>;
        set(
          schemaFields,
          attr.path,
          existingPathSchema.shape({
            [attr.fieldID]: schema,
          })
        );
      } else {
        schemaFields[fieldPath] = schema;
      }
    });

    return schemaFields;
  };

  return yup.object().shape(buildSchema(attributes));
};

export const getAcceptProp = (restriction: string | undefined): Accept => {
  if (!restriction) return {};
  switch (restriction) {
    case "PDF":
      return { "application/pdf": [".pdf"] };
    case "IMAGE":
      return { "image/*": [".jpeg", ".jpg", ".png"] };
    case "AUDIO":
      return { "audio/*": [".mp3", ".wav"] };
    case "VIDEO":
      return { "video/*": [".mp4", ".mov"] };
    case "NONE":
      return {};
    default:
      return {};
  }
};
