import React, { useEffect, useState } from "react";
import { StateStore, WidgetStore } from "@vondot-development/metool_lib";
import { inject, observer } from "mobx-react";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import TextEditor from "components/input.components/text.editor.components/text.editor.component/text.editor.component";
import { CustomerInfoLoading } from "../loading.component/customer.info.widget.loading.component";

interface CustomerInfoWidgetProps {
  widgetID: string;
  widgetStore?: WidgetStore;
  stateStore?: StateStore;
}

const CustomerInfoWidget = ({
  widgetID,
  widgetStore,
  stateStore,
}: CustomerInfoWidgetProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    // wait a second to simulate loading
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  if (isLoading) {
    return <CustomerInfoLoading />;
  }

  return (
    <ComponentWrapper title="Kundeninfo">
      <FormWrapper title="Daten">
        <Row gap={20}>
          <TextEditor
            content={
              "Kunde hat Rückenschmerzen und sollte besonders auf die Haltung achten."
            }
            inputContentChanged={(content: any) => {}}
            className="customer-info-editor"
          />
        </Row>
      </FormWrapper>
    </ComponentWrapper>
  );
};

export default inject(
  "widgetStore",
  "stateStore"
)(observer(CustomerInfoWidget));
