import { WidgetConfig } from "@vondot-development/metool_lib";
import InfoWidgetIcon from "./info.svg";
import CustomerInfoWidget from "./customer.info.widget.component/customer.info.widget.component";

export const CustomerInfoWidgetConfig: WidgetConfig = {
  name: "Intro Card",
  type: "CUSTOMER_INFO_WIDGET",
  component: CustomerInfoWidget,
  icon: InfoWidgetIcon,
  canHaveChildren: false,
};
