import {
  saveAccessToken,
  saveRefreshToken,
  deleteAccessAndRefreshToken,
} from "globals/helpers/storage.helper";
import { makeAutoObservable } from "mobx";
import { User } from "schemas/user.schemas/user.schema";
import {
  GoogleSignInCredentials,
  HttpAuthService,
  SignInCredentials,
  SignUpCredentials,
} from "services/httpClients/http.auth.client";
import { toast } from "react-toastify";
import { HttpUserService } from "services/httpClients/http.user.client";
import { ClientRole, ClientRoleFilterd } from "schemas/token.role.schema";
import { Invitation } from "schemas/invitation.schema";
import { PaginationDataList } from "globals/intefaces/pageination.data.list.interface";
import { HttpInvitationService } from "services/httpClients/http.invitation.client";
import {
  checkIfAliasIsInTokenRoles,
  filterClientRolesByMembership,
} from "globals/helpers/global.helper";
import { Logging } from "globals/helpers/logging.helper";
import Gleap from "gleap";
import RootStore from "./root.store";

class UserStore {
  private stores: RootStore;

  // Properties
  private _user?: User;
  private _clientRoles?: ClientRoleFilterd;
  private _userAccountKinds?: string[];
  private _userRole?: string;

  private _studioInvitationsDataList: PaginationDataList<Invitation> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  // Setter
  setUser = (user: User): void => {
    Gleap.identify(user._id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
    });
    this._user = user;
    this.fetchAndSetClientRoles();
  };

  setUserRole = (userRole: string): void => {
    this._userRole = userRole;
  };

  setUserAccountKinds = (accountKinds: string[]): void => {
    this._userAccountKinds = accountKinds;
  };

  setClientRoles = (clientRoles: ClientRole[]): void => {
    this._clientRoles = filterClientRolesByMembership(clientRoles);
  };

  setUserStudioInvitations = (studioInvitations: Invitation[]): void => {
    this._studioInvitationsDataList.data = studioInvitations;
  };

  // Getter
  get user(): User | undefined {
    if (this._user == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._user));
  }

  get userRole(): string | undefined {
    if (this._userRole == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._userRole));
  }

  get userAccountKinds(): string[] | undefined {
    if (this._userAccountKinds == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._userAccountKinds));
  }

  get clientRoles(): ClientRoleFilterd | undefined {
    if (this._clientRoles == null) {
      return;
    }
    return JSON.parse(JSON.stringify(this._clientRoles));
  }

  get studioUserInvitations(): PaginationDataList<Invitation> {
    return JSON.parse(JSON.stringify(this._studioInvitationsDataList));
  }

  // Methods
  fetchAndSetCurrentUser = async (): Promise<User | undefined> => {
    try {
      const response = await HttpUserService.getInstance().getCurrentUser();

      const user = response.data;
      this.setUser(user);

      return user;
    } catch (err) {
      Logging.error({
        className: "UserStore",
        methodName: "fetchAndSetCurrentUser",
        message: "Fehler beim Laden des Benutzers",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetCurrentUserAccountKinds = async (): Promise<
    string[] | undefined
  > => {
    try {
      const response =
        await HttpUserService.getInstance().getUserAccountKinds();

      const accountKinds = response.data;
      this.setUserAccountKinds(accountKinds);

      return accountKinds;
    } catch (err) {
      Logging.error({
        className: "UserStore",
        methodName: "fetchAndSetCurrentUserAccountKinds",
        message: "Fehler beim Laden der Benutzer Account Arten",
        exception: err,
        showAlert: false,
      });
    }
  };

  updateCurrentUser = async (data: User): Promise<User | undefined> => {
    try {
      const updatedUser = await HttpUserService.getInstance().updateCurrentUser(
        data
      );

      if (updatedUser == null) {
        toast.error("Profile aktualisieren fehlgeschlagen");
      }

      const user = updatedUser.data;

      this.setUser(user);

      return user;
    } catch (err) {
      Logging.error({
        className: "UserStore",
        methodName: "updateCurrentUser",
        message: "Fehler beim Aktualisieren des Benutzers",
        exception: err,
        showAlert: true,
      });
    }
  };

  // Authentication
  signIn = async (data: SignInCredentials): Promise<User | undefined> => {
    try {
      const response = await HttpAuthService.getInstance().handleSignIn(data);

      const { user, access_token, refresh_token } = response.data;

      saveAccessToken(access_token);
      saveRefreshToken(refresh_token);

      this.setUser(user);

      return user;
    } catch (err) {
      Logging.error({
        className: "UserStore",
        methodName: "signIn",
        message: "Fehler beim Anmelden",
        exception: err,
        showAlert: true,
      });
    }
  };

  signUp = async (
    data: SignUpCredentials | GoogleSignInCredentials
  ): Promise<User | undefined> => {
    try {
      const response = await HttpAuthService.getInstance().handleSignUp(data);

      const { user, access_token, refresh_token } = response.data;

      saveAccessToken(access_token);
      saveRefreshToken(refresh_token);

      this.setUser(user);

      return user;
    } catch (err) {
      Logging.error({
        className: "UserStore",
        methodName: "signUp",
        message: "Fehler beim Registrieren",
        exception: err,
        showAlert: true,
      });
    }
  };

  logout = (): void => {
    this._user = undefined;
  };

  clear = (): void => {
    if (this._user != null) {
      window.location.reload();
    }

    this._user = undefined;
    deleteAccessAndRefreshToken();
  };

  checkIfUserHasPermission = (args: { alias?: string }): boolean => {
    if (args.alias == null) {
      return true;
    }

    let access = false;

    if (this._clientRoles == null) {
      return false;
    }

    // check if the studio has the permission
    access = checkIfAliasIsInTokenRoles(
      this._clientRoles.studioRoles,
      args.alias
    );

    // if the studio does not have the permission return false
    if (!access) {
      return access;
    }

    // check if the user has the permission
    access = checkIfAliasIsInTokenRoles(
      this._clientRoles.userRoles,
      args.alias
    );

    return access;
  };

  checkIfHasRole = (args: {
    alias?: string;
    membership?: "STUDIO" | "USER";
  }): boolean => {
    if (args.membership == null) {
      args.membership = "STUDIO";
    }

    if (args.alias == null) {
      return true;
    }

    let access = false;

    if (this._clientRoles == null) {
      return false;
    }

    const roles = args.membership === "STUDIO" ? "studioRoles" : "userRoles";

    // check if the studio has the permission
    access = checkIfAliasIsInTokenRoles(this._clientRoles[roles], args.alias);

    return access;
  };

  fetchAndSetUserStudioInvitations = async (): Promise<
    Invitation[] | undefined
  > => {
    try {
      const invitations =
        await HttpInvitationService.getInstance().getInvitationsOfCurrentUser();

      const studioInvitations = invitations;

      this.setUserStudioInvitations(studioInvitations);

      return studioInvitations;
    } catch (err) {
      Logging.error({
        className: "UserStore",
        methodName: "fetchAndSetUserStudioInvitations",
        message: "Fehler beim Laden der Einladungen des Benutzers",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetClientRoles = async (): Promise<void> => {
    try {
      const response =
        await HttpUserService.getInstance().getClientPermissions();

      const clientRoles = response.data as ClientRole[];

      this.setClientRoles(clientRoles);
      this.setCurrentUserRole();
    } catch (err) {
      Logging.error({
        className: "UserStore",
        methodName: "fetchAndSetClientRolesv",
        message: "Fehler beim Laden der Rechte",
        exception: err,
        showAlert: true,
      });
    }
  };

  setCurrentUserRole = (): void => {
    if (
      this?.checkIfHasRole({
        membership: "USER",
        alias: "DASHBOARD_STUDIO_ADMIN",
      })
    ) {
      this.setUserRole("Studio Admin");
    }

    if (
      this.checkIfHasRole({
        membership: "USER",
        alias: "DASHBOARD_TRAINER",
      })
    ) {
      this.setUserRole("Trainer");
    }

    if (
      this.checkIfHasRole({
        membership: "USER",
        alias: "GYMO_ADMIN",
      })
    ) {
      this.setUserRole("Gymo Admin");
    }
  };
}

export default UserStore;
