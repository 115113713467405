import * as yup from "yup";
import { feedActionSchema } from "./feed.action.schema";
import { feedConfigSchema } from "./feed.config.schema";
import { feedPromotionSchema } from "./feed.promotion.schema";
import { System } from "schemas/system.schema";

export const feedSchema = yup.object().shape({
  _id: yup.string().notRequired(),
  feedConfig: feedConfigSchema.required("Kategorie ist verpflichtend"),
  coverImageUrl: yup.string().required("Coverbild ist verpflichtend"),
  title: yup.string().required("Titel ist verpflichtend"),
  subTitle: yup.string(),
  content: yup.mixed().required("Inhalt ist verpflichtend"),
  promotion: feedPromotionSchema.notRequired().default(undefined),
  action: feedActionSchema.notRequired().default(undefined),
  isPublished: yup.boolean().required("Verfügbarkeit ist verpflichtend"),
});

export interface Feed extends yup.InferType<typeof feedSchema> {
  system: System;
}

export const feedToJson = (feed: Feed): any => {
  return {
    ...feed,
    feedConfig: feed.feedConfig._id,
  };
};
