import React, { useEffect, useState } from "react";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import MainLayout from "layouts/main.layout/main.layout";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router-dom";
import FeedStore from "stores/feed.store";
import StudioStore from "stores/studio.store";
import { ModalStore } from "stores/modal.store";
import FeedContentItemForm from "../components/feed.content.item.form.component/feed.content.item.form.component";
import FixedControllersToolbar from "components/navigation.components/fixed.controller.toolbar.component/fixed.controller.toolbar.component";
import { generatePlaceholderAvatarUrl } from "globals/helpers/global.helper";
import { feedPublishedStatus } from "globals/data/globals.data";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { feedSchema } from "schemas/feed.schemas/feed.schema";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import { useTranslation } from "react-i18next";
import { navigationHelper } from "globals/helpers/navigation.helper";

interface FeedContentDetailPageProps {
  feedStore?: FeedStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const FeedContentDetailPage = ({
  feedStore,
  studioStore,
  modalStore,
}: FeedContentDetailPageProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = navigationHelper();

  const { feedID } = useParams();
  const [isEditing, setIsEditing] = useState(feedID !== "new");
  const [isUploading, setIsUploading] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const feedItem = feedStore?.currentFeed?.data;
  const currentFeedConfig = feedStore?.currentFeedConfig?.data;

  useEffect(() => {
    initializePage();
  }, [feedID, feedStore]);

  const methods = useForm({
    resolver: yupResolver(feedSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const initializePage = async (): Promise<void> => {
    if (feedID == null) {
      return;
    }

    if (feedID !== "new") {
      await feedStore?.fetchAndSetFeedItem({ feedID });
      setIsEditing(true);
    }

    if (feedID === "new" && currentFeedConfig != null) {
      feedStore?.createInitialFeedItem(currentFeedConfig);

      setIsEditing(false);
    }

    // TODO Fix this with default values
    methods.reset(feedStore?.currentFeed?.data);
  };

  const handleDeleteFeedItem = async (): Promise<void> => {
    if (feedItem?._id != null) {
      modalStore?.showDeleteAlert({
        t,
        onConfirm: async () => {
          if (feedItem?._id == null) return;
          feedStore?.archiveFeedItem(feedItem);

          if (currentFeedConfig?._id != null) {
            navigate(`feed/${currentFeedConfig?._id}/content`);
          }
        },
      });
    }
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline
        title={feedItem?.title}
        showBackButton
        rootPath="content"
      ></PageHeadline>
    );
  };

  if (feedStore?.currentFeed?.isLoading === true) {
    return <SkeletonDetailPage />; // TODO improve !
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer key={feedID === "new" ? Math.random() : feedID}>
        <WidthLimiterWrapper maxSize="form">
          <FormProvider {...methods}>
            <FeedContentItemForm
              isEditing={isEditing}
              key={JSON.stringify(feedItem)}
              onDirty={(isDirty: boolean) => {
                setFormIsDirty(isDirty);
              }}
              isUploading={(isUploading: boolean) => {
                setIsUploading(isUploading);
              }}
            />
            <FixedControllersToolbar
              options={feedPublishedStatus}
              formID="feed-content-item-form"
              disabled={false}
              fromIsDirty={formIsDirty}
              isLoading={isUploading}
              hasUnsavedChanges={false}
              title={feedItem?.title}
              imageUrl={generatePlaceholderAvatarUrl(feedItem?.title ?? "")}
              handleDelete={async () => {
                handleDeleteFeedItem();
              }}
              selectedStatus={feedPublishedStatus.find(
                (item) => item.value === feedItem?.isPublished
              )}
              handleStatusSelect={(option) => {
                if (option?.value != null) {
                  methods.setValue("isPublished", option.value);
                }
              }}
            />
          </FormProvider>
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "feedStore",
  "studioStore",
  "modalStore"
)(observer(FeedContentDetailPage));
