import React, { useEffect } from "react";
import FeedStore from "stores/feed.store";
import { inject, observer } from "mobx-react";
import {
  Outlet,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import MainLayout from "layouts/main.layout/main.layout";
import FeedConfigDetailPage from "./feed.config.detail.page/feed.config.detail.page";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import { createTranslate } from "globals/helpers/global.helper";
import { useTranslation } from "react-i18next";
import FeedContentOverviewPage from "./feed.content.detail.pages/feed.content.overview.page/feed.content.overview.page";

interface FeedConfigurationDetailPageProps {
  feedStore?: FeedStore;
}

const FeedConfigurationDetailPage = ({
  feedStore,
}: FeedConfigurationDetailPageProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "feedPage");

  const { feedConfigID } = useParams();

  const location = useLocation();
  const configIsActive = location?.pathname?.includes("config");

  useEffect(() => {
    if (feedConfigID != null && feedConfigID !== "new") {
      feedStore?.fetchAndSetFeedConfigItem({ feedConfigID });
    }
  }, [feedConfigID]);

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        tabs={[
          <Tab
            label={translate("config.tabHeadline")}
            key="feed-config-tab"
            path="config"
            rootPath={`feed/${feedConfigID}`}
          />,
          <Tab
            label={translate("content.tabHeadline")}
            key="feed-content-tab"
            path="content"
            rootPath={`feed/${feedConfigID}`}
            disabled={feedConfigID === "new"}
          />,
        ]}
      >
        <Outlet />
      </LinkTabs>
    );
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline showBackButton rootPath="feed">
        {_buildTabs()}
      </PageHeadline>
    );
  };

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer key={feedConfigID}>
        <WidthLimiterWrapper maxSize={configIsActive ? "large" : "default"}>
          <Routes>
            <Route path="config" element={<FeedConfigDetailPage />} />
            <Route path="content" element={<FeedContentOverviewPage />} />
          </Routes>
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default inject("feedStore")(observer(FeedConfigurationDetailPage));
