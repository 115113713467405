import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import React from "react";
import Skeleton from "react-loading-skeleton";

export const BillingDataWidgetLoading = ({
  count = 1,
}: {
  count?: number;
}): JSX.Element => {
  return (
    <ComponentWrapper title="Zahlungsdaten">
      <FormWrapper title="Kreditkarte">
        {[...Array(count)].map((_, index) => (
          <Row gap={20} key={index}>
            <div
              key={index}
              style={{
                width: "100%",
              }}
            >
              <Skeleton count={1} width="100%" height={35} className="mb-25" />
            </div>
            <div
              key={index}
              style={{
                width: "100%",
              }}
            >
              <Skeleton count={1} width="100%" height={35} />
            </div>
          </Row>
        ))}
      </FormWrapper>
    </ComponentWrapper>
  );
};
