import React from "react";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import MainLayout from "layouts/main.layout/main.layout";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import ConfiguratorTab from "./components/coniguration.tab/configurator.tab";
import PublishingTab from "./components/publishing.tab/publishing.tab";
import { useTranslation } from "react-i18next";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";

const AppConfiguratorPage = (): JSX.Element => {
  const { t } = useTranslation();

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        tabs={[
          <Tab
            label={t("appConfiguratorPage.tabs.appConfigurator")}
            key="exercise-info-tab"
            path="configurator"
            rootPath="app"
          />,
          <Tab
            label={t("appConfiguratorPage.tabs.publishing")}
            key="exercise-infos-tab"
            path="publishing"
            rootPath="app"
          />,
        ]}
      >
        <Outlet />
      </LinkTabs>
    );
  };

  return (
    <MainLayout
      topBars={[
        <PageHeadline key="configurator-top-bar">{_buildTabs()}</PageHeadline>,
      ]}
    >
      <PageContainer>
        <WidthLimiterWrapper>
          <Routes>
            <Route path="/" element={<Navigate to="configurator" />} />
            <Route path="configurator" element={<ConfiguratorTab />} />
            <Route path="publishing" element={<PublishingTab />} />
          </Routes>
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default AppConfiguratorPage;
