import React from "react";
import "./upload.asset.list.component.scss";
import classNames from "classnames";
import { Asset } from "schemas/asset.schemas/asset.schema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faEye, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { redirectToExternalResource } from "globals/helpers/navigation.helper";

import ListDataTable from "../list.data.table.component/list.data.table.component";
import Row from "components/general.compoenents/row.component/row.component";
import Image from "components/image.component/image.component";
import { ImageSizes } from "globals/enums/global.enum";

interface UploadAssetListProps {
  uploadedAssets: Asset[];
  selectedAssets: Asset[];
  onClick?: (asset: Asset) => void;
  removeUploadedAssets: (index: number) => void;
}

const UploadAssetsList = ({
  uploadedAssets,
  selectedAssets,
  onClick,
  removeUploadedAssets,
}: UploadAssetListProps): JSX.Element => {
  return (
    <ListDataTable
      data={uploadedAssets}
      onClick={onClick}
      columns={[
        {
          flex: 1,
        },
        {
          flex: 1,
        },
        {
          flex: 1,
        },
      ]}
      dataTableItemBuilder={(dataItem: Asset, index: number) => {
        const isSelected = selectedAssets?.find((selectedItem: any) => {
          return selectedItem._id === dataItem._id;
        });

        return {
          key: dataItem._id,
          className: classNames({
            "list-data-table-body-item-selected": isSelected,
          }),
          children: [
            {
              child: (
                <Row alignItems="center">
                  <Image
                    className="mr-20"
                    rounded={false}
                    imageUrl={dataItem.url}
                    size={ImageSizes.M}
                  />
                  {`Image ${index + 1}`}
                </Row>
              ),
            },
            {
              child: (
                <Row justifyContent="center" alignItems="center">
                  <FontAwesomeIcon
                    className="uploaded-check-icon"
                    icon={faCheck}
                  />
                  {`Completed`}
                </Row>
              ),
            },
            {
              child: (
                <Row justifyContent="flex-end">
                  <FontAwesomeIcon
                    onClick={(event) => {
                      event.stopPropagation();
                      redirectToExternalResource(dataItem.url);
                    }}
                    className="uploaded-item-icon"
                    icon={faEye}
                  />
                  <FontAwesomeIcon
                    onClick={(event) => {
                      removeUploadedAssets(index);
                    }}
                    className="uploaded-item-icon"
                    icon={faXmark}
                  />
                </Row>
              ),
            },
          ],
        };
      }}
      gap={30}
      disableHeader={true}
    />
  );
};

export default UploadAssetsList;
