import { Logging } from "globals/helpers/logging.helper";
import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { HttpPaymentService } from "services/httpClients/http.payment.client";
import RootStore from "./root.store";

class PaymentStore {
  private stores: RootStore;

  paymentMethods: any[] = [];
  setupIntent: any = undefined;
  invoices: any[] = [];
  subscriptions: any[] = [];
  paymentCustomer: any = undefined;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  createSetupIntent = async (): Promise<void> => {
    try {
      const response =
        await HttpPaymentService.getInstance().createSetupIntent();

      runInAction(() => {
        this.setupIntent = response.data;
      });
    } catch (err) {
      Logging.error({
        className: "PaymentStore",
        methodName: "createSetupIntent",
        message: "SetupIntent konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  listPaymentMethods = async (): Promise<void> => {
    try {
      const response =
        await HttpPaymentService.getInstance().listPaymentMethods();

      runInAction(() => {
        this.paymentMethods = response.data;
      });
    } catch (err) {
      Logging.error({
        className: "PaymentStore",
        methodName: "listPaymentMethods",
        message: "Zahlungsmethoden konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  deletePaymentMethod = async (args: {
    paymentMethodID: string;
  }): Promise<void> => {
    try {
      await HttpPaymentService.getInstance().deletePaymentMethod({
        paymentMethodID: args.paymentMethodID,
      });

      toast.success("Zahlungsmethode wurde erfolgreich gelöscht");
    } catch (err) {
      Logging.error({
        className: "PaymentStore",
        methodName: "deletePaymentMethod",
        message: "Zahlungsmethode konnte nicht gelöscht werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createSubscription = async (args: {
    priceID: string;
    paymentMethodID: string;
    promotionCode?: string;
  }): Promise<void> => {
    try {
      await HttpPaymentService.getInstance().createSubscription({
        priceID: args.priceID,
        paymentMethodID: args.paymentMethodID,
        promotionCode: args.promotionCode,
      });
    } catch (err) {
      Logging.error({
        className: "PaymentStore",
        methodName: "createSubscription",
        message: "Abonnement konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetInvoices = async (): Promise<void> => {
    try {
      const response = await HttpPaymentService.getInstance().getInvoices();

      runInAction(() => {
        this.invoices = response.data;
      });
    } catch (err) {
      Logging.error({
        className: "PaymentStore",
        methodName: "fetchAndSetInvoices",
        message: "Rechnungen konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetSubscriptions = async (): Promise<void> => {
    try {
      const response =
        await HttpPaymentService.getInstance().getSusbscription();

      runInAction(() => {
        this.subscriptions = response.data;
      });
    } catch (err) {
      Logging.error({
        className: "PaymentStore",
        methodName: "fetchAndSetSubscriptions",
        message: "Abonnements konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  cancelSubscription = async (args: {
    subscriptionID: string;
  }): Promise<void> => {
    try {
      await HttpPaymentService.getInstance().cancelSubscription({
        subscriptionID: args.subscriptionID,
      });

      toast.success("Abonnement wurde erfolgreich gekündigt");
    } catch (err) {
      Logging.error({
        className: "PaymentStore",
        methodName: "cancelSubscription",
        message: "Abonnement konnte nicht gekündigt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetPaymentCustomer = async (): Promise<void> => {
    try {
      const response =
        await HttpPaymentService.getInstance().getPaymentCustomer();

      runInAction(() => {
        this.paymentCustomer = response.data;
      });
    } catch (err) {
      Logging.error({
        className: "PaymentStore",
        methodName: "fetchAndSetPaymentCustomer",
        message: "Kunde konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  updatePaymentCustomer = async (args: { customer: any }): Promise<void> => {
    try {
      await HttpPaymentService.getInstance().updatePaymentCustomer({
        customer: args.customer,
      });

      toast.success("Addresse wurde erfolgreich aktualisiert");
    } catch (err) {
      Logging.error({
        className: "PaymentStore",
        methodName: "updatePaymentCustomer",
        message: "Addresse konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default PaymentStore;
