import React, { useEffect, useState } from "react";
import { StateStore, WidgetStore } from "@vondot-development/metool_lib";
import { inject, observer } from "mobx-react";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import Row from "components/general.compoenents/row.component/row.component";
import { BillingDataWidgetLoading } from "../loading.component/billing.data.form.widget.loading.component";

interface BillingDataFormWidgetProps {
  widgetID: string;
  widgetStore?: WidgetStore;
  stateStore?: StateStore;
}

const BillingDataFormWidget = ({
  widgetID,
  widgetStore,
  stateStore,
}: BillingDataFormWidgetProps): JSX.Element => {
  const [data, setData] = useState<any>({});

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const analized = widgetStore?.getAnalyzedWidgetOptions(widgetID);
    if (!analized) return;

    stateStore?.initializeDynamicOptions(
      widgetID,
      analized,
      (options) => {
        setIsLoading(options?.isLoading);
        setData(options?.data);
      },
      {}
    );
  }, []);

  if (isLoading) {
    return <BillingDataWidgetLoading count={3} />;
  }

  return (
    <ComponentWrapper title="Zahlungsdaten">
      <FormWrapper title="Kreditkarte">
        <Row gap={20}>
          <OutlinedTextInput
            label="Kartennummer"
            initialValue="5595 7852 0772 6814"
          />
          <OutlinedTextInput
            label="Inhaber"
            initialValue={`${data?.firstName || ""} ${data?.lastName || ""}`}
          />
        </Row>

        <Row gap={20}>
          <OutlinedTextInput label="Gültig Bis" initialValue="12/26" />
          <OutlinedTextInput type="password" label="CVV" initialValue="591" />
        </Row>
      </FormWrapper>
    </ComponentWrapper>
  );
};

export default inject(
  "widgetStore",
  "stateStore"
)(observer(BillingDataFormWidget));
