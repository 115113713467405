import { GenericHttpClient } from "./config/http.generic.client";
import { WorkoutStats } from "schemas/workout.stats.schemas/workout.stats.schema";

export class HttpWorkoutStatsService extends GenericHttpClient<WorkoutStats> {
  static _instance: HttpWorkoutStatsService;
  static getInstance(): HttpWorkoutStatsService {
    if (this._instance == null) {
      this._instance = new HttpWorkoutStatsService("/workout-stats");
    }
    return this._instance;
  }
}
