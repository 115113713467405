import React from "react";
import { ModalStore } from "stores/modal.store";
import { inject, observer } from "mobx-react";
import Column from "components/general.compoenents/column.component/column.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { useForm } from "react-hook-form";
import { faUnlockKeyhole } from "@fortawesome/pro-regular-svg-icons";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { toast } from "react-toastify";
import { Logging } from "globals/helpers/logging.helper";
import { HttpUserService } from "services/httpClients/http.user.client";

interface ChangePasswordModalProps {
  modalStore?: ModalStore;
}

const ChangePasswordModal = ({
  modalStore,
}: ChangePasswordModalProps): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async (data: any): Promise<void> => {
    try {
      data.preventDefault();
      const password = data.currentTarget.elements.password.value;
      const newPassword = data.currentTarget.elements.newPassword.value;

      if (password === "" || newPassword === "") {
        return;
      }

      const changePwResult =
        await HttpUserService.getInstance().handleChangePassword({
          password,
          newPassword,
        });

      if (changePwResult) {
        toast.success("Passwort erfolgreich geändert");
        modalStore?.closeModal();
      }
    } catch (err) {
      Logging.error({
        className: "ChangePasswordModal",
        methodName: "onSubmit",
        message: "Passwort konnte nicht geändert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  return (
    <form
      noValidate
      onSubmit={(data) => {
        void onSubmit(data);
      }}
      id="change-password-form"
    >
      <Column
        className="modal-padding"
        justifyContent="center"
        alignItems="center"
      >
        <FontAwesomeIcon
          className="invite-team-member-icon"
          icon={faUnlockKeyhole}
        />
        <TitleText>Passwort ändern</TitleText>
        <RunningText className="mb-15">
          Fülle folgende Felder aus, um dein Account Passwort zu ändern.
        </RunningText>

        <Row className="mt-15" alignItems="center">
          <OutlinedTextInput
            className="mr-10"
            type="password"
            label="Passwort"
            inputRef={register("password", {
              required: "Passwort is required",
            })}
            validationMessage={errors.password?.message?.toString()}
          />
          <OutlinedTextInput
            className="mr-10"
            type="password"
            label="Neues Passwort"
            inputRef={register("newPassword", {
              required: "Neues Passwort is required",
            })}
            validationMessage={errors.newPassword?.message?.toString()}
          />
        </Row>
        <Row className="mt-15" justifyContent="flex-end">
          <FilledButton
            label="Abrechen"
            color="secondary"
            className="mr-10"
            onClick={() => {
              modalStore?.closeModal();
            }}
          />
          <FilledButton label="Bestätigen" type="submit" onClick={() => {}} />
        </Row>
      </Column>
    </form>
  );
};

export default inject("modalStore")(observer(ChangePasswordModal));
