import { WidgetConfig } from "@vondot-development/metool_lib";
import IntroCardIcon from "./intro.card.svg";
import PageIntroWidget from "./page.intro.component/page.intro.component";
import pageIntroWidgetOptionSidebar from "./sidebar.component/page.intro.widget.option.sidebar.component";

export const PageIntroWidgetConfig: WidgetConfig = {
  name: "Page Intro Widget",
  type: "PAGE_INTRO_WIDGET",
  component: PageIntroWidget,
  icon: IntroCardIcon,
  canHaveChildren: false,
  sidebarComponent: pageIntroWidgetOptionSidebar,
};
