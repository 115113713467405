import classNames from "classnames";
import React from "react";
import "./spacer.component.scss";

interface SpacerProps {
  width?: string;
  className?: string;
  bold?: boolean;
}

const Spacer = ({ width, className, bold }: SpacerProps): JSX.Element => {
  const spacerClassName = classNames(
    {
      spacer: true,
      "spacer--bold": bold,
    },
    className
  );

  return <div className={spacerClassName} style={{ width }} />;
};

export default Spacer;
