import AbstractKanbanBoard from "components/board.components/kanban.board.component/kanban.board.component";
import Column from "components/general.compoenents/column.component/column.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import Row from "components/general.compoenents/row.component/row.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import MainLayout from "layouts/main.layout/main.layout";
import "./lead.page.scss";
import React from "react";

enum GymLeadStatus {
  INTERESTED_CONTACTED = "INTERESTED_CONTACTED", // Für Leads, die Interesse gezeigt oder die Sie kontaktiert haben.
  CONSULTATION_SCHEDULED = "CONSULTATION_SCHEDULED", // Für Leads, mit denen ein Beratungsgespräch geplant ist.
  TRIAL_SESSION_SCHEDULED = "TRIAL_SESSION_SCHEDULED", // Für Leads, die ein Probetraining vereinbart haben.
  FEEDBACK_AFTER_TRIAL = "FEEDBACK_AFTER_TRIAL", // Für Leads, die ihr Probetraining absolviert haben und Feedback geben.
  CONVERTED_MEMBER = "CONVERTED_MEMBER", // Für Leads, die zu Mitgliedern geworden sind.
  NOT_INTERESTED_LOST = "NOT_INTERESTED_LOST", // Für Leads, die kein Interesse mehr haben oder verloren gegangen sind.
}

const predefinedPhases = [
  {
    id: "0",
    label: "Interessiert / Kontaktiert",
    key: GymLeadStatus.INTERESTED_CONTACTED,
  },
  {
    id: "1",
    label: "Erstgespräch / Beratung",
    key: GymLeadStatus.CONSULTATION_SCHEDULED,
  },
  {
    id: "2",
    label: "Probetraining Vereinbart",
    key: GymLeadStatus.TRIAL_SESSION_SCHEDULED,
  },
  {
    id: "3",
    label: "Feedback Nach Probetraining",
    key: GymLeadStatus.FEEDBACK_AFTER_TRIAL,
  },
  {
    id: "4",
    label: "Konvertiert / Mitglied Geworden",
    key: GymLeadStatus.CONVERTED_MEMBER,
  },
  {
    id: "5",
    label: "Nicht Interessiert / Verloren",
    key: GymLeadStatus.NOT_INTERESTED_LOST,
  },
];

const PredefinedJobCandidates = [
  {
    id: "1",
    status: GymLeadStatus.INTERESTED_CONTACTED,
    first_name: "Andrea",
    last_name: "Mustermann",
    rate: 4,
    requested_at: "28. Dez. 2023 um 21:43 Uhr",
    is_new: true,
  },
  {
    id: "2",
    status: GymLeadStatus.INTERESTED_CONTACTED,
    first_name: "Tobias",
    last_name: "Duelli",
    rate: 3,
    requested_at: "28. Dez. 2023 um 21:43 Uhr",
    is_new: false,
    image: null,
  },
  {
    id: "3",
    status: GymLeadStatus.CONSULTATION_SCHEDULED,
    first_name: "Alessandro",
    last_name: "Rhomberg",
    rate: 0,
    requested_at: "28. Dez. 2023 um 21:43 Uhr",
    is_new: false,
    image: null,
  },
  {
    id: "4",
    status: GymLeadStatus.CONVERTED_MEMBER,
    first_name: "Julian",
    last_name: "Eberling",
    rate: 5,
    requested_at: "28. Dez. 2023 um 21:43 Uhr",
    is_new: false,
    image: null,
  },
  {
    id: "5",
    status: GymLeadStatus.NOT_INTERESTED_LOST,
    first_name: "Johannes",
    last_name: "Bach",
    rate: 1,
    requested_at: "28. Dez. 2023 um 21:43 Uhr",
    is_new: false,
    image: null,
  },
  {
    id: "6",
    status: GymLeadStatus.TRIAL_SESSION_SCHEDULED,
    first_name: "Johannes",
    last_name: "Bach",
    rate: 1,
    requested_at: "28. Dez. 2023 um 21:43 Uhr",
    is_new: false,
    image: null,
  },
  {
    id: "7",
    status: GymLeadStatus.FEEDBACK_AFTER_TRIAL,
    first_name: "Johannes",
    last_name: "Bach",
    rate: 1,
    requested_at: "28. Dez. 2023 um 21:43 Uhr",
    is_new: false,
    image: null,
  },
  {
    id: "8",
    status: GymLeadStatus.INTERESTED_CONTACTED,
    first_name: "Johannes",
    last_name: "Bach",
    rate: 1,
    requested_at: "28. Dez. 2023 um 21:43 Uhr",
    is_new: false,
    image: null,
  },
  {
    id: "9",
    status: GymLeadStatus.CONSULTATION_SCHEDULED,
    first_name: "Johannes",
    last_name: "Bach",
    rate: 1,
    requested_at: "28. Dez. 2023 um 21:43 Uhr",
    is_new: false,
    image: null,
  },
  {
    id: "10",
    status: GymLeadStatus.TRIAL_SESSION_SCHEDULED,
    first_name: "Johannes",
    last_name: "Bach",
    rate: 1,
    requested_at: "28. Dez. 2023 um 21:43 Uhr",
    is_new: false,
    image: null,
  },
  {
    id: "11",
    status: GymLeadStatus.INTERESTED_CONTACTED,
    first_name: "Johannes",
    last_name: "Bach",
    rate: 1,
    requested_at: "28. Dez. 2023 um 21:43 Uhr",
    is_new: false,
    image: null,
  },
];

const LeadPage = (): JSX.Element => {
  const _buildItem = ({
    id,
    first_name,
    last_name,
    rate,
    requested_at,
    image,
    is_new,
  }: any): JSX.Element => {
    return (
      <Row
        key={id}
        alignItems="flex-end"
        className="job-card-container relative cursor-pointer"
      >
        <Column className="job-card-info">
          <Row className="job-card-name">{`${first_name} ${last_name}`}</Row>
          <div className="job-card-date mt-10">{requested_at}</div>
        </Column>
        <Column className="job-card-image-wrapper">
          {image ? (
            <div className="job-card-image-container">
              <img src={image} alt="job_card_image_alt" />
            </div>
          ) : (
            <div className="job-card-image-replace-container">
              {first_name[0].toUpperCase()}
              {last_name[0].toUpperCase()}
            </div>
          )}
        </Column>
        {is_new && <div className="job-card-new-indicator"></div>}
      </Row>
    );
  };

  return (
    <MainLayout
      topBars={[
        <PageHeadline showBackButton key="leads" title="Neukundengewinnung" />,
      ]}
    >
      <PageContainer>
        <AbstractKanbanBoard
          columns={predefinedPhases}
          items={PredefinedJobCandidates}
          relateField="status"
          itemBuilder={_buildItem}
        />
      </PageContainer>
    </MainLayout>
  );
};

export default LeadPage;
