import classNames from "classnames";
import Column from "components/general.compoenents/column.component/column.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import React from "react";
import "./widget.template.component.scss";

interface WidgetTemplateProps {
  title: string;
  description: string;
  imageUrl: string;
  isActive?: boolean;
  onClick?: () => void;
}

const WidgetTemplate = ({
  title,
  description,
  imageUrl,
  isActive = false,
  onClick,
}: WidgetTemplateProps): JSX.Element => {
  const widgetTemplateClassName = classNames({
    "widget-template-container": true,
    "widget-template-container-active": isActive,
  });

  return (
    <Column
      alignItems="center"
      className={widgetTemplateClassName}
      onClick={onClick}
    >
      <img src={imageUrl} className="widget-template-container-image mb-15" />
      <TitleText>{title}</TitleText>
      <RunningText className="description">{description}</RunningText>
    </Column>
  );
};

export default WidgetTemplate;
