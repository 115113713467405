import React, { useEffect } from "react";
import TrainingPlanExerciseList from "components/table.components/training.plan.exercise.list.component/training.plan.exercise.list.component";
import { inject, observer } from "mobx-react";
import TrainingPlanStore from "stores/training.plan.store";
import { useParams } from "react-router-dom";
import Column from "components/general.compoenents/column.component/column.component";
import { UseFormReturn } from "react-hook-form";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import { usePersistentId } from "globals/helpers/form.key.helper";

interface CreateCustomerTrainingPlanProps {
  formMethods: UseFormReturn<TrainingPlan, any, undefined>;
  trainingPlanStore?: TrainingPlanStore;
}

const CreateCustomerTrainingPlan = ({
  formMethods,
  trainingPlanStore,
}: CreateCustomerTrainingPlanProps): JSX.Element => {
  const { trainingPlanID, customerID } = useParams();
  const persistentId = usePersistentId(trainingPlanID);

  const currentTrainingPlan = trainingPlanStore?.currentTrainingPlan?.data;

  useEffect(() => {
    if (trainingPlanID == null) {
      return;
    }

    if (trainingPlanID !== "new") {
      trainingPlanStore?.fetchAndSetTrainingPlan({
        trainingPlanID,
      });
    } else {
      trainingPlanStore?.setCurrentTrainingPlan({} as any);
    }
  }, [trainingPlanID]);

  useEffect(() => {
    formMethods.reset({
      ...currentTrainingPlan,
      user: customerID as any,
      type: "CUSTOMER",
    });
  }, [currentTrainingPlan]);

  if (
    currentTrainingPlan == null ||
    trainingPlanStore?.currentTrainingPlan?.isLoading
  ) {
    return <></>;
  }

  return (
    <Column key={persistentId}>
      <TrainingPlanExerciseList
        formMethods={formMethods}
        isCustomerTrainingPlan
      />
    </Column>
  );
};

export default inject("trainingPlanStore")(
  observer(CreateCustomerTrainingPlan)
);
