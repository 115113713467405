import React from "react";
import classNames from "classnames";
import "./icon.button.component.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IconButtonProps {
  className?: string;
  onClick?: () => void;
  type?: "submit" | "button";
  form?: string;
  icon: IconProp;
  disabled?: boolean;
}

const IconButton = ({
  className,
  onClick,
  type = "button",
  form,
  icon,
  disabled = false,
}: IconButtonProps): JSX.Element => {
  const iconButtonClassName = classNames(
    {
      "icon-button": true,
      "icon-button--disabled": disabled,
    },
    className
  );

  return (
    <FontAwesomeIcon
      className={iconButtonClassName}
      icon={icon}
      onClick={disabled ? undefined : onClick}
      type={type}
      from={form}
    />
  );
};

export default IconButton;
