import React from "react";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import { ContainerSizes } from "globals/enums/global.enum";
import MainLayout from "layouts/main.layout/main.layout";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import StudioStore from "stores/studio.store";
import ProfileTabPage from "./components/profile.tab.page/profile.tab.page";
import TeamTab from "./components/team.tab.page/team.tab.page";
import "./account.page.scss";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import StudioTabPage from "./components/studio.tab.page/studio.tab.page";
import BillingTabPage from "./components/billing.tab.page/billing.tab.page";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import PageConfigurationTabPage from "./components/metool.tab.pages/page.configuration.tab.page/page.configuration.tab.page";
import DataAttributeConfigurationTabPage from "./components/data.attribute.configuration.tab.page/data.attribute.configuration.tab.page";

interface AccountPageProps {
  studioStore?: StudioStore;
}

const AccountPage = ({ studioStore }: AccountPageProps): JSX.Element => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const _buildTabs = (): JSX.Element => {
    return (
      <PageHeadline>
        <LinkTabs
          tabs={[
            <Tab
              label={t("accountPage.tabs.profile")}
              key="profile-tab"
              path="profile"
              permissionAlias="DASHBOARD_PROFILE_SETTINGS"
            />,
            <Tab
              label={t("accountPage.tabs.studio")}
              key="studio-tab"
              path="my-studio"
              permissionAlias="DASHBOARD_STUDIO_SETTINGS"
            />,
            <Tab
              label={t("accountPage.tabs.team")}
              key="team-tab"
              path="team"
              permissionAlias="DASHBOARD_TEAM_SETTINGS"
            />,
            // TODO igonre for FIBO 2024
            // <Tab
            //   label={t("accountPage.tabs.plan")}
            //   key="plan-tab"
            //   path="plan"
            //   permissionAlias="DASHBOARD_PLAN_BILLING_SETTINGS"
            // />,
            <Tab
              label={t("accountPage.tabs.page")}
              key="pages-tab"
              path="page"
              permissionAlias="DASHBOARD_PLAN_BILLING_SETTINGS" // TODO
            />,
            <Tab
              label={t("accountPage.tabs.dataAttribute")}
              key="data-attributes-configuration-tab"
              path="collections"
              // permissionAlias="DASHBOARD_PLAN_BILLING_SETTINGS" // TODO
            />,
            <Tab
              label={t("accountPage.tabs.archive")}
              key="archive-tab"
              onClick={() => {
                if (studioStore?.studio?._id != null) {
                  navigate(
                    `/studios/${studioStore?.studio?._id}/archive/device-archive`
                  );
                }
              }}
              permissionAlias="DASHBOARD_STUDIO_ARCHIVE"
            />,
          ]}
        />
      </PageHeadline>
    );
  };

  return (
    <MainLayout topBars={[_buildTabs()]}>
      <PageContainer backgroundColor="color-surface">
        <SizedContainer size={ContainerSizes.XXL}>
          <div className="device-detail-wrapper">
            <Routes>
              <Route element={<Outlet />}>
                <Route
                  index={true as any}
                  path="profile"
                  element={<ProfileTabPage />}
                />
                <Route path="my-studio" element={<StudioTabPage />} />
                <Route path="team" element={<TeamTab />} />
                <Route path="plan" element={<BillingTabPage />} />
                <Route path="page" element={<PageConfigurationTabPage />} />
                <Route
                  path="collections"
                  element={<DataAttributeConfigurationTabPage />}
                />
              </Route>
            </Routes>
          </div>
        </SizedContainer>
      </PageContainer>
    </MainLayout>
  );
};

export default inject("studioStore")(observer(AccountPage));
