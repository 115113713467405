import { WidgetConfig } from "@vondot-development/metool_lib";
import BookingDataWidget from "./booking.data.widget.component/booking.data.widget.component";
import BookingWidgetIcon from "./booking.svg";

export const BookingDataWidgetConfig: WidgetConfig = {
  name: "Booking Data Widget",
  type: "BOOKING_DATA_WIDGET",
  component: BookingDataWidget,
  icon: BookingWidgetIcon,
  canHaveChildren: false,
};
