import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import { languages } from "globals/constants/languages";
import { setProperty } from "globals/helpers/assign.object.keys.helper";
import { inject, observer } from "mobx-react";
import React from "react";
import StudioStore from "stores/studio.store";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import LinkButton from "components/input.components/link.button.component/link.button.component";

interface LocalizationConfigurationProps {
  studioStore?: StudioStore;
}

const LocalizationConfiguration = ({
  studioStore,
}: LocalizationConfigurationProps): JSX.Element => {
  const editingStudio = studioStore?.editingStudio;
  const localizations = editingStudio?.localizations;

  if (!localizations) {
    return <></>;
  }

  return (
    <ComponentWrapper
      title="Sprachen"
      className="mb-20"
      actions={
        <LinkButton
          noPadding
          label="Sprache hinufügen"
          className="mt-10"
          onClick={() => {
            localizations.push({
              language: "",
              primary: false,
            });

            studioStore?.setEditingStudio({ ...editingStudio });
          }}
        />
      }
    >
      <FormWrapper>
        {localizations.map((item, index) => (
          <Row key={item.language} alignItems="center" className="mb-15">
            <SelectDropDown
              showBorder
              items={languages}
              selectedItem={languages.find(
                (lang) => lang.code === item.language
              )}
              labelPropertyName="name"
              valuePropertyName="code"
              placeholder="Select language"
              onChange={(value) => {
                setProperty(item, "language", value?.code);
                studioStore?.setEditingStudio({ ...editingStudio });
              }}
            />
            {localizations.length > 1 && !item.primary && (
              <FontAwesomeIcon
                className="ml-10 cursor-pointer"
                icon={faTrash}
                onClick={() => {
                  localizations.splice(index, 1);

                  studioStore?.setEditingStudio({ ...editingStudio });
                }}
              />
            )}
          </Row>
        ))}
      </FormWrapper>
    </ComponentWrapper>
  );
};

export default inject("studioStore")(observer(LocalizationConfiguration));
