/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { StateStore, WidgetStore } from "@vondot-development/metool_lib";
import { inject, observer } from "mobx-react";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import Row from "components/general.compoenents/row.component/row.component";
import TextEditor from "components/input.components/text.editor.components/text.editor.component/text.editor.component";
import { CustomerTrainingDataWidgetLoading } from "../loading.component/customer.training.data.form.widget.loading.component";

interface CustomerTrainingDataFormWidgetProps {
  widgetID: string;
  widgetStore?: WidgetStore;
  stateStore?: StateStore;
}

const CustomerTrainingDataFormWidget = ({
  widgetID,
  widgetStore,
  stateStore,
}: CustomerTrainingDataFormWidgetProps): JSX.Element => {
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const analized = widgetStore?.getAnalyzedWidgetOptions(widgetID);
    if (!analized) return;

    stateStore?.initializeDynamicOptions(
      widgetID,
      analized,
      (options) => {
        setIsLoading(options?.isLoading);
        setData(options?.data);
      },
      {}
    );
  }, []);

  if (isLoading) {
    return <CustomerTrainingDataWidgetLoading />;
  }

  return (
    <ComponentWrapper title="Daten & Ziele">
      <FormWrapper title="Daten">
        <Row gap={20}>
          <OutlinedTextInput
            label="Niveau"
            initialValue={data?.trainingsLevel}
          />
          <OutlinedTextInput
            label="Trainingsrhytmus"
            initialValue={data?.weeklyTrainingRhythm}
          />
        </Row>

        <Row gap={20}>
          <TextEditor
            content={
              "Kunde hat Rückenschmerzen und sollte besonders auf die Haltung achten."
            }
            inputContentChanged={(content: any) => {}}
            className="customer-info-editor"
          />
        </Row>
      </FormWrapper>
    </ComponentWrapper>
  );
};

export default inject(
  "widgetStore",
  "stateStore"
)(observer(CustomerTrainingDataFormWidget));
