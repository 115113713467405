import React from "react";
import "./big.marketplace.card.component.scss";
import Column from "components/general.compoenents/column.component/column.component";
import {
  RunningText,
  RunningTextSkelton,
} from "components/text.components/running.text.component/running.text.component";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";
import Skeleton from "react-loading-skeleton";
import { LargeTextSkeleton } from "components/text.components/large.text.component/large.text.component";
import Row from "components/general.compoenents/row.component/row.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import GymoIcon from "assets/icons/gymo_logo.svg";
import classNames from "classnames";

interface BigMarketPlaceCardProps {
  imageUrl: string;
  title: string;
  subTitle?: string;
  description: string;
  renderTags?: () => JSX.Element;
  price?: string;
  onClick?: () => void;
  creatorLogo?: string;
}

const BigMarketPlaceCard = ({
  imageUrl,
  title,
  subTitle,
  description,
  onClick,
  renderTags,
  price,
  creatorLogo,
}: BigMarketPlaceCardProps): JSX.Element => {
  const creatorLogoUrl = creatorLogo ?? GymoIcon;

  const creatorImgWrapperClassName = classNames("creator-image-wrapper", {
    "creator-image-wrapper--padding": creatorLogo == null,
  });

  return (
    <Column
      className="big-market-place-add-on"
      onClick={onClick ? () => onClick() : undefined}
    >
      <div className="image-container">
        <div className={creatorImgWrapperClassName}>
          <img className="creator-image" src={creatorLogoUrl} />
        </div>

        <img className="add-one-image" src={imageUrl} />

        <div className="tags-wrapper">{renderTags?.()}</div>
      </div>
      <Column className="add-on-content">
        <Row justifyContent="space-between">
          <Column>
            <SmallHeadline>{title}</SmallHeadline>
            {subTitle && <RunningText>{subTitle}</RunningText>}
          </Column>
          {price && <TitleText className="price-label">{price}</TitleText>}
        </Row>
        <RunningText className="mt-15">{description}</RunningText>
      </Column>
    </Column>
  );
};

export default BigMarketPlaceCard;

//! Skeleton Loader

export const BigMarketPlaceCardSkeleton = ({
  count = 1,
}: {
  count?: number;
}): JSX.Element => {
  return (
    <>
      {Array(count)
        .fill(count)
        .map((_, i) => {
          return (
            <Column className="big-market-place-add-on" key={i}>
              <div className="image-container">
                <Skeleton height={200} />
              </div>
              <Column className="add-on-content">
                <LargeTextSkeleton />
                <RunningTextSkelton />
              </Column>
            </Column>
          );
        })}
    </>
  );
};
