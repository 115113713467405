import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Logging {
  static info = async (args: {
    className: string;
    methodName: string;
    message: string;
    exception?: any;
    showAlert?: boolean;
  }): Promise<void> => {
    // TODO comment out for FIBO 2024
    // if (args.showAlert) {
    //   toast.error(args.message);
    // }

    Sentry.withScope((scope) => {
      scope.setTag("className", args.className);
      scope.setTag("methodName", args.methodName);
      Sentry.captureMessage(args.message);
    });
  };

  static warning = async (args: {
    className: string;
    methodName: string;
    message: string;
    exception?: any;
    stacktrace?: any;
    showAlert?: boolean;
  }): Promise<void> => {
    if (args.showAlert) {
      toast.error(args.message);
    }

    Sentry.withScope((scope) => {
      scope.setTag("className", args.className);
      scope.setTag("methodName", args.methodName);
      Sentry.captureMessage(args.message, "warning");
    });
  };

  static error = async (args: {
    className: string;
    methodName: string;
    message?: string;
    exception?: any;
    showAlert?: boolean;
  }): Promise<void> => {
    if (args.showAlert) {
      toast.error(args.message);
    }

    Sentry.withScope((scope) => {
      scope.setTag("className", args.className);
      scope.setTag("methodName", args.methodName);
      scope.setTag("message", args.message);
      Sentry.captureException(args.exception);
    });
  };
}
