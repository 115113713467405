import { useNavigate } from "react-router";
import { getResource } from "./storage.helper";

// Helper function to navigate to a specific path within the studio context
export const navigationHelper = (
  rootPath?: string
): ((path: string) => void) => {
  const navigate = useNavigate();
  const studioID = getResource() || "";

  return (path: string) => {
    if (studioID == null) return;

    // Ensure rootPath starts with "/"
    if (rootPath != null && rootPath[0] !== "/") {
      rootPath = `/${rootPath}`;
    }

    // Ensure path starts with "/"
    if (path[0] !== "/") {
      path = `/${path}`;
    }

    const basePath = `/studios/${studioID}${rootPath ?? ""}`;
    navigate(`${basePath}${path}`);
  };
};

export const redirectToExternalResource = (url: string): void => {
  window.open(url);
};
