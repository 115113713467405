import { DataAttributeCollection } from "schemas/data.attribute.collection.schemas/data.attribute.collection.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpDataAttributeCollectionService extends GenericHttpClient<DataAttributeCollection> {
  static _instance: HttpDataAttributeCollectionService;
  static getInstance(): HttpDataAttributeCollectionService {
    if (this._instance == null) {
      this._instance = new HttpDataAttributeCollectionService(
        "/data-attribute-collections"
      );
    }
    return this._instance;
  }
}
