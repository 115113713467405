import React, { useEffect } from "react";
import MainLayout from "layouts/main.layout/main.layout";
import TrainingPlanStore from "stores/training.plan.store";
import { inject, observer } from "mobx-react";
import StudioStore from "stores/studio.store";
import TrainingPlanList from "./components/training.plan.list.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import planIllustration from "assets/illustrations/feed-mockup.png";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import { ModalStore } from "stores/modal.store";
import { SearchTopic } from "stores/global.search.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Row from "components/general.compoenents/row.component/row.component";
import { createTranslate } from "globals/helpers/global.helper";
import { useTranslation } from "react-i18next";
import PageIntroCard from "components/card.components/page.intro.card.component/page.intro.card.component";
import { navigationHelper } from "globals/helpers/navigation.helper";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";

interface TrainingPlanOverviewPageProps {
  trainingPlanStore?: TrainingPlanStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const TrainingPlanOverviewPage = ({
  trainingPlanStore,
  studioStore,
  modalStore,
}: TrainingPlanOverviewPageProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "trainingPlanPage.overview");
  const navigate = navigationHelper();

  const trainingPlans = trainingPlanStore?.trainingPlans?.data ?? [];

  useEffect(() => {
    initializePage();
  }, []);

  const initializePage = async (): Promise<void> => {
    trainingPlanStore?.fetchAndSetTrainingPlans({});
  };

  const handleClickPlan = (
    plan: TrainingPlan | undefined,
    isNew?: boolean | undefined
  ): void => {
    if (studioStore?.studio?._id == null) return;
    if (plan?._id != null && !isNew) {
      navigate(`training-plans/${plan?._id}/info`);
    } else {
      navigate(`training-plans/new/info`);
    }
  };

  return (
    <MainLayout>
      <PageContainer>
        <WidthLimiterWrapper>
          <PageIntroCard
            image={planIllustration}
            imagePosition="bottom center"
            title={translate("introTitle")}
            description={translate("introDescription")}
            buttonText={translate("addButton")}
            buttonAction={() => {
              handleClickPlan(undefined, true);
            }}
          />
          <Row justifyContent="flex-end">
            <FilledButton
              className="mb-15"
              icon={faSearch}
              label="Search"
              onClick={() => {
                modalStore?.openGlobalSearchModal(SearchTopic.TRAINING_PLAN);
              }}
            />
          </Row>
          <TrainingPlanList
            isLoading={trainingPlanStore?.trainingPlans?.isLoading}
            plans={trainingPlans}
            onClick={(plan: TrainingPlan) => handleClickPlan(plan, false)}
          />
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "trainingPlanStore",
  "studioStore",
  "modalStore"
)(observer(TrainingPlanOverviewPage));
