export enum RoleAffiliationType {
  USER = "USER",
  TRAINER = "TRAINER",
  ORGANIZATION_ADMIN = "ORGANIZATION_ADMIN",
  STUDIO_ADMIN = "STUDIO_ADMIN",
}

export enum PermissionType {
  SERVER = "SERVER",
  CLIENT = "CLIENT",
}

export enum LanguageCode {
  DE = "de",
  EN = "en",
}

export enum ContainerSizes {
  S = "s",
  M = "m",
  L = "l",
  XML = "xml",
  XL = "xl",
  XXL = "xxl",
  CUSTOM = "CUSTOM",
}

export enum ImageSizes {
  S = "s",
  M = "m",
  L = "l",
  XL = "xl",
  FULL_SIZE = "FULL_SIZE",
  XXL = "xxl",
}

export enum TrainingLevel {
  BEGINNER = 20,
  ROOKIE = 40,
  ADVANCED = 60,
  EXPERT = 80,
  ARNOLD = 100,
}

export enum PropertyType {
  MUSCLE_GROUP = "MUSCLE_GROUP",
  READABLE_MUSCLE_GROUP = "READABLE_MUSCLE_GROUP",
  TAG = "TAG",
  DEVICE = "DEVICE",
  EXERCISE_PROPERTY = "EXERCISE_PROPERTY",
}

export enum CollectionDataType {
  TEMPLATE = "TEMPLATE",
  STUDIO = "STUDIO",
  USER = "USER",
}

export enum BookingInfoStatus {
  PARTIALLY_BOOKED = "PARTIALLY_BOOKED",
  FULLY_BOOKED = "FULLY_BOOKED",
}
