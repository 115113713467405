export interface DemoOrder {
  number: number;
  customerName: string;
  date: string;
  total: string;
  payment: string;
  status: string;
}

export const demoOrders: DemoOrder[] = [
  {
    number: 12345678,
    customerName: "Max Mustermann",
    date: "01.04.2024",
    total: "25€",
    payment: "online",
    status: "offen",
  },
  {
    number: 23456789,
    customerName: "Erika Musterfrau",
    date: "02.04.2024",
    total: "30€",
    payment: "offline",
    status: "abgeschlossen",
  },
  {
    number: 34567890,
    customerName: "Julia Schmitt",
    date: "03.04.2024",
    total: "45€",
    payment: "online",
    status: "offen",
  },
  {
    number: 45678901,
    customerName: "Tobias Huber",
    date: "04.04.2024",
    total: "40€",
    payment: "offline",
    status: "offen",
  },
  {
    number: 56789012,
    customerName: "Sophia Müller",
    date: "05.04.2024",
    total: "20€",
    payment: "online",
    status: "abgeschlossen",
  },
  {
    number: 67890123,
    customerName: "Lukas Schmidt",
    date: "06.04.2024",
    total: "35€",
    payment: "offline",
    status: "offen",
  },
  {
    number: 78901234,
    customerName: "Anna Schneider",
    date: "07.04.2024",
    total: "50€",
    payment: "online",
    status: "abgeschlossen",
  },
  {
    number: 89012345,
    customerName: "Finn Fischer",
    date: "08.04.2024",
    total: "15€",
    payment: "offline",
    status: "offen",
  },
  {
    number: 90123456,
    customerName: "Mia Becker",
    date: "09.04.2024",
    total: "25€",
    payment: "online",
    status: "offen",
  },
  {
    number: 12345670,
    customerName: "Noah Krause",
    date: "10.04.2024",
    total: "30€",
    payment: "offline",
    status: "abgeschlossen",
  },
];
