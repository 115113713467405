import { RunningText } from "components/text.components/running.text.component/running.text.component";
import React from "react";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import ListDataTable from "../list.data.table.component/list.data.table.component";
import { formatDate } from "globals/helpers/global.helper";
import { ColorInputReadOnly } from "components/input.components/color.input.component/color.input.component";
import Row from "components/general.compoenents/row.component/row.component";

interface GlobalTrainingPlanListProps {
  trainingPlans: TrainingPlan[];
  onClick?: (dataItem: TrainingPlan) => void;
  noDataMessage: string;
  disableHeader?: boolean;
}

const GlobalTrainingPlanList = ({
  trainingPlans,
  onClick,
  noDataMessage,
  disableHeader = false,
}: GlobalTrainingPlanListProps): JSX.Element => {
  return (
    <ListDataTable
      disableHeader={disableHeader}
      noDataMessage={noDataMessage}
      onClick={onClick}
      data={trainingPlans}
      columns={[
        {
          child: <RunningText>Plan</RunningText>,
        },
        {
          child: <RunningText>Übungen</RunningText>,
        },
        {
          child: <RunningText>Geändert am </RunningText>,
        },
      ]}
      dataTableItemBuilder={(dataItem: TrainingPlan) => {
        return {
          key: dataItem._id ?? `trainingplan-${dataItem.title}`,
          children: [
            {
              child: (
                <Row alignItems="center">
                  <ColorInputReadOnly value={dataItem?.coverColor ?? ""} />
                  <RunningText className="ml-15">{dataItem.title}</RunningText>
                </Row>
              ),
            },
            {
              child: (
                <RunningText>{dataItem.exercises.length} Übungen</RunningText>
              ),
            },
            {
              child: (
                <RunningText>
                  {formatDate(dataItem.system?.modifiedAt, "DD.MM.YYYY")}
                </RunningText>
              ),
            },
          ],
        };
      }}
    />
  );
};

export default GlobalTrainingPlanList;
