import React from "react";
import bookingSystemAddon from "assets/addons/booking_system_addon.png";
import customerManagmentAddon from "assets/addons/customer_managment_addon.png";
import Column from "components/general.compoenents/column.component/column.component";
import { useTranslation } from "react-i18next";
import {
  createTranslate,
  generatePlaceholderAvatarUrl,
} from "globals/helpers/global.helper";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { navigationHelper } from "globals/helpers/navigation.helper";
import BigMarketPlaceCard from "pages/marketplace.pages/components/big.marketplace.cardcomponent/big.marketplace.card.component";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import Row from "components/general.compoenents/row.component/row.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";

interface MarketPlaceAddOnPageProps {
  showHeader?: boolean;
}

const MarketplaceAddOnPage = ({
  showHeader = false,
}: MarketPlaceAddOnPageProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, `marketplacePage.overview.addons`);
  const navigate = navigationHelper();

  return (
    <Column className="mt-30 mb-30">
      {showHeader && (
        <LinkButton
          width="fit-content"
          fontSize="headline"
          label={translate("introTitle")}
          color="secondary"
          icon={faChevronRight}
          noPadding
          wrapperClassName="mb-10"
          onClick={() => navigate("marketplace/addons")}
        />
      )}

      <Wrap gap={20}>
        <BigMarketPlaceCard
          title="Buchungssystem"
          description="Nutze unser Buchungssystem und biete deine Dienstleistungen digital über die App und Homepage an. Kurse, Personal Training und Räume können bequem erstellt, verwaltet und gebucht werden."
          imageUrl={bookingSystemAddon}
          creatorLogo={generatePlaceholderAvatarUrl("testd")}
          price="Free"
          renderTags={() => (
            <Row gap={5}>
              <HighlightedInfoText fitContent variant="small">
                Neu
              </HighlightedInfoText>
              <HighlightedInfoText fitContent variant="small">
                Neu
              </HighlightedInfoText>
              <HighlightedInfoText fitContent variant="small">
                Neu
              </HighlightedInfoText>
            </Row>
          )}
        />
        <BigMarketPlaceCard
          title="Mitgliederverwaltung"
          description="Verwalte deine Mitglieder einfach und effizient mit unserer Mitgliederverwaltungsfunktion. Alles auf einen Blick – von Mitgliederdetails bis hin zu Trainingsanalyse und Plan Erstellung."
          imageUrl={customerManagmentAddon}
        />
        <BigMarketPlaceCard
          title="Kassensystem"
          description="Optimiere dein Zahlungsprozess mit unserem Kassensystem. Einfach, sicher und effizient – verwalte Transaktionen und Zahlungen bequem über unsere Plattform."
          imageUrl={customerManagmentAddon}
        />
        <BigMarketPlaceCard
          title="Mahnwesen"
          description="Verwalte Zahlungsrückstände einfach mit unserem Mahnwesen. Automatisierte Mahnungen helfen Ihnen, Ihren Cashflow zu optimieren."
          imageUrl={customerManagmentAddon}
        />
        <BigMarketPlaceCard
          title="Gutscheine"
          description="Verwalte deine individuellen Gutscheine mühelos mit unserer Funktion. Erstellen, verfolgen und einlösen – alles mit Leichtigkeit."
          imageUrl={customerManagmentAddon}
        />
        <BigMarketPlaceCard
          title="QR-Code Erweiterung"
          description="Durch die Integration unseres QR-Systems können Sie problemlos all Ihre Geräte digitalisieren. Bestimme den Inhalt, benutze unsere Bibliothek oder deine eigenen Videos."
          imageUrl={customerManagmentAddon}
        />
        <BigMarketPlaceCard
          title="Webshop"
          description="Nutze unsere integrierte Lösung, um Ihren eigenen Webshop unkompliziert einzurichten. Verwalte deine eigenen Produkte, Bestellungen und Verkäufe."
          imageUrl={customerManagmentAddon}
        />
        <BigMarketPlaceCard
          title="Marketingpaket"
          description="Buche unser Marketingpaket und erhalten für dein Studio angepasste Banner, Flyer & Video-Content sowie ein 1-zu-1-Coaching mit einem unserer Marketingexperten."
          imageUrl={customerManagmentAddon}
        />
      </Wrap>
    </Column>
  );
};

export default MarketplaceAddOnPage;
