/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as yup from "yup";
import { languageSchema } from "../language.string.schema";
import { Localization, Studio } from "../studio.schemas/studio.schema";
import { System } from "../system.schema";
import { languageObjectSchema } from "schemas/language.object.schema";

export enum ContentLibraryType {
  EXERCISE = "EXERCISE",
}

export const contentLibrarySchema = (languages: Localization[]) =>
  yup.object().shape({
    _id: yup.string().notRequired(),
    title: languageSchema(languages).required(),
    description: languageObjectSchema(languages).notRequired(),
    images: yup.array().of(yup.string()).notRequired(),
    coverImageUrl: yup.string().required(),
    type: yup.string().oneOf(Object.values(ContentLibraryType)).required(),
    isPublished: yup.boolean().required(),
  });

export interface ContentLibrary
  extends yup.InferType<ReturnType<typeof contentLibrarySchema>> {
  studio: Studio;
  system?: System;
}

export interface InstalledContentLibrary extends ContentLibrary {
  contentCount: number;
}
