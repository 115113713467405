import React from "react";
import { NavigationActionType } from "@vondot-development/metool_lib/dist/globals/interfaces/navigation.interface";
import { RenderPage as RenderMetoolPage } from "@vondot-development/metool_lib";
import { useParams } from "react-router-dom";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";

const RenderPage = (): JSX.Element => {
  const { pageID } = useParams();

  return (
    <>
      <PageContainer>
        {pageID != null && (
          <RenderMetoolPage
            pageToRender={{
              actionType: NavigationActionType.NAV_TO_PAGE,
              targetID: pageID,
            }}
          />
        )}
      </PageContainer>
    </>
  );
};

export default RenderPage;
