import ContentLibraryStore from "./content.library.store";
import CustomerStore from "./customer.store";
import DataAttributeCollectionStore from "./data.attribute.collection.store";
import DataAttributeStore from "./data.attribute.store";
import ExerciseStore from "./exercise.store";
import FeedStore from "./feed.store";
import GlobalSearchStore from "./global.search.store";
import GymoCustomerStore from "./gymo.customer.store";
import MetoolStore from "./metool.store";
import { ModalStore } from "./modal.store";
import PaymentStore from "./payment.store";
import PropertyStore from "./property.store";
import StudioStore from "./studio.store";
import TrainingPlanStore from "./training.plan.store";
import UserStore from "./user.store";
import AssetStore from "./asset.store";

class RootStore {
  userStore: UserStore;
  studioStore: StudioStore;
  modalStore: ModalStore;
  exerciseStore: ExerciseStore;
  propertyStore: PropertyStore;
  feedStore: FeedStore;
  trainingPlanStore: TrainingPlanStore;
  paymentStore: PaymentStore;
  customerStore: CustomerStore;
  gymoCustomerStore: GymoCustomerStore;
  metoolStore: MetoolStore;
  dataAttributeCollectionStore: DataAttributeCollectionStore;
  dataAttributeStore: DataAttributeStore;
  globalSearchStore: GlobalSearchStore;
  contentLibraryStore: ContentLibraryStore;
  assetStore: AssetStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.studioStore = new StudioStore(this);
    this.modalStore = new ModalStore(this);
    this.exerciseStore = new ExerciseStore(this);
    this.propertyStore = new PropertyStore(this);
    this.feedStore = new FeedStore(this);
    this.trainingPlanStore = new TrainingPlanStore(this);
    this.paymentStore = new PaymentStore(this);
    this.customerStore = new CustomerStore(this);
    this.gymoCustomerStore = new GymoCustomerStore(this);
    this.metoolStore = new MetoolStore(this);
    this.dataAttributeCollectionStore = new DataAttributeCollectionStore(this);
    this.dataAttributeStore = new DataAttributeStore(this);
    this.globalSearchStore = new GlobalSearchStore(this);
    this.contentLibraryStore = new ContentLibraryStore(this);
    this.assetStore = new AssetStore(this);
  }
}

export default RootStore;
