import React from "react";
import Column from "components/general.compoenents/column.component/column.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import Image from "components/image.component/image.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Headline from "components/text.components/headline.component/headline.component";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { ImageSizes } from "globals/enums/global.enum";
import { generatePlaceholderAvatarUrl } from "globals/helpers/global.helper";
import "./marketplace.detail.hero.component.scss";

interface MarketplaceDetailHeroProps {
  title: string | undefined;
  description?: string;
  coverImageUrl?: string;
  tags?: string[];
  creator?: {
    name: string | undefined;
    logoUrl?: string;
    price?: string;
  };
  renderInfo?: () => JSX.Element | undefined;
}

const MarketplaceDetailHero = ({
  title,
  description,
  coverImageUrl,
  tags,
  creator,
  renderInfo,
}: MarketplaceDetailHeroProps): JSX.Element => {
  return (
    <ComponentWrapper className="mb-10">
      <Row gap={20}>
        <Column className="relative">
          {renderInfo != null && (
            <div className="installed-indicator">{renderInfo()}</div>
          )}
          <Image
            className="library-image"
            imageUrl={coverImageUrl}
            size={ImageSizes.FULL_SIZE}
          />
          <FormWrapper className="p-10 mt-20" noPadding>
            <Row alignItems="center" gap={10}>
              <Row alignItems="center">
                <Image
                  className="mr-10"
                  size={ImageSizes.M}
                  imageUrl={
                    creator?.logoUrl ??
                    generatePlaceholderAvatarUrl(creator?.name)
                  }
                />
                <RunningText>{creator?.name}</RunningText>
              </Row>

              {creator?.price && (
                <FilledButton
                  label={creator?.price}
                  color="secondary"
                  className="mr"
                />
              )}
            </Row>
          </FormWrapper>
        </Column>
        <FormWrapper>
          <Headline className="mb-10">{title}</Headline>

          {tags && (
            <Row gap={10}>
              {tags.map((tag) => (
                <HighlightedInfoText key={tag} fitContent>
                  {tag}
                </HighlightedInfoText>
              ))}
            </Row>
          )}

          <Spacer className="mb-15 mt-10" />
          <TitleText className="bold mb-5">Über die Content Library</TitleText>
          {description && <RunningText>{description}</RunningText>}
        </FormWrapper>
      </Row>
    </ComponentWrapper>
  );
};

export default MarketplaceDetailHero;
