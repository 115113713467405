import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./slider.input.component.scss";
import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";

interface SliderInputProps {
  value?: number;
  onChange?: (value: number | number[]) => void;
  minValue?: number;
  maxValue?: number;
  step?: number;
  label?: string;
  formattedValue?: string;
  className?: string;
}

const SliderInput = ({
  value,
  onChange,
  minValue,
  maxValue,
  step,
  label,
  formattedValue,
  className,
}: SliderInputProps): JSX.Element => {
  return (
    <Column className={className}>
      <Row className="mb-5" justifyContent="space-between">
        {label && <TitleText>{label}</TitleText>}
        {formattedValue && <RunningText>{formattedValue}</RunningText>}
      </Row>
      <Slider
        min={minValue}
        max={maxValue}
        value={value}
        onChange={onChange}
        step={step}
      />
    </Column>
  );
};

export default SliderInput;
