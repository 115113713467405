import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import MainLayout from "layouts/main.layout/main.layout";
import FixedControllersToolbar from "components/navigation.components/fixed.controller.toolbar.component/fixed.controller.toolbar.component";
import React, { useState } from "react";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import TextEditor from "components/input.components/text.editor.components/text.editor.component/text.editor.component";

const demoEmailContent = {
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Liebe Mitglieder,",
        },
      ],
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "wir freuen uns, Ihnen mitteilen zu können, dass wir unseren Service erheblich erweitert haben und nun stolz verkünden können, dass unser Studio zu 100% digitalisiert ist. Als geschätztes Mitglied erhalten Sie Zugang zu unserer umfangreichen App, die Ihnen ein einzigartiges Fitnesserlebnis bietet.",
        },
      ],
    },
    {
      type: "paragraph",
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Mit unserer neuen App haben Sie die Möglichkeit, Ihre Fitnessreise noch individueller zu gestalten. Sie können nicht nur Ihre eigenen Trainingspläne erstellen, sondern auch von unseren erfahrenen Trainern maßgeschneiderte Pläne erstellen lassen, die Ihren persönlichen Zielen entsprechen. Darüber hinaus können Sie bequem alle unsere Kurse buchen, um Ihr Training abwechslungsreich zu gestalten und neue Fitnessziele zu erreichen.",
        },
      ],
    },
    {
      type: "paragraph",
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Unser Newsfeed hält Sie stets auf dem Laufenden über die neuesten Entwicklungen, Veranstaltungen und Angebote in unserem Studio. So verpassen Sie nie wieder wichtige Informationen und haben immer Zugang zu exklusiven Angeboten.",
        },
      ],
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Das Beste daran? All diese Funktionen sind in Ihrem Mitgliedschaftsabo enthalten, sodass Sie das volle Potenzial unserer App nutzen können, ohne zusätzliche Kosten.",
        },
      ],
    },
    {
      type: "paragraph",
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Laden Sie die App noch heute herunter und tauchen Sie ein in eine neue Ära des Fitnessstudios, die Flexibilität, Personalisierung und Innovation vereint. Wir wünschen Ihnen viel Spaß und Erfolg auf Ihrer Fitnessreise.",
        },
      ],
    },
    {
      type: "paragraph",
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Mit sportlichen Grüßen,",
        },
      ],
    },
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "Eurer Powerhouse",
        },
      ],
    },
    {
      type: "paragraph",
    },
  ],
};

const MarketingEmailPage = (): JSX.Element => {
  const [subject, setSubject] = useState("Unser neues Powerhouse App!");

  const _buildEmailForm = (): JSX.Element => {
    return (
      <ComponentWrapper title="Email bearbeiten">
        <OutlinedTextInput
          label="Betreff"
          value={subject}
          onChange={(text: any) => {
            if (text) {
              setSubject(text);
            }
          }}
        />
        <TextEditor content={demoEmailContent} />
      </ComponentWrapper>
    );
  };

  return (
    <MainLayout
      topBars={[
        <PageHeadline
          showBackButton
          key="push-notifications"
          title="Marketing email"
        />,
      ]}
    >
      <PageContainer>
        {_buildEmailForm()}
        <FixedControllersToolbar
          disabled={false}
          isLoading={false}
          hasUnsavedChanges={true}
          keyString="booking-options-save-btn"
          title={subject}
        />
      </PageContainer>
    </MainLayout>
  );
};

export default MarketingEmailPage;
