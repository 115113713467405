/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { useForm } from "react-hook-form";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import StudioStore from "stores/studio.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import PageHeader from "components/navigation.components/page.header.component/page.header.component";
import { getFormattedErrorMessage } from "globals/helpers/validation.helper";
import { toast } from "react-toastify";
import GymoCustomerStore from "stores/gymo.customer.store";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import { appConfigurationIsValid } from "globals/data/globals.data";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import Row from "components/general.compoenents/row.component/row.component";
import { LargeText } from "components/text.components/large.text.component/large.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { studioSchema } from "schemas/studio.schemas/studio.schema";
import { HttpActionrService } from "services/httpClients/http.action.client";
import { HttpGymoCustomerService } from "services/httpClients/http.gymo.customer.client";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import {
  saveAccessToken,
  setLastVisitedStudio,
  setResource,
} from "globals/helpers/storage.helper";

interface AppConfigurationFormProps {
  gymoCustomerStore?: GymoCustomerStore;
  studioStore?: StudioStore;
  onDirty: (isDirty: boolean) => void;
}

const AppConfigurationForm = ({
  gymoCustomerStore,
  studioStore,
  onDirty,
}: AppConfigurationFormProps): JSX.Element => {
  const gymoCustomer = gymoCustomerStore?.currentGymoCustomer?.data;

  const [isUploading, setIsUploading] = useState(false);

  const copyToClipboard = (text: string): void => {
    navigator.clipboard.writeText(text);
  };

  // save acces token and resource in local storage and reload page
  const storeCustomerInLocalStorageAndOpenInNewTab =
    async (): Promise<void> => {
      const customer = gymoCustomerStore?.currentGymoCustomer?.data;

      if (customer == null) {
        return;
      }

      const response =
        await HttpGymoCustomerService.getInstance().getAccessTokenForStudioDashboardUser(
          customer._id
        );

      if (response == null) {
        return;
      }

      setLastVisitedStudio(customer._id);
      setResource(customer._id);
      saveAccessToken(response.access_token);
      window.alert(
        `ACHTUNG: Du wirst nach dem Klick auf OK automatisch als ${customer?.name} eingeloggt.`
      );

      window.location.reload();
    };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    clearErrors,
  } = useForm({
    resolver: yupResolver(studioSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: gymoCustomer,
  });

  useEffect(() => {
    onDirty(isDirty);
  }, [isDirty]);

  const setValueAndMarkDirty = (name: any, value: any): void => {
    setValue(name, value, { shouldDirty: true });
  };

  // handle gymo customer form submit
  const onSubmit = async (data: any): Promise<void> => {
    if (gymoCustomer == null) {
      return;
    }

    gymoCustomerStore?.setCurrentGymoCustomer({
      ...gymoCustomer,
      ...data,
    });

    await gymoCustomerStore?.updateGymoCustomer({
      gymoCustomer: {
        ...gymoCustomer,
        ...data,
      },
    });
  };

  if (gymoCustomer == null) {
    return <></>;
  }

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit, (errors) => {
        toast.error("Bitte überprüfe deine Eingaben");
      })}
    >
      <PageHeader label={gymoCustomer?.name ?? "-"}>
        <FilledButton
          disabled={isUploading || !isDirty}
          label="Speichern"
          color="secondary"
          type="submit"
        />
      </PageHeader>

      <ComponentWrapper title="Aktionen" className="mb-20">
        <Row>
          <FilledButton
            label="Actions generieren"
            className="mr-15"
            onClick={async () => {
              try {
                await HttpActionrService.getInstance().generateActionsForCurrentStudio(
                  {
                    studioID: gymoCustomer?._id ?? "",
                  }
                );
                toast.success("Actions erfolgreich generiert");
              } catch (err) {
                toast.error("Fehler beim generieren der Actions");
              }
            }}
          />
          <FilledButton
            className="mr-15"
            label="QR Codes exportieren"
            onClick={async () => {
              try {
                await HttpActionrService.getInstance().exportQrCodeForCurrentStudio(
                  {
                    studioID: gymoCustomer?._id ?? "",
                  }
                );

                toast.success("QR-Codes erfolgreich exportiert");
              } catch (err) {
                toast.error("QR-Codes konnten nicht exportiert werden");
              }
            }}
          />
        </Row>
      </ComponentWrapper>

      <ComponentWrapper title="App veröffentlichung" className="mb-20">
        <Row>
          <FilledButton
            label="Produktions-Veröffentlichung"
            className="mr-15"
            onClick={async () => {
              try {
                await HttpGymoCustomerService.getInstance().publishApp({
                  studioID: gymoCustomer?._id ?? "",
                  workflow: "production",
                });
                toast.success("App erfolgreich veröffentlicht");
              } catch (err) {
                toast.error("Fehler beim veröffentlichen der App");
              }
            }}
          />
          <FilledButton
            label="Development-Veröffentlichung"
            className="mr-15"
            onClick={async () => {
              try {
                await HttpGymoCustomerService.getInstance().publishApp({
                  studioID: gymoCustomer?._id ?? "",
                  workflow: "development",
                });
                toast.success("App erfolgreich veröffentlicht");
              } catch (err) {
                toast.error("Fehler beim veröffentlichen der App");
              }
            }}
          />
        </Row>
      </ComponentWrapper>

      <ComponentWrapper title="Informationen" className="mb-20">
        <SizedContainer size={ContainerSizes.M}>
          <Row
            justifyContent="space-between"
            alignItems="center"
            className="mb-10"
          >
            <LargeText className="bold">Studio Name:</LargeText>
            <RunningText>{gymoCustomer?.name ?? "keine Angabe"}</RunningText>
          </Row>
          <Row
            justifyContent="space-between"
            alignItems="center"
            className="mb-10"
          >
            <LargeText className="bold">Name Zusatz:</LargeText>
            <RunningText>
              {gymoCustomer?.nameAddition ?? "keine Angabe"}
            </RunningText>
          </Row>
          <Row
            justifyContent="space-between"
            alignItems="center"
            className="mb-10"
          >
            <LargeText className="bold">Studio Email:</LargeText>
            <RunningText>{gymoCustomer?.email ?? "keine Angabe"}</RunningText>
          </Row>
          <Row
            justifyContent="space-between"
            alignItems="center"
            className="mb-10"
          >
            <LargeText className="bold">Studio CheckIn Code:</LargeText>
            <RunningText>
              {gymoCustomer?.verificationCode ?? "keine Angabe"}
            </RunningText>
          </Row>
        </SizedContainer>
      </ComponentWrapper>

      <ComponentWrapper title="App Demo User" className="mb-20">
        <SizedContainer size={ContainerSizes.M}>
          <Row
            justifyContent="space-between"
            alignItems="center"
            className="mb-10"
          >
            <LargeText className="bold">Email:</LargeText>
            <RunningText>
              {gymoCustomer?.demoUser?.email ?? "keine Angabe"}
            </RunningText>
          </Row>
          <Row
            justifyContent="space-between"
            alignItems="center"
            className="mb-10"
          >
            <LargeText className="bold">Passwort:</LargeText>
            <RunningText>
              {gymoCustomer?.demoUser?.password ?? "keine Angabe"}
            </RunningText>
          </Row>
        </SizedContainer>
      </ComponentWrapper>

      <ComponentWrapper title="Development" className="mb-20">
        <SizedContainer size={ContainerSizes.M}>
          <Row
            justifyContent="space-between"
            alignItems="center"
            className="mb-10"
          >
            <LargeText className="bold">Resource:</LargeText>
            <RunningText>{gymoCustomer?._id ?? "keine Angabe"}</RunningText>
          </Row>
        </SizedContainer>
        <LinkButton
          className="pl-0"
          label="Demo-User Token kopieren"
          onClick={async () => {
            try {
              const response =
                await HttpGymoCustomerService.getInstance().getAccessTokenForStudioDemoUser(
                  gymoCustomer?._id
                );

              copyToClipboard(response.access_token ?? "undefined");
              toast.success("Token wurde in die Zwischenablage Kopiert!");
            } catch (err) {
              toast.error("Fehler beim anfordern des Tokens!");
            }
          }}
        />
        <LinkButton
          label="Dashboard-User Token kopieren"
          onClick={async () => {
            try {
              const response =
                await HttpGymoCustomerService.getInstance().getAccessTokenForStudioDashboardUser(
                  gymoCustomer?._id
                );

              copyToClipboard(response.access_token ?? "undefined");
              toast.success("Token wurde in die Zwischenablage Kopiert!");
            } catch (err) {
              toast.error("Fehler beim anfordern des Tokens!");
            }
          }}
        />
        <LinkButton
          label="Login mit Kunde"
          onClick={async () => {
            try {
              storeCustomerInLocalStorageAndOpenInNewTab();
            } catch (err) {
              toast.error("Fehler beim öffnen des Kunden!");
            }
          }}
        />
      </ComponentWrapper>

      <ComponentWrapper title="App Store Konfiguration">
        <SizedContainer size={ContainerSizes.XL}>
          <OutlinedTextInput
            label="App name"
            inputRef={register("appConfiguration.name")}
            validationMessage={getFormattedErrorMessage(
              "App Store Name",
              errors.appConfiguration?.name
            )}
          />

          <TitleText className="mb-20">Firebase config</TitleText>

          <OutlinedTextInput
            label="iOS Client ID"
            className="mt-30"
            inputRef={register("appConfiguration.firebase.iosClientId")}
            validationMessage={getFormattedErrorMessage(
              "App Store Name",
              errors.appConfiguration?.firebase?.iosClientId
            )}
          />

          <OutlinedTextInput
            label="Android Client ID"
            className="mt-30"
            inputRef={register("appConfiguration.firebase.androidClientId")}
            validationMessage={getFormattedErrorMessage(
              "App Store Name",
              errors.appConfiguration?.firebase?.androidClientId
            )}
          />

          <OutlinedTextInput
            label="Project ID"
            className="mt-30"
            inputRef={register("appConfiguration.firebase.projectId")}
            validationMessage={getFormattedErrorMessage(
              "App Store Name",
              errors.appConfiguration?.firebase?.projectId
            )}
          />

          <OutlinedTextInput
            label="Deep Link URL Scheme"
            className="mt-30"
            inputRef={register("appConfiguration.firebase.deepLinkURLScheme")}
            validationMessage={getFormattedErrorMessage(
              "App Store Name",
              errors.appConfiguration?.firebase?.deepLinkURLScheme
            )}
          />

          <OutlinedTextInput
            label="FCM Server Key"
            className="mt-30"
            inputRef={register("appConfiguration.firebase.fcmServerKey")}
            validationMessage={getFormattedErrorMessage(
              "FCM Server Key",
              errors.appConfiguration?.firebase?.fcmServerKey
            )}
          />

          <TitleText className="mb-20">iOS config</TitleText>
          <OutlinedTextInput
            label="App Store ID"
            className="mt-30"
            inputRef={register("appConfiguration.ios.appStoreId")}
            validationMessage={getFormattedErrorMessage(
              "App Store ID",
              errors.appConfiguration?.ios?.appStoreId
            )}
          />

          <OutlinedTextInput
            label="Bundle ID"
            className="mt-30"
            inputRef={register("appConfiguration.ios.bundleId")}
            validationMessage={getFormattedErrorMessage(
              "Bundle ID",
              errors.appConfiguration?.ios?.bundleId
            )}
          />

          <TitleText className="mb-20">Android config</TitleText>
          <OutlinedTextInput
            label="Bundle ID"
            className="mt-30"
            inputRef={register("appConfiguration.android.packageName")}
            validationMessage={getFormattedErrorMessage(
              "Bundle ID",
              errors.appConfiguration?.android?.packageName
            )}
          />

          <SelectDropDown
            label="App Konfiguration Valid"
            className="mb-10"
            selectedItem={appConfigurationIsValid.find(
              (item) =>
                item.value === gymoCustomer?.appConfiguration?.configIsValid
            )}
            items={appConfigurationIsValid ?? []}
            onChange={(item) => {
              setValueAndMarkDirty(
                "appConfiguration.configIsValid",
                item?.value
              );
              clearErrors("appConfiguration.configIsValid");
            }}
            inputRef={register("appConfiguration.configIsValid")}
            validationMessage={errors.appConfiguration?.configIsValid?.message?.toString()}
          />
        </SizedContainer>
        <Wrap className="mt-30" alignItems="flex-start">
          <SizedContainer size={ContainerSizes.M} className="mr-30">
            <FileUpload
              label="App Icon"
              isUploading={(uploading) => {
                setIsUploading(uploading);
              }}
              aspectRatio={1 / 1}
              folder="app-icons"
              inputRef={register("appConfiguration.iconUrl")}
              fileUrl={getProperty(gymoCustomer, "appConfiguration.iconUrl")}
              validationMessage={errors.appConfiguration?.iconUrl?.message?.toString()}
              onFilesUploaded={(files: any) => {
                if (files && files.length > 0) {
                  setValueAndMarkDirty(
                    "appConfiguration.iconUrl",
                    files[0].path
                  );
                  clearErrors("appConfiguration.iconUrl");
                }
              }}
            />

            <FileUpload
              label="Splash Screen Icon"
              isUploading={(uploading) => {
                setIsUploading(uploading);
              }}
              aspectRatio={1 / 1}
              folder="app-icons"
              inputRef={register("appConfiguration.splashIconUrl")}
              fileUrl={getProperty(
                gymoCustomer,
                "appConfiguration.splashIconUrl"
              )}
              validationMessage={errors.appConfiguration?.splashIconUrl?.message?.toString()}
              onFilesUploaded={(files: any) => {
                if (files && files.length > 0) {
                  setValueAndMarkDirty(
                    "appConfiguration.splashIconUrl",
                    files[0].path
                  );
                  clearErrors("appConfiguration.splashIconUrl");
                }
              }}
            />
          </SizedContainer>
        </Wrap>
      </ComponentWrapper>
    </form>
  );
};

export default inject(
  "gymoCustomerStore",
  "studioStore"
)(observer(AppConfigurationForm));
