import React, { useEffect, useState } from "react";
import NavListItem, {
  NavListItemSkeleton,
} from "../nav.list.item.component/nav.list.item.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { inject, observer } from "mobx-react";
import StudioStore from "stores/studio.store";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import Column from "components/general.compoenents/column.component/column.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import PropertyStore from "stores/property.store";
import { getProperty } from "globals/helpers/assign.object.keys.helper";
import { propertyTypeOptions } from "globals/data/globals.data";
import NavListNoSearchResultComponent from "components/modal.components/no.search.result.components/nav.list.no.search.result.component/nav.list.no.search.result.component";

interface PropertyNavListProps {
  propertyStore?: PropertyStore;
  studioStore?: StudioStore;
}

const PropertyNavList = ({
  propertyStore,
  studioStore,
}: PropertyNavListProps): JSX.Element => {
  const navigate = useNavigate();
  const { propertyID } = useParams();
  const isLoading = propertyStore?.properties?.isLoading;
  const properties = propertyStore?.properties?.data ?? [];
  const currentProperty = propertyStore?.currentProperty?.data;

  const [isSearchActive, setIsSearchActive] = useState(false);

  const propertySearchResult = propertyStore?.propertiesSearchResult ?? [];

  // if isSearch is active, use propertySearchResult otherwise use property
  const propertiesToRender = isSearchActive ? propertySearchResult : properties;

  // query
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const filter = searchParams.get("filter");

  useEffect(() => {
    propertyStore?.fetchAndSetProperties({
      refresh: true,
      filter,
    });
  }, [filter]);

  const _buildNewPropertyPreview = (): JSX.Element => {
    return (
      <NavListItem
        onClick={() => {
          if (studioStore?.studio?._id != null) {
            navigate(
              `/studios/${studioStore?.studio?._id}/properties/new/info`
            );
          }
        }}
        title={getProperty(currentProperty, "title.de") ?? "Neues Gerät"}
        imageUrl={getProperty(currentProperty, "coverImageUrl")}
        subtitle="Erstelle ein neues Property"
        isActive
      />
    );
  };

  const _buildPropertyList = (): JSX.Element => {
    if (isLoading === true) {
      return (
        <div className="global-nav-list-wrapper">
          <NavListItemSkeleton count={10} />
        </div>
      );
    }

    return (
      <div className="global-nav-list-wrapper">
        {propertyID === "new" && _buildNewPropertyPreview()}

        {propertiesToRender.length === 0 &&
          properties.length > 0 &&
          isSearchActive &&
          propertyID !== "new" && <NavListNoSearchResultComponent />}

        {propertiesToRender.map((item, i) => {
          return (
            <Column key={i} alignItems="center">
              <NavListItem
                index={i + 1}
                imageUrl={item.coverImageUrl ?? ""}
                title={item.title.de}
                subtitle={
                  propertyTypeOptions.find(
                    (option) => option.value === item.type
                  )?.label ?? ""
                }
                isActive={item._id === propertyID}
                onClick={() => {
                  if (studioStore?.studio?._id != null && item._id != null) {
                    navigate(
                      `/studios/${studioStore?.studio?._id}/properties/${item._id}/info`
                    );
                  }
                }}
              />
              <Spacer width="95%" />
            </Column>
          );
        })}
      </div>
    );
  };

  return (
    <Column>
      <PageHeadline
        classNameActions="full-width"
        className="border-right"
        actions={
          <FilledButton
            label="Hinzufügen"
            color="secondary"
            onClick={() => {
              if (studioStore?.studio?._id != null) {
                navigate(
                  `/studios/${studioStore?.studio?._id}/properties/new/info`
                );
              }
            }}
          />
        }
      >
        <OutlinedTextInput
          className="mr-20"
          placeholder="Property suchen"
          onChange={(value) => {
            if (
              value != null &&
              value?.trim().length > 0 &&
              properties?.length > 0
            ) {
              setIsSearchActive(true);
              propertyStore?.searchAndSetProperties(value);
            } else {
              setIsSearchActive(false);
            }
          }}
        />
      </PageHeadline>
      {_buildPropertyList()}
    </Column>
  );
};

export default inject(
  "propertyStore",
  "studioStore"
)(observer(PropertyNavList));
