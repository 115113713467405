import React, { useEffect, useState } from "react";
import "./exercise.detail.page.scss";
import ExerciseStore from "stores/exercise.store";
import { inject, observer } from "mobx-react";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import ExerciseForm from "./components/exercise.form.component/exercise.form.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import MainLayout from "layouts/main.layout/main.layout";
import PropertyStore from "stores/property.store";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import ExerciseInfoOverviewPage from "./components/exercise.info.overview.page/exercise.info.overview.page";
import LanguageDropdown from "components/input.components/dropdown.components/language.dropdown.component/language.dropdown.component";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { exerciseSchema } from "schemas/exercise.schemas/exercise.schema";
import FixedControllersToolbar from "components/navigation.components/fixed.controller.toolbar.component/fixed.controller.toolbar.component";
import StudioStore from "stores/studio.store";
import { usePersistentId } from "globals/helpers/form.key.helper";
import { useTranslation } from "react-i18next";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { createTranslate } from "globals/helpers/global.helper";
import { ModalStore } from "stores/modal.store";
import { navigationHelper } from "globals/helpers/navigation.helper";
import ExerciseInfoDetailPage from "./components/exercise.info.detail.components/exercise.info.detail.page";
import { isEqual } from "lodash";

interface ExerciseDetailPageProps {
  exerciseStore?: ExerciseStore;
  propertyStore?: PropertyStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const ExerciseDetailPage = ({
  exerciseStore,
  propertyStore,
  studioStore,
  modalStore,
}: ExerciseDetailPageProps): JSX.Element => {
  const { exerciseID } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const translate = createTranslate(t, "exerciseDetailPage.tabs");
  const navigate = navigationHelper();

  const isInfoTabActive = location.pathname.includes("info");
  const ifExerciseInfoTabActive = location.pathname.includes("topics/");

  const isEditing = exerciseID !== "new";
  const persistentId = usePersistentId(exerciseID);
  const [isUploading, setIsUploading] = useState(false);

  const currentExercise = exerciseStore?.currentExercise?.data;
  const currentLanguage = studioStore?.currentLanguage;
  const currentLanguages = studioStore?.studio?.localizations ?? [];

  useEffect(() => {
    initializePage();
  }, [exerciseID]);

  const methods = useForm({
    resolver: yupResolver(exerciseSchema(currentLanguages)),
    mode: "onChange",
    reValidateMode: "onChange",
    values: currentExercise,
  });

  const {
    formState: { isDirty },
    watch,
  } = methods;

  const initializePage = async (): Promise<void> => {
    if (exerciseID == null) {
      return;
    }

    propertyStore?.fetchAndSetProperties({});

    if (exerciseID !== "new") {
      await exerciseStore?.fetchAndSetExercise({ exerciseID });
    }
  };

  if (!isEditing && !isEqual(exerciseStore?.currentExercise?.data, {})) {
    exerciseStore?.createInitialExercise();
  }

  const handleArchiveExercise = async (): Promise<void> => {
    if (currentExercise == null) return;

    modalStore?.showDeleteAlert({
      t,
      onConfirm: async () => {
        await exerciseStore?.archiveExercise(currentExercise);

        navigate("inventory/exercises");
      },
    });
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        actions={[
          <SizedContainer
            className="mr-10"
            size={ContainerSizes.M}
            key="info,topics*"
          >
            <LanguageDropdown />
          </SizedContainer>,
        ]}
        tabs={[
          <Tab
            label={translate("info")}
            key="exercise-info-tab"
            path="info"
            rootPath={exerciseID}
            dirty={isDirty}
          />,
          <Tab
            label={translate("description")}
            key="exercise-infos-tab"
            path="topics"
            rootPath={exerciseID}
            dirty={isDirty}
          />,
        ]}
      >
        <Outlet />
      </LinkTabs>
    );
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline
        showBackButton
        rootPath={ifExerciseInfoTabActive ? "topics" : "exercises"}
      >
        {_buildTabs()}
      </PageHeadline>
    );
  };

  if (exerciseStore?.currentExercise?.isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer key={persistentId}>
        <WidthLimiterWrapper maxSize="form">
          <Routes>
            <Route element={<Outlet />}>
              <Route
                path="info"
                element={
                  <FormProvider {...methods}>
                    <ExerciseForm
                      isUploading={(isUploading) => setIsUploading(isUploading)}
                      isEditing={isEditing}
                    />
                  </FormProvider>
                }
              />
              <Route path="topics" element={<ExerciseInfoOverviewPage />}>
                <Route path=":topicID" element={<ExerciseInfoDetailPage />} />
              </Route>
            </Route>
          </Routes>

          {/* SHOW FIXED CONTROLLERS TOOLBAR FOR INFO TAB */}
          {isInfoTabActive && (
            <FixedControllersToolbar
              formID="exercise-info-form"
              disabled={!isDirty}
              isLoading={isUploading}
              hasUnsavedChanges={isDirty}
              keyString="exercise-info-form-btn"
              title={watch(`title.${currentLanguage?.value}`)}
              imageUrl={watch("coverImageUrl")}
              handleDelete={handleArchiveExercise}
            />
          )}
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "exerciseStore",
  "propertyStore",
  "studioStore",
  "modalStore"
)(observer(ExerciseDetailPage));
