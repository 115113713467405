import React from "react";
import classNames from "classnames";
import { isImage } from "globals/helpers/file.type.helper";
import { Upload } from "schemas/upload.schema";

import { SmallText } from "components/text.components/small.text.component/small.text.component";
import Image from "components/image.component/image.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";

interface PageContainerProps {
  isDefaultFileUploader?: boolean;
  label?: string;
  className?: string;
  inputRef: any;
  selectedFileUrl?: string;
  fileUrl: string;
  folder: string;
  errors?: any;
  onFilesUploaded?: (files?: Upload[]) => void;
  onLibraryChoosed?: (data: any) => void;
  onAssetContainerClick?: () => void;
}

const UploadContainer = ({
  isDefaultFileUploader = true,
  label,
  className,
  inputRef,
  selectedFileUrl,
  fileUrl,
  folder,
  errors,
  onFilesUploaded,
  onLibraryChoosed,
  onAssetContainerClick,
}: PageContainerProps): JSX.Element => {
  const uploadContainerClassName = classNames({}, className);

  return isDefaultFileUploader ? (
    <FileUpload
      label={label}
      className="mt-15"
      folder={folder}
      inputRef={inputRef}
      fileUrl={fileUrl}
      validationMessage={errors.coverImageUrl?.message?.toString()}
      onLibraryChoosed={onLibraryChoosed}
      onFilesUploaded={onFilesUploaded}
    />
  ) : (
    <>
      <SmallText className="mb-10">{"Open upload modal"}</SmallText>
      <div className={uploadContainerClassName} onClick={onAssetContainerClick}>
        {selectedFileUrl != null && isImage(selectedFileUrl) && (
          <Image className="uploaded-file-image" imageUrl={selectedFileUrl} />
        )}
        <OutlinedTextInput className="mt-15 hidden-input" inputRef={inputRef} />
      </div>
    </>
  );
};

export default UploadContainer;
