import React from "react";
import classNames from "classnames";
import "./width.limiter.wrapper.component.scss";

type MaxSize = "default" | "large" | "form";

interface WidthLimiterWrapperProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  infinity?: boolean;
  maxSize?: MaxSize;
}

const WidthLimiterWrapper = ({
  children,
  className,
  infinity = false,
  maxSize = "default",
}: WidthLimiterWrapperProps): JSX.Element => {
  const wrapperClassName = classNames(
    "max-width-wrapper",
    `max-width-wrapper--${maxSize}`,
    {
      "max-width-wrapper--infinity": infinity,
    },
    className
  );

  return <div className={wrapperClassName}>{children}</div>;
};

export default WidthLimiterWrapper;
