import { Logging } from "globals/helpers/logging.helper";
import {
  ContentLibrary,
  InstalledContentLibrary,
} from "../../schemas/content.library.schemas/content.library.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpContentLibraryClient extends GenericHttpClient<ContentLibrary> {
  static _instance: HttpContentLibraryClient;
  static getInstance(): HttpContentLibraryClient {
    if (this._instance == null) {
      this._instance = new HttpContentLibraryClient("/content-libraries");
    }
    return this._instance;
  }

  async getContentForLibrary<T>(args: {
    contentLibraryID: string;
    query?: any;
  }): Promise<T[] | undefined> {
    try {
      const { contentLibraryID, query } = args;

      const response = await this.get(
        `/content-libraries/${contentLibraryID}/content`,
        {
          params: query,
        }
      );
      return response.data;
    } catch (err) {
      Logging.error({
        className: "HttpContentLibraryClient",
        methodName: "getContentForLibrary",
        message: "Could not get content for library",
        exception: err,
        showAlert: true,
      });
    }
  }

  async installContentForLibrary(args: {
    contentLibraryID: string;
  }): Promise<void> {
    try {
      const { contentLibraryID } = args;

      await this.post(`/content-libraries/${contentLibraryID}/install`);
    } catch (err) {
      Logging.error({
        className: "HttpContentLibraryClient",
        methodName: "installContentForLibrary",
        message: "Could not install content for library",
        exception: err,
        showAlert: true,
      });
    }
  }

  async getInstalledContentLibraries(): Promise<
    InstalledContentLibrary[] | undefined
  > {
    try {
      const response = await this.get(`/content-libraries/installed`);
      return response.data;
    } catch (err) {
      Logging.error({
        className: "HttpContentLibraryClient",
        methodName: "getInstalledContentLibraries",
        message: "Could not get installed content libraries",
        exception: err,
        showAlert: true,
      });
    }
  }
}
