import classNames from "classnames";
import React from "react";

import "./component.wrapper.component.scss";
import Column from "../column.component/column.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";

interface ComponentWrapperProps {
  title?: string | JSX.Element;
  children: JSX.Element | JSX.Element[] | any;
  actions?: JSX.Element | JSX.Element[];
  outsideActions?: JSX.Element | JSX.Element[];
  noPadding?: boolean;
  headlinePadding?: boolean;
  noBorderRadius?: boolean;
  className?: string;
  headerClassName?: string;
  disabled?: boolean;
  autoWidth?: boolean;
  info?: { title: string; content: string };
  key?: React.Key | null | undefined;
}

const ComponentWrapper = ({
  title,
  children,
  actions,
  outsideActions,
  noPadding = false,
  headlinePadding = false,
  noBorderRadius = false,
  className,
  headerClassName,
  disabled = false,
  autoWidth = false,
  key,
}: ComponentWrapperProps): JSX.Element => {
  const outsideWrapperClassName = classNames(
    {
      "component-container-wrapper": true,
    },
    className
  );

  const componentContainerClassName = classNames(
    {
      "component-container": true,
      "component-container--auto-width": autoWidth,
      "component-container--no-padding": noPadding,
      "component-container--headline-padding": headlinePadding,
      "component-container--no-border-radius": noBorderRadius,
    },
    className
  );

  const componentWrapperClassName = classNames(
    {
      "component-wrapper-container": true,
      "component-wrapper-container--no-padding": noPadding,
    },
    className
  );

  const componentWrapperContentClassName = classNames({
    "component-wrapper-container-content": true,
    "component-wrapper-container-content--disabled": disabled,
  });

  const componentHeaderClassName = classNames(
    {
      "component-wrapper-container-header": true,
    },
    headerClassName
  );

  return (
    <Column key={key} className={outsideWrapperClassName}>
      {outsideActions != null && outsideActions}
      <Column className={componentContainerClassName} key={key}>
        {(title != null || actions != null) && (
          <div className={componentHeaderClassName}>
            <RunningText className="bold">{title}</RunningText>
            {actions}
          </div>
        )}
        <div className={componentWrapperClassName}>
          <div className={componentWrapperContentClassName}>{children}</div>
        </div>
      </Column>
    </Column>
  );
};

export default ComponentWrapper;
