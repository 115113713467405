import React from "react";
import {
  SmallText,
  SmallTextSkeleton,
} from "components/text.components/small.text.component/small.text.component";
import { LargeTextSkeleton } from "components/text.components/large.text.component/large.text.component";
import Image from "components/image.component/image.component";
import { ImageSizes } from "globals/enums/global.enum";
import "./nav.list.item.component.scss";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import TitleText from "components/text.components/title.text.component/title.text.component";
import Column from "components/general.compoenents/column.component/column.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import LinearProgressBar from "components/general.compoenents/linear.progress.bar.component/linear.progress.bar.component";
import Row from "components/general.compoenents/row.component/row.component";

interface NavListItemProps {
  index?: number;
  imageUrl?: string;
  title: string;
  subtitle?: string;
  progressBarInfo?: string;
  progressBarValue?: number;
  rightLabel?: JSX.Element;
  isActive: boolean;
  newItem?: boolean;
  onClick: () => void;
}

const NavListItem = ({
  index,
  imageUrl,
  title,
  subtitle = "",
  progressBarInfo,
  progressBarValue,
  rightLabel,
  isActive,
  newItem = false,
  onClick,
}: NavListItemProps): JSX.Element => {
  const navListItemClassName = classNames({
    "nav-list-item-wrapper": true,
    "nav-list-item-wrapper--active": isActive,
  });

  return (
    <div className={navListItemClassName} onClick={onClick}>
      <div className="nav-list-item-image-container">
        {imageUrl != null && !newItem ? (
          <Image size={ImageSizes.M} imageUrl={imageUrl}></Image>
        ) : (
          <Image size={ImageSizes.M} empty={true}></Image>
        )}
      </div>
      <div className="nav-list-item-text-container">
        <TitleText
          color="text-secondary"
          className={isActive ? "text-color-inverted" : ""}
        >
          {title}
        </TitleText>
        <SmallText
          className={
            isActive
              ? "nav-list-sub-title text-color-inverted"
              : "nav-list-sub-title"
          }
        >
          {subtitle}
        </SmallText>
        {progressBarValue != null && progressBarValue > 0 && (
          <Row alignItems="center" className="mt-5">
            <LinearProgressBar
              value={progressBarValue}
              color="secondary"
              width={70}
            />
            {progressBarInfo != null && (
              <SmallText className="ml-10">{progressBarInfo}</SmallText>
            )}
          </Row>
        )}
      </div>
      <div className="nav-list-item-action-container">
        {rightLabel != null && rightLabel}
      </div>
    </div>
  );
};

export default NavListItem;

export const NavListItemSkeleton = ({
  count = 1,
}: {
  count?: number;
}): JSX.Element => {
  return (
    <>
      {Array(count)
        .fill(count)
        .map((_, i) => {
          return (
            <Column key={i} alignItems="center">
              <div className="nav-list-item-wrapper">
                <div className="nav-list-item-image-container">
                  <Skeleton count={1} width={60} height={60} />
                </div>
                <div className="nav-list-item-text-container">
                  <LargeTextSkeleton className="nav-item-title" />
                  <SmallTextSkeleton className="" />
                </div>
              </div>
              <Spacer width="95%" />
            </Column>
          );
        })}
    </>
  );
};
