import { GenericHttpClient } from "./config/http.generic.client";
import { DataAttribute } from "schemas/data.attribute.schemas/data.attribute.schema";

export class HttpDataAttributeService extends GenericHttpClient<DataAttribute> {
  static _instance: HttpDataAttributeService;
  static getInstance(): HttpDataAttributeService {
    if (this._instance == null) {
      this._instance = new HttpDataAttributeService("/data-attributes");
    }
    return this._instance;
  }

  async checkIfFieldIdExists(args: {
    fieldID: string;
    collectionID: string;
  }): Promise<boolean> {
    const response = await this.get(`/data-attributes/check-field-exists`, {
      params: {
        fieldID: args.fieldID,
        collectionID: args.collectionID,
      },
    });
    return response.data;
  }
}
