import React, { useEffect, useState } from "react";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router-dom";
import FeedStore from "stores/feed.store";
import FeedConfigForm from "./components/feed.config.form.component";
import StudioStore from "stores/studio.store";
import { generatePlaceholderAvatarUrl } from "globals/helpers/global.helper";
import FixedControllersToolbar from "components/navigation.components/fixed.controller.toolbar.component/fixed.controller.toolbar.component";
import Column from "components/general.compoenents/column.component/column.component";
import { ModalStore } from "stores/modal.store";
import { useTranslation } from "react-i18next";
import { navigationHelper } from "globals/helpers/navigation.helper";
import { isEqual } from "lodash";

interface FeedConfigDetailPageProps {
  feedStore?: FeedStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const FeedConfigDetailPage = ({
  feedStore,
  studioStore,
  modalStore,
}: FeedConfigDetailPageProps): JSX.Element => {
  const { t } = useTranslation();

  const { feedConfigID } = useParams();

  const isEditing = feedConfigID !== "new";
  const [formIsDirty, setFormIsDirty] = useState(false);
  const navigate = navigationHelper();

  const currentFeedConfig = feedStore?.currentFeedConfig;

  useEffect(() => {
    initializePage();
  }, [feedConfigID]);

  const initializePage = async (): Promise<void> => {
    if (feedConfigID == null) {
      return;
    }

    if (feedConfigID !== "new") {
      await feedStore?.fetchAndSetFeedConfigItem({ feedConfigID });
    }
  };

  if (!isEditing && !isEqual(feedStore?.currentFeedConfig?.data, {})) {
    feedStore?.createInitialFeedConfigItem();
  }

  const handleArchiveFeedConfig = async (): Promise<void> => {
    if (currentFeedConfig?.data == null) {
      return;
    }

    modalStore?.showDeleteAlert({
      t,
      onConfirm: async () => {
        if (currentFeedConfig?.data?._id != null) {
          await feedStore?.archiveFeedConfig(currentFeedConfig?.data);
          navigate("feed");
        }
      },
    });
  };

  const onSubmit = async (data: any): Promise<void> => {
    if (currentFeedConfig?.data == null) return;

    feedStore?.setCurrentFeedConfig({
      ...currentFeedConfig?.data,
      ...data,
    });

    if (!isEditing) {
      // create new feed config item
      await feedStore?.createFeedConfig({
        feedConfig: data,
      });
    } else {
      // update feed config item
      await feedStore?.updateFeedConfig({
        id: currentFeedConfig?.data?._id as string,
        feedConfig: data,
      });
    }

    setFormIsDirty(false);
  };

  if (feedStore?.currentFeedConfig?.isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <Column alignItems="center">
      <FeedConfigForm
        key={feedConfigID}
        onFormSubmit={onSubmit}
        onDirty={(isDirty) => {
          setFormIsDirty(isDirty);
        }}
      />

      <FixedControllersToolbar
        formID="feed-config-form"
        fromIsDirty={formIsDirty}
        disabled={false}
        isLoading={feedStore?.currentFeedConfig?.isLoading}
        hasUnsavedChanges={true} // TODO
        keyString="feed-config-form-btn"
        title={currentFeedConfig?.data?.title}
        handleDelete={handleArchiveFeedConfig}
        imageUrl={generatePlaceholderAvatarUrl(
          currentFeedConfig?.data?.title ?? ""
        )}
      />
    </Column>
  );
};

export default inject(
  "feedStore",
  "studioStore",
  "modalStore"
)(observer(FeedConfigDetailPage));
