import { makeAutoObservable } from "mobx";
import {
  DataItem,
  PaginationDataList,
  getSkipAndLimitFromPage,
} from "globals/intefaces/pageination.data.list.interface";
import { Logging } from "globals/helpers/logging.helper";
import { HttpGymoCustomerService } from "services/httpClients/http.gymo.customer.client";
import { Studio } from "schemas/studio.schemas/studio.schema";
import { toast } from "react-toastify";
import { User } from "schemas/user.schemas/user.schema";
import RootStore from "./root.store";

class GymoCustomerStore {
  private stores: RootStore;

  // Studio Properties
  private _gymoCustomerDataList: PaginationDataList<Studio> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentGymoCustomerAppUsers: PaginationDataList<User> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentGymoCustomerData: DataItem<Studio> = {
    data: undefined,
    isLoading: false,
  };

  private _gymoCustomerSearchResult: Studio[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setter
  setGymoCustomers = (gymoCustomers: Studio[]): any => {
    this._gymoCustomerDataList.data = gymoCustomers;
  };

  setGymoCustomerSearchResult = (gymoCustomers: Studio[]): any => {
    this._gymoCustomerSearchResult = gymoCustomers;
  };

  setCurrentGymoCustomer = (gymoCustomer: Studio): any => {
    this._currentGymoCustomerData.data = gymoCustomer;
  };

  setCurrentGymoCustomerAppUsers = (appUsers: User[]): any => {
    this._currentGymoCustomerAppUsers.data = appUsers;
  };

  //! Getters
  get gymoCustomers(): PaginationDataList<Studio> | undefined {
    return JSON.parse(JSON.stringify(this._gymoCustomerDataList));
  }

  get gymoCustomerSearchResult(): Studio[] | undefined {
    if (this._gymoCustomerSearchResult == null) {
      return;
    }

    return JSON.parse(JSON.stringify(this._gymoCustomerSearchResult));
  }

  get currentGymoCustomer(): DataItem<Studio> | undefined {
    if (this._currentGymoCustomerData == null) {
      return;
    }

    return JSON.parse(JSON.stringify(this._currentGymoCustomerData));
  }

  get currentGymoCustomerAppUsers(): PaginationDataList<User> | undefined {
    if (this._currentGymoCustomerAppUsers == null) {
      return;
    }

    return JSON.parse(JSON.stringify(this._currentGymoCustomerAppUsers));
  }

  //! Methods

  fetchAndSetGymoCustomers = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this.gymoCustomers != null &&
        this._gymoCustomerDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._gymoCustomerDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._gymoCustomerDataList.pageIndex++;
      } else {
        this._gymoCustomerDataList.pageIndex = 0;
        this._gymoCustomerDataList.data = [];
      }

      this._gymoCustomerDataList.isLoading = true;

      const gymoCustomers = await HttpGymoCustomerService.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._gymoCustomerDataList.pageIndex,
            itemsInPage: this._gymoCustomerDataList.itemsInPage,
          }),
        },
      });

      if (gymoCustomers == null) {
        this._gymoCustomerDataList.isLoading = false;
        return;
      }

      this.setGymoCustomers(gymoCustomers);
      this._gymoCustomerDataList.isLoading = false;
    } catch (err) {
      this._gymoCustomerDataList.isLoading = false;

      Logging.error({
        className: "GymoCustomerStore",
        methodName: "fetchAndSetGymoCustomers",
        message: "Gymo Kunden konnten nicht geladen werden.",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetCurrentGymoCustomer = async (args: {
    gymoCustomerID: string;
  }): Promise<void> => {
    try {
      if (this._currentGymoCustomerData.isLoading) {
        return;
      }

      this._currentGymoCustomerData.isLoading = true;

      const gymoCustomer = await HttpGymoCustomerService.getInstance().findOne({
        id: args.gymoCustomerID,
      });

      if (gymoCustomer == null) {
        this._currentGymoCustomerData.isLoading = false;
        return;
      }

      this.setCurrentGymoCustomer(gymoCustomer);
      this._currentGymoCustomerData.isLoading = false;
    } catch (err) {
      this._currentGymoCustomerData.isLoading = false;

      Logging.error({
        className: "GymoCustomerStore",
        methodName: "fetchAndSetCurrentGymoCustomer",
        message: "Gymo Kunden konnten nicht geladen werden.",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateGymoCustomer = async (args: {
    gymoCustomer: Studio;
  }): Promise<void> => {
    try {
      if (this._currentGymoCustomerData.isLoading) {
        return;
      }

      this._currentGymoCustomerData.isLoading = true;

      const gymoCustomer =
        await HttpGymoCustomerService.getInstance().updateOne({
          id: args.gymoCustomer?._id,
          data: args.gymoCustomer,
        });

      if (gymoCustomer == null) {
        this._currentGymoCustomerData.isLoading = false;
        return;
      }

      toast.success("Gymo Kunde wurde erfolgreich aktualisiert.");

      this.setCurrentGymoCustomer(gymoCustomer);
      this._currentGymoCustomerData.isLoading = false;
    } catch (err) {
      this._currentGymoCustomerData.isLoading = false;

      Logging.error({
        className: "GymoCustomerStore",
        methodName: "updateGymoCustomer",
        message: "Gymo Kunden konnten nicht geladen werden.",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetCurrentGymoCustomerAppUsers = async (args: {
    gymoCustomerID: string;
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._currentGymoCustomerAppUsers != null &&
        this._currentGymoCustomerAppUsers.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._currentGymoCustomerAppUsers.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._currentGymoCustomerAppUsers.pageIndex++;
      } else {
        this._currentGymoCustomerAppUsers.pageIndex = 0;
        this._currentGymoCustomerAppUsers.data = [];
      }

      this._currentGymoCustomerAppUsers.isLoading = true;

      const appUsers =
        await HttpGymoCustomerService.getInstance().getGymoCustomerAppUsers({
          studioID: args.gymoCustomerID,
          query: {
            ...getSkipAndLimitFromPage({
              pageIndex: this._currentGymoCustomerAppUsers.pageIndex,
              itemsInPage: this._currentGymoCustomerAppUsers.itemsInPage,
            }),
          },
        });

      if (appUsers == null) {
        this._currentGymoCustomerAppUsers.isLoading = false;
        return;
      }

      this.setCurrentGymoCustomerAppUsers(appUsers);
      this._currentGymoCustomerAppUsers.isLoading = false;
    } catch (err) {
      this._currentGymoCustomerAppUsers.isLoading = false;

      Logging.error({
        className: "GymoCustomerStore",
        methodName: "fetchAndSetCurrentGymoCustomerAppUsers",
        message: "Gymo Kunden konnten nicht geladen werden.",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default GymoCustomerStore;
