import React from "react";
import {
  CollapsibleSection,
  EditorStore,
  StateInput,
  WidgetStore,
} from "@vondot-development/metool_lib";
import { inject, observer } from "mobx-react";

interface CustomerTrainingDataOptionSidebarProps {
  widgetStore?: WidgetStore;
  editorStore?: EditorStore;
}

const CustomerTrainingDataFormOptionSidebar = ({
  widgetStore,
  editorStore,
}: CustomerTrainingDataOptionSidebarProps): JSX.Element => {
  const selectedWidgetID = editorStore?.selectedWidget?.widget.widgetID;

  const data: any = widgetStore?.getWidgetOption(
    selectedWidgetID ?? "",
    "data"
  );

  return (
    <div>
      <CollapsibleSection title="Content">
        <StateInput
          label="Data"
          value={data ?? ""}
          onChange={(value: string) => {
            widgetStore?.updateWidgetOption(
              selectedWidgetID ?? "",
              "data",
              value
            );
          }}
        />
      </CollapsibleSection>
    </div>
  );
};

export default inject(
  "widgetStore",
  "editorStore"
)(observer(CustomerTrainingDataFormOptionSidebar));
