import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import Headline from "components/text.components/headline.component/headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import React from "react";
import "./modal.header.component.scss";
import classNames from "classnames";

interface ModalHeaderProps {
  title?: string | undefined;
  description?: string | undefined;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  noPadding?: boolean;
}

const ModalHeader = ({
  title,
  description,
  children,
  className,
  noPadding,
}: ModalHeaderProps): JSX.Element => {
  const modalHeaderClassName = classNames({
    "modal-header": true,
    "no-padding": noPadding,
  });

  return (
    <Column className={modalHeaderClassName}>
      {title != null && <Headline className="mb-10">{title}</Headline>}
      {description != null && (
        <RunningText className="mb-10">{description}</RunningText>
      )}
      <Row className={`mt-15 ${className}`}>{children}</Row>
    </Column>
  );
};

export default ModalHeader;
