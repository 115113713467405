import React from "react";
import Column from "components/general.compoenents/column.component/column.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Row from "components/general.compoenents/row.component/row.component";
import { inject, observer } from "mobx-react";
// import { useNavigate } from "react-router-dom";
import CustomerStore from "stores/customer.store";
import { ModalStore } from "stores/modal.store";
import StudioStore from "stores/studio.store";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import {
  DemoBooking,
  demoBookings,
} from "components/metool.widgets/booking.data.table.widget/demo.data/demo.data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import {
  DemoOrder,
  demoOrders,
} from "components/metool.widgets/order.data.table.widget/demo.data/demo.data";

interface CustomerBookingTabProps {
  customerStore?: CustomerStore;
  modalStore?: ModalStore;
  studioStore?: StudioStore;
}

//! TODO HARDCODED FOR THE FIBO DEMO
const CustomerBookingTab = ({
  customerStore,
  modalStore,
  studioStore,
}: CustomerBookingTabProps): JSX.Element => {
  // const navigate = useNavigate();

  const buildOrderDataList = (): JSX.Element => {
    return (
      <ListDataTable
        data={demoOrders}
        columns={[
          {
            child: <RunningText className="bold-text">Order</RunningText>,
            flex: 1,
          },
          {
            child: <RunningText className="bold-text">Datum</RunningText>,
          },

          {
            child: <RunningText className="bold-text">Total</RunningText>,
          },

          {
            child: <RunningText className="bold-text">Zahlung</RunningText>,
          },
          {
            child: <RunningText className="bold-text">Status</RunningText>,
          },
        ]}
        dataTableItemBuilder={(dataItem: DemoOrder, index) => {
          return {
            key: `${dataItem.number}-${index}`,
            children: [
              {
                child: (
                  <RunningText className="bold-text">
                    {dataItem?.number ?? "-"}
                  </RunningText>
                ),
              },
              {
                child: <RunningText>{dataItem?.date ?? "-"}</RunningText>,
              },
              {
                child: <RunningText>{dataItem?.total ?? "-"}</RunningText>,
              },
              {
                child: <RunningText>{dataItem?.payment ?? ""}</RunningText>,
              },
              {
                child: (
                  <Row>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="status-icon"
                    />
                    <RunningText>Abgeschlossen</RunningText>
                  </Row>
                ),
              },
            ],
          };
        }}
        itemClassName="device-brand-table-item"
      />
    );
  };

  const buildBookingDataList = (): JSX.Element => {
    return (
      <ListDataTable
        data={demoBookings}
        columns={[
          {
            child: <RunningText className="bold-text">Buchung</RunningText>,
            flex: 1,
          },
          {
            child: (
              <RunningText className="bold-text">Buchungs Option</RunningText>
            ),
          },
          {
            child: <RunningText className="bold-text">Resource</RunningText>,
          },
          {
            child: <RunningText className="bold-text">Zeitraum</RunningText>,
          },
          {
            child: <RunningText className="bold-text">Total</RunningText>,
          },
          {
            child: <RunningText className="bold-text">Status</RunningText>,
          },
        ]}
        dataTableItemBuilder={(dataItem: DemoBooking, index) => {
          return {
            key: `${dataItem.number}-${index}`,
            children: [
              {
                child: (
                  <RunningText className="bold-text">
                    {dataItem?.number ?? "-"}
                  </RunningText>
                ),
              },
              {
                child: <RunningText>{dataItem?.title ?? "-"}</RunningText>,
              },
              {
                child: <RunningText>{dataItem?.resource ?? "-"}</RunningText>,
              },
              {
                child: <RunningText>{dataItem?.from ?? ""}</RunningText>,
              },
              {
                child: <RunningText>{dataItem?.total ?? "-"}</RunningText>,
              },
              {
                child: (
                  <Row>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="status-icon"
                    />
                    <RunningText>Abgeschlossen</RunningText>
                  </Row>
                ),
              },
            ],
          };
        }}
        itemClassName="device-brand-table-item"
      />
    );
  };

  return (
    <Column>
      <ComponentWrapper
        title={`Buchungen von ${customerStore?.currentCustomerFullName}`}
        className="mb-15"
      >
        {buildBookingDataList()}
      </ComponentWrapper>

      <ComponentWrapper
        title={`Orders von ${customerStore?.currentCustomerFullName}`}
        className="mb-15"
      >
        {buildOrderDataList()}
      </ComponentWrapper>
    </Column>
  );
};

export default inject(
  "customerStore",
  "modalStore",
  "studioStore"
)(observer(CustomerBookingTab));
