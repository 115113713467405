import React from "react";
import Row from "components/general.compoenents/row.component/row.component";
import Image from "components/image.component/image.component";
import ListDataTable, {
  DataTableRowCell,
} from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { ImageSizes } from "globals/enums/global.enum";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import { SearchTopic } from "stores/global.search.store";
import { Feed } from "schemas/feed.schemas/feed.schema";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import { ModalStore } from "stores/modal.store";
import { inject, observer } from "mobx-react";
import Highlighter from "react-highlight-words";
import StudioStore from "stores/studio.store";
import { navigationHelper } from "globals/helpers/navigation.helper";
import { Customer } from "schemas/customer.schemas/customer.schema";

interface GlobalSearchResultListProps {
  topic: SearchTopic;
  data: any[];
  isLoading?: boolean;
  limit?: number;
  showHeader?: boolean;
  searchTerm: string;
  modalStore?: ModalStore;
  studioStore?: StudioStore;
}

const GlobalSearchResultList = ({
  topic,
  data = [],
  isLoading = false,
  limit = 5,
  showHeader = false,
  searchTerm,
  modalStore,
  studioStore,
}: GlobalSearchResultListProps): JSX.Element => {
  const currentLanguage = studioStore?.currentLanguage?.value;
  const navigate = navigationHelper();

  // handle navigation based on search topic
  const handleNavigateOnClick = (searchTopic: SearchTopic, item: any): void => {
    const typedItem = item as Exercise | TrainingPlan | Feed;

    switch (searchTopic) {
      case SearchTopic.EXERCISE:
        navigate(`inventory/exercises/${typedItem?._id}/info`);
        break;
      case SearchTopic.TRAINING_PLAN:
        navigate(`training-plans/${typedItem?._id}`);
        break;
      case SearchTopic.FEED:
        navigate(
          `feed/${(typedItem as Feed)?.feedConfig?._id}/content/${
            typedItem?._id
          }`
        );
        break;
      case SearchTopic.CUSTOMER:
        navigate(`management/members/${typedItem?._id}/info`);
        break;
    }

    // close the search modal
    modalStore?.closeModal();
  };

  // configure data table cell for each topic

  const getExerciseConfig = (exercise: Exercise): DataTableRowCell[] => {
    return [
      {
        child: (
          <Row alignItems="center">
            <Image imageUrl={exercise?.coverImageUrl} size={ImageSizes.S} />
            <RunningText className="ml-20">
              <Highlighter
                caseSensitive={false}
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={exercise?.title?.[currentLanguage!]}
              />
            </RunningText>
          </Row>
        ),
      },
      {
        child: (
          <HighlightedInfoText color="success">
            {exercise.type}
          </HighlightedInfoText>
        ),
      },
    ];
  };

  const getTrainingPlanConfig = (
    trainingPlan: TrainingPlan
  ): DataTableRowCell[] => {
    return [
      {
        child: (
          <Row alignItems="center">
            <Image imageUrl={trainingPlan?.coverImageUrl} size={ImageSizes.S} />
            <RunningText className="ml-20">
              <Highlighter
                caseSensitive={false}
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={trainingPlan?.title}
              />
            </RunningText>
          </Row>
        ),
      },
      {
        child: (
          <HighlightedInfoText
            color={trainingPlan?.isPublished ? "success" : "warning"}
          >
            {trainingPlan?.isPublished ? "Published" : "Draft"}
          </HighlightedInfoText>
        ),
      },
    ];
  };

  const getFeedConfig = (feed: Feed): DataTableRowCell[] => {
    return [
      {
        child: (
          <Row alignItems="center">
            <Image imageUrl={feed?.coverImageUrl} size={ImageSizes.S} />
            <RunningText className="ml-20">
              <Highlighter
                caseSensitive={false}
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={feed?.title}
              />
            </RunningText>
          </Row>
        ),
      },
      {
        child: (
          <HighlightedInfoText
            color={feed?.isPublished ? "success" : "warning"}
          >
            {feed?.isPublished ? "Published" : "Draft"}
          </HighlightedInfoText>
        ),
      },
    ];
  };

  const getCustomerConfig = (customer: Customer): DataTableRowCell[] => {
    return [
      {
        child: (
          <Row alignItems="center">
            <Image
              imageUrl={customer?.profileImageUrl as any}
              size={ImageSizes.S}
            />
            <RunningText className="ml-20">
              <Highlighter
                caseSensitive={false}
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={customer.firstName}
              />
            </RunningText>
            <RunningText className="ml-5">
              <Highlighter
                caseSensitive={false}
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={customer?.lastName as any}
              />
            </RunningText>
          </Row>
        ),
      },
      {
        child: <></>,
      },
    ];
  };

  if (limit != null && data?.length > limit) {
    data = data?.slice(0, limit);
  }

  return (
    <ListDataTable
      onClick={(item) => handleNavigateOnClick(topic, item)}
      disableHeader={!showHeader}
      gap={20}
      data={data}
      isLoading={isLoading}
      columns={[
        {
          child: <TitleText>{topic}</TitleText>,
          flex: 5,
        },
        {
          child: <></>,
          flex: 1,
        },
      ]}
      dataTableItemBuilder={(dataItem: any) => {
        switch (topic) {
          case "exercise":
            return {
              key: dataItem._id,
              children: getExerciseConfig(dataItem),
            };
          case "trainingPlan":
            return {
              key: dataItem._id,
              children: getTrainingPlanConfig(dataItem),
            };
          case "feed":
            return {
              key: dataItem._id,
              children: getFeedConfig(dataItem),
            };
          case "customer":
            return {
              key: dataItem._id,
              children: getCustomerConfig(dataItem),
            };
          default:
            return {
              key: dataItem?._id,
              children: [
                {
                  child: <></>,
                },
                {
                  child: <></>,
                },
              ],
            };
        }
      }}
    />
  );
};

export default inject(
  "modalStore",
  "studioStore"
)(observer(GlobalSearchResultList));
