import React, { useState } from "react";
import "./marketing.push.notification.page.scss";
import SplitLayout from "layouts/split.layout/split.layout";
import Center from "components/general.compoenents/center.component/center.component";
import Column from "components/general.compoenents/column.component/column.component";
import publishPreviewImage from "assets/illustrations/publishing_preview.png";
import Row from "components/general.compoenents/row.component/row.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import GymoLogo from "components/general.compoenents/gymo.logo.component/gymo.logo.component";
import MainLayout from "layouts/main.layout/main.layout";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import OutlinedTextArea from "components/input.components/outlined.text.area.component/outlined.text.area.component";
import FixedControllersToolbar from "components/navigation.components/fixed.controller.toolbar.component/fixed.controller.toolbar.component";

const MarketingPushNotification = (): JSX.Element => {
  const [title, setTitle] = useState("Neue Produkte im Powerhouse!");
  const [message, setMessage] = useState(
    "Entdecken Sie die neuesten Produkte im Powerhouse. Jetzt entdecken!"
  );

  const _buildPushNotificationConfiguration = (): JSX.Element => {
    return (
      <ComponentWrapper title="Configuration" className="mr-30">
        <OutlinedTextInput
          className="mb-30"
          label="Title"
          value={title}
          onChange={(text: any) => {
            if (text) {
              setTitle(text);
            }
          }}
        />
        <OutlinedTextArea
          label="Message"
          value={message}
          onChange={(text: any) => {
            if (text) {
              setMessage(text);
            }
          }}
        />
      </ComponentWrapper>
    );
  };

  const _buildPreview = (): JSX.Element => {
    return (
      <div className="push-notification-preview-container">
        <img className="phone-preview-image" src={publishPreviewImage} />
        <Column className="push-notification-container">
          <Row alignItems="center" className="mb-5">
            <Row alignItems="center">
              <img />
              <GymoLogo className="mr-5" size={13} />
              <div className="app-name">Gymo App</div>
            </Row>
            <div className="app-name">now</div>
          </Row>
          <TitleText>{title}</TitleText>
          <RunningText>{message}</RunningText>
        </Column>
      </div>
    );
  };

  return (
    <MainLayout
      topBars={[
        <PageHeadline
          showBackButton
          key="push-notifications"
          title="Push notifications"
        />,
      ]}
    >
      <PageContainer>
        <SplitLayout
          leftGrow={2}
          leftChild={<Column>{_buildPushNotificationConfiguration()}</Column>}
          rightChild={
            <Center className="hide-on-mobile">{_buildPreview()}</Center>
          }
        />
        <FixedControllersToolbar
          disabled={false}
          isLoading={false}
          hasUnsavedChanges={true}
          keyString="booking-options-save-btn"
          title={title}
        />
      </PageContainer>
    </MainLayout>
  );
};

export default MarketingPushNotification;
