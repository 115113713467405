import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { ModalSize, ModalStore, ModalType } from "stores/modal.store";
import { useTranslation } from "react-i18next";
import DataAttributeCollectionStore from "stores/data.attribute.collection.store";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import DataAttributeStore from "stores/data.attribute.store";
import DataAttributeList from "./components/data.attribute.list.component/data.attribute.list.component";
import { DataAttribute } from "schemas/data.attribute.schemas/data.attribute.schema";
import { DataAttributeCollection } from "schemas/data.attribute.collection.schemas/data.attribute.collection.schema";
import { createTranslate } from "globals/helpers/global.helper";
import Row from "components/general.compoenents/row.component/row.component";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";
import Headline from "components/text.components/headline.component/headline.component";

interface DataAttributeConfigurationTabPageProps {
  modalStore?: ModalStore;
  dataAttributeCollectionStore?: DataAttributeCollectionStore;
  dataAttributeStore?: DataAttributeStore;
}

const DataAttributeConfigurationTabPage = ({
  modalStore,
  dataAttributeCollectionStore: collectionStore,
  dataAttributeStore,
}: DataAttributeConfigurationTabPageProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "accountPage.dataAttributeTab");

  const collections = collectionStore?.dataAttributeCollections;
  const currentCollection = collectionStore?.currentDataAttributeCollection;
  const collectionAttributes = dataAttributeStore?.getCollectionAttributes(
    currentCollection?.data?._id as string
  );

  useEffect(() => {
    initializePage();
  }, []);

  useEffect(() => {
    // fetch data attributes for current collection
    if (currentCollection?.data?._id != null) {
      dataAttributeStore?.fetchAndSetAttributesForCollection({
        collectionID: currentCollection.data?._id,
      });
    }
  }, [collectionStore?.currentDataAttributeCollection?.data]);

  const initializePage = async (): Promise<void> => {
    await collectionStore?.fetchAndSetDataAttributeCollections({
      refresh: true,
    });

    // set initial selected collection
    if (collections?.data != null && collections.data.length > 0) {
      collectionStore?.setCurrentDataAttributeCollection(collections.data[0]);
    }
  };

  const selectDataAttribute = (args: {
    isEditing: boolean;
    dataAttribute?: DataAttribute | undefined;
  }): void => {
    // check if a data attribute should be edited or created
    const dataAttributeToEdit = args?.isEditing
      ? args?.dataAttribute
      : dataAttributeStore?.createInitialDataAttribute(
          currentCollection?.data as DataAttributeCollection
        );

    // set the data attribute to edit or create
    dataAttributeStore?.setCurrentDataAttribute(
      dataAttributeToEdit as DataAttribute
    );

    // open the modal for the data attribute form
    modalStore?.openModal(
      ModalType.DATA_ATTRIBUTE_FORM_MODAL,
      ModalSize.SMALL,
      {
        isEditing: args.isEditing,
      }
    );
  };

  const _buildAvailableCollectionAsTabs = (): JSX.Element => {
    return (
      <LinkTabs
        tabs={
          collections?.data.map((collection) => (
            <Tab
              label={collection.title}
              key={collection?._id}
              active={currentCollection?.data?._id === collection?._id}
              onClick={() => {
                collectionStore?.setCurrentDataAttributeCollection(collection);
              }}
            />
          )) ?? []
        }
      />
    );
  };

  const buildCurrentCollectionInfo = (): JSX.Element => {
    return (
      <ComponentWrapper
        className="mb-10"
        outsideActions={
          <SmallHeadline className="mb-20">
            {currentCollection?.data?.title}
          </SmallHeadline>
        }
      >
        visibilities and other information
      </ComponentWrapper>
    );
  };

  return (
    <>
      <ComponentWrapper
        className="mb-20"
        headlinePadding
        outsideActions={
          <Row
            className="mb-20"
            justifyContent="space-between"
            alignItems="center"
          >
            <Headline>{translate("collections.header")}</Headline>
            <FilledButton
              label={translate("collections.addButton")}
              color="secondary"
              onClick={() => {
                modalStore?.openModal(
                  ModalType.DATA_ATTRIBUTE_COLLECTION_FORM_MODAL,
                  ModalSize.SMALL,
                  {
                    isEditing: false,
                  }
                );
              }}
            />
          </Row>
        }
      >
        {_buildAvailableCollectionAsTabs()}
      </ComponentWrapper>

      {buildCurrentCollectionInfo()}

      <ComponentWrapper
        title={translate("attributes.header")}
        actions={
          <FilledButton
            label={translate("attributes.addButton")}
            color="secondary"
            onClick={() => {
              selectDataAttribute({
                isEditing: false,
              });
            }}
          />
        }
      >
        <DataAttributeList
          // key={collectionAttributes?.data.toString()}
          isLoading={collectionAttributes?.isLoading}
          dataAttributes={collectionAttributes?.data ?? []}
          onClick={(dataAttribute) => {
            selectDataAttribute({
              isEditing: true,
              dataAttribute,
            });
          }}
        />
      </ComponentWrapper>
    </>
  );
};

export default inject(
  "modalStore",
  "dataAttributeCollectionStore",
  "dataAttributeStore"
)(observer(DataAttributeConfigurationTabPage));
