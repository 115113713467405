import Column from "components/general.compoenents/column.component/column.component";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import MainLayout from "layouts/main.layout/main.layout";
import marketingIllustrations from "assets/illustrations/invitation.png";
import React from "react";
import CampainList from "./components/campain.list.component";
import { useOutlet } from "react-router";
import { toast } from "react-toastify";
import PageIntroCard from "components/card.components/page.intro.card.component/page.intro.card.component";

const MarketingPage = (): JSX.Element => {
  const outlet = useOutlet();

  if (outlet) {
    return outlet;
  }

  return (
    <MainLayout>
      <PageContainer>
        <Column>
          <PageIntroCard
            image={marketingIllustrations}
            imagePosition="bottom center"
            title="Marketing"
            description="Erstellen und verwalten Sie Marketingkampagnen, Werbeaktionen und zusätzliche Optionen."
            buttonAction={() => {
              toast.success("In FIBO-Demo nicht verfügbar");
            }}
            buttonText="Neue Kampagne erstellen"
          />
          <CampainList />
        </Column>
      </PageContainer>
    </MainLayout>
  );
};

export default MarketingPage;
