import React from "react";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { Feed } from "schemas/feed.schemas/feed.schema";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import { useTranslation } from "react-i18next";
import { createTranslate, formatDate } from "globals/helpers/global.helper";
import Image from "components/image.component/image.component";
import { ImageSizes } from "globals/enums/global.enum";
import Row from "components/general.compoenents/row.component/row.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons";
import FeedStore from "stores/feed.store";
import { inject, observer } from "mobx-react";
import { navigationHelper } from "globals/helpers/navigation.helper";

interface FeedContentListProps {
  feedItems: Feed[];
  onClick?: (feedConfig: Feed) => void;
  isLoading?: boolean;
  feedStore?: FeedStore;
}

const FeedContentList = ({
  feedItems,
  onClick,
  isLoading = false,
  feedStore,
}: FeedContentListProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "feedPage.content.overview");
  const navigate = navigationHelper();

  return (
    <ComponentWrapper noPadding>
      <ListDataTable
        gap={20}
        data={feedItems || []}
        noDataMessage={translate("noDataMessage")}
        isLoading={isLoading}
        onClick={onClick}
        listDropdownMenu={{
          items: [
            {
              label: "Edit Feed",
              icon: faEdit,
              onClick: (feedItem: Feed) => {
                const feedConfigID = feedItem?.feedConfig?._id;
                const feedID = feedItem?._id;

                if (feedConfigID == null || feedID == null) return;

                navigate(`feed/${feedConfigID}/content/${feedID}`);
              },
            },

            {
              label: "Delete Feed",
              icon: faTrash,
              onClick: (feedItem: Feed) => {
                feedStore?.archiveFeedItem(feedItem);
              },
            },
          ],
          placement: "left",
        }}
        columns={[
          {
            child: <TitleText tableHeader>{translate("title")}</TitleText>,
            flex: 2,
          },
          {
            child: <TitleText tableHeader>{translate("subTitle")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("status")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("createdAt")}</TitleText>,
            flex: 1,
          },
        ]}
        dataTableItemBuilder={(dataItem: Feed) => {
          return {
            key: dataItem._id,
            children: [
              {
                child: (
                  <Row alignItems="center">
                    <Image
                      imageUrl={dataItem.coverImageUrl}
                      size={ImageSizes.M}
                    />
                    <RunningText className="ml-20">
                      {dataItem.title}
                    </RunningText>
                  </Row>
                ),
              },
              {
                child: <RunningText>{dataItem.subTitle}</RunningText>,
              },
              {
                child: (
                  <HighlightedInfoText
                    color={dataItem?.isPublished ? "success" : "warning"}
                  >
                    {dataItem?.isPublished
                      ? translate("statusPublished")
                      : translate("statusDraft")}
                  </HighlightedInfoText>
                ),
              },
              {
                child: (
                  <RunningText>
                    {formatDate(dataItem?.system?.createdAt)}
                  </RunningText>
                ),
              },
            ],
          } as any;
        }}
      />
    </ComponentWrapper>
  );
};

export default inject("feedStore")(observer(FeedContentList));
