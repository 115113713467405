/* eslint-disable react/display-name */
import React, { forwardRef, ForwardedRef, InputHTMLAttributes } from "react";
import classNames from "classnames";
import "./filled.checkbox.component.scss";
import Row from "components/general.compoenents/row.component/row.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";

interface FilledCheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "className"> {
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  isDisabled?: boolean;
  label?: string;
}

const FilledCheckbox = forwardRef(
  (
    {
      className,
      onChange,
      checked,
      isDisabled,
      label,
      ...props
    }: FilledCheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const filledCheckboxClassName = classNames(className);

    return (
      <Row alignItems="center" className={filledCheckboxClassName}>
        <label className="filled-checkbox-wrapper">
          <input
            {...props}
            type="checkbox"
            checked={checked}
            onChange={onChange}
            disabled={isDisabled}
            ref={ref}
          />
          <span className="filled-checkmark-container" />
        </label>
        <RunningText>{label}</RunningText>
      </Row>
    );
  }
);

export default FilledCheckbox;
