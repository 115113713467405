import React from "react";
import { WidgetStore } from "@vondot-development/metool_lib";
import { inject, observer } from "mobx-react";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import "./order.data.table.widget.component.scss";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { DemoOrder, demoOrders } from "../demo.data/demo.data";
import Row from "components/general.compoenents/row.component/row.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";

interface OrderDataWidgetProps {
  widgetID: string;
  widgetStore?: WidgetStore;
}

const OrderDataWidget = ({
  widgetID,
  widgetStore,
}: OrderDataWidgetProps): JSX.Element => {
  const buildOrderDataList = (): JSX.Element => {
    return (
      <ListDataTable
        data={demoOrders}
        columns={[
          {
            child: <RunningText className="bold-text">Order</RunningText>,
            flex: 1,
          },
          {
            child: <RunningText className="bold-text">Datum</RunningText>,
          },

          {
            child: <RunningText className="bold-text">Total</RunningText>,
          },

          {
            child: <RunningText className="bold-text">Zahlung</RunningText>,
          },
          {
            child: <RunningText className="bold-text">Status</RunningText>,
          },
        ]}
        dataTableItemBuilder={(dataItem: DemoOrder, index) => {
          return {
            key: `${dataItem.number}-${index}`,
            children: [
              {
                child: (
                  <RunningText className="bold-text">
                    {dataItem?.number ?? "-"}
                  </RunningText>
                ),
              },
              {
                child: <RunningText>{dataItem?.date ?? "-"}</RunningText>,
              },
              {
                child: <RunningText>{dataItem?.total ?? "-"}</RunningText>,
              },
              {
                child: <RunningText>{dataItem?.payment ?? ""}</RunningText>,
              },
              {
                child: (
                  <Row>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="status-icon"
                    />
                    <RunningText>Abgeschlossen</RunningText>
                  </Row>
                ),
              },
            ],
          };
        }}
        itemClassName="device-brand-table-item"
      />
    );
  };

  return (
    <ComponentWrapper title="Orders">{buildOrderDataList()}</ComponentWrapper>
  );
};

export default inject("widgetStore", "stateStore")(observer(OrderDataWidget));
