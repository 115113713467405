import React, { useCallback, useEffect, useState } from "react";
import "./file.upload.component.scss";
import { Accept, useDropzone } from "react-dropzone";
import classNames from "classnames";
import { faUpload, faImage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { Upload } from "schemas/upload.schema";
import { HttpUploadService } from "services/httpClients/http.upload.client";
import Loading from "components/general.compoenents/loading.components/loading.component/loading.component";
import { isImage, isVideo } from "globals/helpers/file.type.helper";
import ImageWithAuthHeader from "components/general.compoenents/authenticated.image.component/authenticated.image.component";
import Column from "components/general.compoenents/column.component/column.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import Row from "components/general.compoenents/row.component/row.component";
import LinkButton from "../link.button.component/link.button.component";
import { ModalStore } from "stores/modal.store";
import { inject, observer } from "mobx-react";
import { Asset } from "schemas/asset.schemas/asset.schema";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import AssetStore from "stores/asset.store";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import UploadAssetsList from "components/table.components/upload.asset.list.component/upload.asset.list.component";

interface FileUploadProps {
  label?: string;
  fileUrl?: string;
  folder: string;
  accept?: Accept;
  onFilesUploaded?: (files?: Upload[]) => void;
  className?: string;
  aspectRatio?: number;
  inputRef?: any;
  validationMessage?: string;
  isUploading?: (isLoading: boolean) => void;
  onLibraryChoosed?: (data: any) => void;
  disabled?: boolean;
  modalStore?: ModalStore;
  assetStore?: AssetStore;
  minHeight?: number;
  isAssetsList?: boolean;
  assetSelectionLimit?: number;
}

const FileUpload = ({
  label,
  fileUrl,
  folder,
  accept = {
    "image/*": [".jpeg", ".jpg", ".png"],
    "application/pdf": [".pdf"],
    "audio/*": [".mp3", ".wav"],
    "text/plain": [".txt"],
    "text/csv": [".csv"],
    "video/*": [".mp4", ".webm", ".ogg"],
  },
  onFilesUploaded,
  className,
  aspectRatio = 4 / 3,
  minHeight = undefined,
  inputRef,
  validationMessage,
  isUploading,
  onLibraryChoosed,
  disabled = false,
  modalStore,
  assetStore,
  isAssetsList = false,
  assetSelectionLimit,
}: FileUploadProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState(fileUrl);
  const [hasFile, setHasFile] = useState(Boolean(fileUrl));
  const { t } = useTranslation();
  const translate = createTranslate(t, "fileUploader");

  const uploadedAssets = assetStore?.uploadedAssets ?? [];
  const selectedUploadedAssets = assetStore?.selectedUploadedAssets;

  const onDrop: any = useCallback(
    async (acceptedFiles: any) => {
      if (disabled) return;

      setIsLoading(true);
      isUploading?.(true);

      const uploads: Upload[] | undefined =
        await HttpUploadService.getInstance().uploadFiles({
          files: acceptedFiles,
          folder,
        });

      setSelectedFileUrl(uploads?.[0].path);
      setHasFile(true);

      onFilesUploaded?.(uploads);

      setIsLoading(false);
      isUploading?.(false);
    },
    [disabled]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    noClick: disabled,
    noKeyboard: disabled,
  });

  useEffect(() => {
    // Call initial if undefined
    if (
      onFilesUploaded != null &&
      (selectedFileUrl == null || selectedFileUrl === "")
    ) {
      onFilesUploaded(undefined);
    }
  }, [selectedFileUrl]);

  const fileUploadWrapperClassName = classNames({}, className);

  const fileUploadClassName = classNames("file-upload-container", {
    "file-upload-container--dragging": isDragActive,
    "file-upload-container--error": validationMessage != null,
    "file-upload-container--list": isAssetsList,
  });

  const removeFile = (): void => {
    setSelectedFileUrl("");
    setHasFile(false);
    onFilesUploaded?.([{ path: "" }] as Upload[]);
  };

  const addSelectedUploadedAsset = (asset: Asset): void => {
    if (!selectedUploadedAssets) {
      assetStore?.setSelectedUploadedAssets([asset]);
    } else {
      if (selectedUploadedAssets.length === assetSelectionLimit) {
        return;
      }

      selectedUploadedAssets.push(asset);
      assetStore?.setSelectedUploadedAssets(selectedUploadedAssets);
    }
  };

  const removeSelectedUploadedAsset = (asset: Asset): void => {
    const selectedAssetIndex: number | undefined =
      selectedUploadedAssets?.findIndex(
        (item: Asset) => item._id === asset._id
      );

    if (selectedAssetIndex !== undefined) {
      selectedUploadedAssets?.splice(selectedAssetIndex, 1);
      assetStore?.setSelectedUploadedAssets(selectedUploadedAssets as Asset[]);
    }
  };

  const checkAssetSelected = (asset: Asset): boolean => {
    return !!selectedUploadedAssets?.find(
      (item: Asset) => item._id === asset._id
    );
  };

  const _buildPlaceholder = (): JSX.Element => {
    return isAssetsList ? (
      <Row
        justifyContent="center"
        alignItems="center"
        className="file-upload-list-placeholder"
      >
        <FontAwesomeIcon
          className="file-upload-list-placeholder-icon mr-20"
          icon={faUpload}
        />
        <RunningText className="file-upload-list-placeholder-text">
          {translate("fileUploaderBoxMessage1")}
          <span className="file-upload-list-placeholder-bold">
            {translate("fileUploaderBoxMessage2")}
          </span>
          {translate("fileUploaderBoxMessage3")}
          <br />
          {translate("fileUploaderBoxMessage4")}
        </RunningText>
      </Row>
    ) : (
      <div className="file-upload-placeholder">
        <FontAwesomeIcon
          className="file-upload-placeholder-icon mb-10"
          icon={faImage}
        />
        <RunningText className="file-upload-placeholder-text">
          Datei hochladen
        </RunningText>
      </div>
    );
  };

  const _buildUploadedAssetsList = (): JSX.Element => {
    return (
      <UploadAssetsList
        uploadedAssets={uploadedAssets}
        selectedAssets={assetStore?.selectedUploadedAssets ?? []}
        onClick={(asset: Asset) => {
          if (checkAssetSelected(asset)) {
            removeSelectedUploadedAsset(asset);
          } else {
            addSelectedUploadedAsset(asset);
          }
        }}
        removeUploadedAssets={(index: number) => {
          uploadedAssets?.splice(index, 1);
          assetStore?.setUploadedAssets(uploadedAssets);
        }}
      />
    );
  };

  return (
    <Column className={fileUploadWrapperClassName}>
      <Row justifyContent="space-between" alignItems="center">
        <>{label != null && <TitleText className="mb-5">{label}</TitleText>}</>
        <>
          {hasFile && !isAssetsList ? (
            <LinkButton
              disabled={disabled}
              color="danger"
              className="pr-5 pt-0 pb-5 pl-0"
              label="Datei entfernen"
              onClick={removeFile}
            />
          ) : (
            <>
              {onLibraryChoosed != null && (
                <LinkButton
                  disabled={disabled}
                  className="pr-5 pt-0 pb-5"
                  label="Aus Bibliothek"
                  onClick={() => {
                    if (hasFile) {
                      removeFile();
                    } else {
                      modalStore?.showTemplateModal({
                        onSave: (data) => {
                          if (onLibraryChoosed) onLibraryChoosed(data);
                          setSelectedFileUrl(data?.url);
                          setHasFile(true);
                        },
                      });
                    }
                  }}
                />
              )}
            </>
          )}
        </>
      </Row>
      <div
        className={fileUploadClassName}
        style={{ aspectRatio, minHeight }}
        {...getRootProps()}
      >
        <input {...inputRef} type="hidden" />
        <input {...getInputProps()} disabled={disabled} />
        {(selectedFileUrl == null || selectedFileUrl === "" || isAssetsList) &&
          !isLoading &&
          _buildPlaceholder()}
        {isLoading && <Loading size="medium" theme="dark" />}
        {selectedFileUrl != null &&
          !isLoading &&
          isImage(selectedFileUrl) &&
          !isAssetsList && (
            <ImageWithAuthHeader className="file-image" src={selectedFileUrl} />
          )}
        {selectedFileUrl != null && !isLoading && isVideo(selectedFileUrl) && (
          <video
            height="100%"
            width="100%"
            style={{ objectFit: "cover", borderRadius: "10px" }}
            controls
            controlsList="nodownload"
          >
            <source src={selectedFileUrl} type="video/mp4" />
          </video>
        )}
      </div>
      <PageContainer className="content-container">
        {_buildUploadedAssetsList()}
      </PageContainer>
    </Column>
  );
};

export default inject("modalStore", "assetStore")(observer(FileUpload));
