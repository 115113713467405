import Image from "components/image.component/image.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { SmallText } from "components/text.components/small.text.component/small.text.component";
import { ImageSizes } from "globals/enums/global.enum";
import React from "react";
import { Exercise } from "../../../schemas/exercise.schemas/exercise.schema";
import ListDataTable from "../list.data.table.component/list.data.table.component";
import classNames from "classnames";

interface GlobalExercisesListProps {
  exercises: Exercise[];
  selectedExercises: Exercise[];
  onClick?: (exercise: Exercise) => void;
}

const GlobalExercisesList = ({
  exercises,
  selectedExercises,
  onClick,
}: GlobalExercisesListProps): JSX.Element => {
  return (
    <ListDataTable
      data={exercises}
      onClick={onClick}
      columns={[
        {
          flex: 0,
        },
        {
          flex: 0,
        },
        {
          flex: 2,
        },
      ]}
      dataTableItemBuilder={(dataItem: Exercise, index: number) => {
        const isSelected = selectedExercises?.find((selectedItem: any) => {
          return selectedItem._id === dataItem._id;
        });

        return {
          key: dataItem._id ?? dataItem.coverImageUrl,
          className: classNames({
            "list-data-table-body-item-selected": isSelected,
          }),
          children: [
            {
              child: <></>,
              className: "mr-10",
            },
            {
              child: (
                <Image
                  rounded={false}
                  imageUrl={dataItem.coverImageUrl}
                  size={ImageSizes.M}
                />
              ),
            },
            {
              child: (
                <div>
                  <RunningText
                    className={isSelected ? "text-color-inverted" : ""}
                  >
                    {dataItem.title.de ?? ""}
                  </RunningText>
                  {dataItem?.muscleGroups?.agonist != null && (
                    <SmallText
                      className={isSelected ? "text-color-inverted" : ""}
                    >
                      {dataItem?.muscleGroups?.agonist?.map(
                        (muscleGroup, index) =>
                          `${muscleGroup.title.de}${
                            index !==
                            dataItem?.muscleGroups?.agonist?.length - 1
                              ? ", "
                              : ""
                          }`
                      )}
                    </SmallText>
                  )}
                </div>
              ),
            },
          ],
        };
      }}
      itemClassName="device-brand-table-item"
      gap={30}
      disableHeader={true}
    />
  );
};

export default GlobalExercisesList;
