import React from "react";
import { arrayMoveImmutable } from "array-move";
import Column from "components/general.compoenents/column.component/column.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import { inject, observer } from "mobx-react";
import { useOutlet } from "react-router-dom";
import ExerciseStore from "stores/exercise.store";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import ListDataTable, {
  ListDropdownMenuProps,
} from "components/table.components/list.data.table.component/list.data.table.component";
import { InfoList } from "schemas/exercise.schemas/exercise.schema";
import StudioStore from "stores/studio.store";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import Row from "components/general.compoenents/row.component/row.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { CollectionDataType } from "globals/enums/global.enum";
import { navigationHelper } from "globals/helpers/navigation.helper";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { ModalStore } from "stores/modal.store";

interface ExerciseInfoOverviewPageProps {
  exerciseStore?: ExerciseStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const ExerciseInfoOverviewPage = ({
  exerciseStore,
  studioStore,
  modalStore,
}: ExerciseInfoOverviewPageProps): JSX.Element => {
  const outlet = useOutlet();
  const navigate = navigationHelper();
  const { t } = useTranslation();
  const translate = createTranslate(t, "exerciseInfoOverviewPage");

  const currentExericse = exerciseStore?.currentExercise?.data;
  const isTemplate = currentExericse?.type === CollectionDataType.TEMPLATE;
  const currentLanguage = studioStore?.currentLanguage.value;

  const infoTopics = (currentExericse?.infos ?? []).map((info): InfoList => {
    return { ...info, id: Math.random().toString() };
  });

  // Render ExerciseInfoDetailPage if outlet is present
  if (outlet != null) {
    return <>{outlet}</>;
  }

  const onSortEnd = (oldIndex: number, newIndex: number): void => {
    if (!infoTopics || currentExericse == null) return;

    const sortedInfoTopics = arrayMoveImmutable(infoTopics, oldIndex, newIndex);
    currentExericse.infos = sortedInfoTopics;

    exerciseStore?.setCurrentExercise(currentExericse);

    // update exercise on sort end
    exerciseStore?.updateExercise({
      id: currentExericse._id!,
      exercise: currentExericse,
    });
  };

  const _buildListDropdownMenu = ():
    | ListDropdownMenuProps<InfoList>
    | undefined => {
    if (isTemplate) return undefined;

    return {
      items: [
        {
          label: translate("listDropdownMenu.edit"),
          icon: faEdit,
          onClick: (infoListItem: InfoList) => {
            if (currentExericse == null || infoListItem == null) return;

            // get index of infoListItem
            const index = infoTopics.findIndex(
              (info) => info.id === infoListItem.id
            );

            // navigate to infoListItem
            navigate(
              `inventory/exercises/${currentExericse?._id}/topics/${index}`
            );
          },
        },
        {
          label: translate("listDropdownMenu.delete"),
          icon: faTrash,
          onClick: (infoListItem: InfoList) => {
            if (currentExericse == null || infoListItem == null) return;

            modalStore?.showDeleteAlert({
              t,
              onConfirm: async () => {
                // get index of infoListItem
                const index = infoTopics.findIndex(
                  (info) => info.id === infoListItem.id
                );

                // remove infoListItem
                infoTopics.splice(index, 1);

                // update current exercise
                currentExericse.infos = infoTopics;
                exerciseStore?.setCurrentExercise(currentExericse);

                // update exercise on sort end
                exerciseStore?.updateExercise({
                  id: currentExericse._id!,
                  exercise: currentExericse,
                });
              },
            });
          },
        },
      ],
      placement: "left",
    };
  };

  const _buildActions = (): JSX.Element => {
    return (
      <LinkButton
        disabled={isTemplate}
        noPadding
        onClick={(e) => {
          exerciseStore?.addNewExerciseInfoListItem();
          navigate(`inventory/exercises/${currentExericse?._id}/topics/${0}`);
        }}
        label={translate("addTopicButton")}
      />
    );
  };

  return (
    <ComponentWrapper
      title={translate("componentWrapperHeadline")}
      actions={_buildActions()}
    >
      <div>
        <Column>
          {infoTopics?.length === 0 && (
            <RunningText className="ml-15 mt-15 mb-15 mr-15">
              {translate("noDataMessage")}
            </RunningText>
          )}
          <ListDataTable
            disableSort={isTemplate}
            data={infoTopics}
            onClick={(_infoList: InfoList, index, e: any) => {
              navigate(
                `inventory/exercises/${currentExericse?._id}/topics/${index}`
              );
            }}
            listDropdownMenu={_buildListDropdownMenu()}
            columns={[
              {
                child: (
                  <RunningText className="bold-text">
                    {translate("topicTitle")}
                  </RunningText>
                ),
                flex: 1,
              },
              {
                child: (
                  <RunningText className="bold-text">
                    {translate("topicContentCount")}
                  </RunningText>
                ),
              },
            ]}
            dataTableItemBuilder={(dataItem: InfoList, index) => {
              return {
                key: `${dataItem.id}-${index}`,
                children: [
                  {
                    child: (
                      <Row alignItems="center">
                        <FontAwesomeIcon
                          icon={faBars}
                          key={`info-topic-${index}`}
                        />
                        <RunningText className="mr-15 ml-15 bold-text">
                          {dataItem?.label?.[currentLanguage!]}
                        </RunningText>
                      </Row>
                    ),
                  },
                  {
                    child: (
                      <HighlightedInfoText fitContent>
                        {dataItem?.data?.length <= 0
                          ? translate("noTopicCount")
                          : dataItem?.data?.length}
                      </HighlightedInfoText>
                    ),
                  },
                ],
              };
            }}
            sortable
            onSortEnd={onSortEnd}
          />
        </Column>
      </div>
    </ComponentWrapper>
  );
};

export default inject(
  "exerciseStore",
  "studioStore",
  "modalStore"
)(observer(ExerciseInfoOverviewPage));
