import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import { GenericHttpClient } from "./config/http.generic.client";

export class HttpExerciseService extends GenericHttpClient<Exercise> {
  static _instance: HttpExerciseService;
  static getInstance(): HttpExerciseService {
    if (this._instance == null) {
      this._instance = new HttpExerciseService("/exercises");
    }
    return this._instance;
  }

  async cloneLibraryExerciseToStudioExercise(args: {
    libraryExerciseID: string;
  }): Promise<Exercise> {
    const response = await this.put(
      `/exercises/${args.libraryExerciseID}/clone`
    );
    return response.data as Exercise;
  }
}
