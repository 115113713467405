import { System } from "schemas/system.schema";
import * as yup from "yup";

export const feedConfigSchema = yup.object().shape({
  _id: yup.string().notRequired(),
  title: yup
    .string()
    .required("Titel ist verpflichtend")
    .max(40, "Maximal 40 Zeichen"),
  iconUrl: yup.string().notRequired(),
  subTitle: yup.string().max(40, "Maximal 40 Zeichen"),
  sort: yup.number().integer().notRequired().default(0),
  layoutID: yup
    .string()
    .required("Layout ist verpflichtend")
    .default("LISTVIEW"),
  widgetID: yup.string().required("Widget ist verpflichtend"),
  scrollDirection: yup.string().required("Scrollrichtung ist verpflichtend"),
  aspectRatio: yup.string().notRequired(),
  location: yup.string().notRequired().default("HOME"),
});

export interface FeedConfig extends yup.InferType<typeof feedConfigSchema> {
  system: System;
}
