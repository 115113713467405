import React, {
  createContext,
  useContext,
  useMemo,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DataAttributeStore from "stores/data.attribute.store";
import { DataAttribute } from "schemas/data.attribute.schemas/data.attribute.schema";
import { PaginationDataList } from "globals/intefaces/pageination.data.list.interface";
import { generateValidationSchemaNew } from "globals/helpers/data.attribute.helper";

interface DataAttributeProviderType {
  attributes: PaginationDataList<DataAttribute>;
  collectionID: string;
  isLoading: boolean;
  methods: ReturnType<typeof useForm>;
}

const DataAttributContext = createContext<DataAttributeProviderType | null>(
  null
);

export const useCustomContext = (): DataAttributeProviderType => {
  const context = useContext(DataAttributContext);
  if (!context) {
    throw new Error("useCustomContext must be used within a CustomProvider");
  }
  return context;
};

interface DataAttributeProviderProps {
  children: ReactNode;
  dataAttributeStore: DataAttributeStore;
  collectionID: string;
  initialFormData?: any;
}

export const DataAttributeProvider = ({
  children,
  dataAttributeStore,
  collectionID,
  initialFormData = {},
}: DataAttributeProviderProps): JSX.Element => {
  const [attributes, setAttributes] = useState<
    PaginationDataList<DataAttribute>
  >({
    data: [],
    pageIndex: 0,
    itemsInPage: 0,
    isLoading: true,
  });

  const validationSchema = useMemo(
    () =>
      generateValidationSchemaNew(attributes?.data ?? [], dataAttributeStore),
    [attributes, dataAttributeStore]
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    defaultValues: initialFormData,
  });

  useEffect(() => {
    const loadAttributes = async (): Promise<void> => {
      const fetchedAttributes =
        await dataAttributeStore.getCollectionAttributes(collectionID);
      setAttributes({
        ...fetchedAttributes,
        isLoading: false,
      });
      // Aktualisiere das Validierungsschema, wenn sich die Attribute ändern
      methods.reset({}, { keepValues: true }); // Setzt die Formularwerte zurück, behält aber die aktuellen Werte
    };

    loadAttributes();
  }, [collectionID, dataAttributeStore, methods]);

  const contextValue = useMemo(
    () => ({
      attributes,
      collectionID,
      isLoading: attributes.isLoading,
      methods,
    }),
    [attributes, collectionID, methods]
  );

  return (
    <DataAttributContext.Provider value={contextValue}>
      <FormProvider {...methods}>{children}</FormProvider>
    </DataAttributContext.Provider>
  );
};
