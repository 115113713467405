import React, { ReactNode } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface SortableItemProps {
  children: ReactNode;
  id: string;
  disabled?: boolean;
}

export const SortableItem = ({
  children,
  id,
  disabled = false,
}: SortableItemProps): JSX.Element => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, disabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: disabled ? "not-allowed" : undefined, // Cursor-Stil anpassen, wenn deaktiviert
  };

  // Modifizieren des Verhaltens, basierend auf der 'disabled' Eigenschaft
  const refSetter = disabled ? undefined : setNodeRef;

  // Listener nur anwenden, wenn das Element nicht deaktiviert ist
  const effectiveListeners = disabled ? {} : listeners;
  const effectiveAttributes = disabled ? {} : attributes;

  return (
    <div
      ref={refSetter}
      style={style}
      {...effectiveAttributes}
      {...effectiveListeners}
    >
      {children}
    </div>
  );
};
