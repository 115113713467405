import React, { useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { debounce } from "lodash";
import { ModalStore } from "stores/modal.store";
import GlobalSearchStore, { SearchTopic } from "stores/global.search.store";
import GlobalSearchResultList from "./components/global.search.result.list.component";
import Column from "components/general.compoenents/column.component/column.component";
import TagFilterBarComponent, {
  FilterTag,
} from "components/input.components/tag.filter.bar.component/tag.filter.bar.component";
import Gleap from "gleap";
import "./global.search.modal.component.scss";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import Loading from "components/general.compoenents/loading.components/loading.component/loading.component";
import SmallHeadline from "components/text.components/small.headline.component/small.headline.component";
import { useInitialFocusAndSelect } from "globals/helpers/hook.helper";
import { createTranslate } from "globals/helpers/global.helper";
import { useTranslation } from "react-i18next";
import PageIntroCard from "components/card.components/page.intro.card.component/page.intro.card.component";

interface GlobalSearchModalProps {
  modalStore?: ModalStore;
  globalSearchStore?: GlobalSearchStore;
}

const GlobalSearchModal = ({
  modalStore,
  globalSearchStore: searchStore,
}: GlobalSearchModalProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "globalSearchModal");

  const { topic: initialTopic } = modalStore?.customData ?? {};

  // prepare tags for tag filter bar based on search topics enum
  const tags = Object.values(SearchTopic).map((topic) => ({
    key: topic,
    label: translate(`${topic}.label`),
  }));

  const [selectedTopic, setSelectedTopic] = useState<SearchTopic>(
    initialTopic ?? SearchTopic.ALL
  );
  const [allTopicsHaveNoData, setAllTopicsHaveNoData] = useState(false);
  const lastSearchTerm = searchStore?.getSearchedTermForTopic(selectedTopic);
  const [initialLoad, setInitialLoad] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  // focus and select the initial value of the input field
  const autoFocusRef = useInitialFocusAndSelect(searchTerm);

  const debounceSearch = useCallback(
    debounce((nextValue, topic) => {
      searchStore?.globalSearch(nextValue, topic);
    }, 500),
    []
  );

  useEffect(() => {
    if (autoFocusRef.current) {
      autoFocusRef.current.focus();
    }
  }, [selectedTopic]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }

    if (
      searchTerm != null &&
      searchTerm !== lastSearchTerm &&
      searchTerm?.trim().length > 0
    ) {
      debounceSearch(searchTerm, selectedTopic);
    }
  }, [searchTerm, debounceSearch]);

  useEffect(() => {
    if (lastSearchTerm != null && initialLoad) {
      setSearchTerm(lastSearchTerm);
    }
  }, []);

  // check if all topics have no data for the current search term
  useEffect(() => {
    if (selectedTopic === SearchTopic.ALL) {
      checkAllTopicsData();
    }
  }, [searchTerm, selectedTopic]);

  const checkAllTopicsData = async (): Promise<void> => {
    const hasData = await Promise.all(
      Object.values(SearchTopic)
        .filter((t) => t !== SearchTopic.ALL)
        .map(async (topic) => {
          const result = await searchStore?.getSearchedDataForTopic(topic);
          return result?.data?.length > 0;
        })
    );

    // set state if all topics have no data
    setAllTopicsHaveNoData(!hasData.includes(true));
  };

  const handleTagSelectionChange = (selectedTags: FilterTag[]): void => {
    if (selectedTags?.length === 0) return;

    // set selected topic and reset search input
    const selectedTopic = selectedTags[0].key as SearchTopic;
    setSelectedTopic(selectedTopic);
    setSearchTerm("");
  };

  const renderSearchResults = (): JSX.Element[] | JSX.Element => {
    if (selectedTopic === SearchTopic.ALL) {
      const resuls = Object.values(SearchTopic)
        .filter((t) => t !== SearchTopic.ALL)
        ?.map((topic) => {
          const result = searchStore?.getSearchedDataForTopic(topic);
          const noDataForTpoic =
            searchTerm?.trim().length > 0 && result?.data?.length === 0;

          if (noDataForTpoic) {
            return <></>;
          }

          return (
            <Column key={`${topic}-search-results`}>
              <SmallHeadline className="mt-10 mb-5 ml-10">
                {translate(`${topic}.label`)}
              </SmallHeadline>
              <GlobalSearchResultList
                searchTerm={searchTerm}
                key={topic}
                topic={topic}
                data={result?.data ?? []}
                isLoading={result?.isLoading ?? false}
              />
              <Spacer bold />
            </Column>
          );
        });

      if (allTopicsHaveNoData) {
        return (
          <PageIntroCard
            key={`${selectedTopic}-no-data`}
            title={translate("noResultsTitle")}
            description={translate("noResultsDescripton")}
            buttonText={translate("noResultAction")}
            wrapperClassName="mt-20"
            imagePosition="bottom center"
            buttonAction={() => {
              Gleap.open();
            }}
          />
        );
      }

      return resuls;
    } else {
      const result = searchStore?.getSearchedDataForTopic(selectedTopic);
      const noDataForTopic =
        searchTerm?.trim().length > 0 && result?.data?.length === 0;

      if (noDataForTopic) {
        return (
          <PageIntroCard
            key={`${selectedTopic}-no-data`}
            title={translate("noResultsTitle")}
            description={translate("noResultsDescripton")}
            buttonText={translate("noResultAction")}
            wrapperClassName="mt-20"
            imagePosition="bottom center"
            buttonAction={() => {
              Gleap.open();
            }}
          />
        );
      }

      return (
        <GlobalSearchResultList
          searchTerm={searchTerm}
          topic={selectedTopic}
          data={result?.data ?? []}
          isLoading={result?.isLoading ?? false}
        />
      );
    }
  };

  return (
    <Column>
      <div className="global-search-container">
        <input
          ref={autoFocusRef}
          className="global-search-container-input"
          type="text"
          autoFocus
          placeholder={translate("searchPlaceholder")}
          value={searchTerm}
          onChange={(value) => {
            setSearchTerm(value?.target?.value ?? "");
          }}
        />
        {searchStore?.isLoading && <Loading theme="dark" size="semi-medium" />}
      </div>

      {!searchStore?.isLoading && (
        <>
          <Spacer />
          <TagFilterBarComponent
            className="tag-filter-bar-container"
            singleSelection
            tags={tags}
            initialSelectedTags={[{ key: selectedTopic, label: selectedTopic }]}
            onTagSelectionChange={handleTagSelectionChange}
          />
          <Spacer />
          {searchTerm?.trim()?.length > 0 && (
            <Column className="global-search-results">
              {renderSearchResults()}
            </Column>
          )}
        </>
      )}
    </Column>
  );
};

export default inject(
  "modalStore",
  "globalSearchStore"
)(observer(GlobalSearchModal));
