import React from "react";
import classNames from "classnames";
import Headline from "../../text.components/headline.component/headline.component";
import Column from "../../general.compoenents/column.component/column.component";
import "./page.intro.card.component.scss";
import Row from "../../general.compoenents/row.component/row.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import LinkButton from "components/input.components/link.button.component/link.button.component";

interface PageIntroCardProps {
  title: string;
  description: string;
  buttonText?: string;
  buttonAction?: () => void;
  image?: any;
  imagePosition?: string;
  imageClassName?: string;
  wrapperClassName?: string;
  disableMargin?: boolean;
  buttonType?: "filled-button" | "link-button";
}

const PageIntroCard = ({
  title,
  description,
  buttonText,
  image,
  imagePosition,
  imageClassName,
  wrapperClassName,
  buttonAction,
  disableMargin = false,
  buttonType = "filled-button",
}: PageIntroCardProps): JSX.Element => {
  const introWrapperClassName = classNames(
    {
      "page-intro-card": true,
      "page-intro-card-disable-margin": disableMargin,
    },

    wrapperClassName
  );

  const introCardClassName = classNames(
    {
      "page-intro-card-image": true,
    },
    imageClassName
  );

  const _renderButton = (): JSX.Element => {
    if (buttonType === "filled-button") {
      return (
        <FilledButton
          onClick={buttonAction}
          className="mt-10"
          label={buttonText ?? ""}
        />
      );
    }
    return (
      <LinkButton
        onClick={buttonAction}
        className="mt-10 pl-0"
        label={buttonText ?? ""}
      />
    );
  };

  return (
    <div className={introWrapperClassName}>
      <Column
        className="page-intro-card-content"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Headline className="mb-10">{title}</Headline>
        <RunningText>{description}</RunningText>
        <Row gap={15}>{buttonText && buttonAction && _renderButton()}</Row>
      </Column>
      {image && (
        <img
          className={introCardClassName}
          src={image}
          alt={title}
          style={{
            objectPosition: imagePosition ?? "center",
          }}
        />
      )}
    </div>
  );
};

export default PageIntroCard;
