import * as yup from "yup";

export const dataAttributeOptionSchema = yup.object().shape({
  label: yup.string().required(),
  value: yup.string().required(),
  icon: yup.string().notRequired(),
});

export interface DataAttributeOption
  extends yup.InferType<typeof dataAttributeOptionSchema> {}
