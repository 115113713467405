import React from "react";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { FeedConfig } from "schemas/feed.schemas/feed.config.schema";
import {
  createTranslate,
  formatDate,
  generatePlaceholderAvatarUrl,
} from "globals/helpers/global.helper";
import { useTranslation } from "react-i18next";
import Row from "components/general.compoenents/row.component/row.component";
import Image from "components/image.component/image.component";
import { ImageSizes } from "globals/enums/global.enum";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import {
  faEdit,
  faNewspaper,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import FeedStore from "stores/feed.store";
import { inject, observer } from "mobx-react";
import { ModalStore } from "stores/modal.store";
import { navigationHelper } from "globals/helpers/navigation.helper";

interface FeedConfigListProps {
  feedConfigs: FeedConfig[];
  onClick?: (feedConfig: FeedConfig) => void;
  isLoading?: boolean;
  feedStore?: FeedStore;
  modalStore?: ModalStore;
}

const FeedConfigList = ({
  feedConfigs,
  onClick,
  isLoading,
  feedStore,
  modalStore,
}: FeedConfigListProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "feedPage.config.overview");
  const navigate = navigationHelper();

  const handleArchiveFeedConfig = async (config: FeedConfig): Promise<void> => {
    if (config == null) return;

    modalStore?.showDeleteAlert({
      t,
      onConfirm: async () => {
        if (config?._id != null) {
          await feedStore?.archiveFeedConfig(config);
        }
      },
    });
  };

  return (
    <ComponentWrapper noPadding>
      <ListDataTable
        sortable
        onSortEnd={() => {
          console.log("sort");
        }}
        data={feedConfigs}
        noDataMessage={translate("noDataMessage")}
        isLoading={isLoading}
        onClick={onClick}
        listDropdownMenu={{
          items: [
            {
              label: translate("listDropdownMenu.edit"),
              icon: faEdit,
              onClick: (config: FeedConfig) => {
                if (config?._id == null) return;
                navigate(`feed/${config?._id}/config`);
              },
            },
            {
              label: translate("listDropdownMenu.show"),
              icon: faNewspaper,
              onClick: (config: FeedConfig) => {
                if (config?._id == null) return;
                navigate(`feed/${config?._id}/content`);
              },
            },
            {
              label: translate("listDropdownMenu.delete"),
              icon: faTrash,
              onClick: (config: FeedConfig) => {
                handleArchiveFeedConfig(config);
              },
            },
          ],
          placement: "left",
        }}
        columns={[
          {
            child: <TitleText tableHeader>{translate("name")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("subTitle")}</TitleText>,
            flex: 2,
          },
          {
            child: <TitleText tableHeader>{translate("createdAt")}</TitleText>,
          },
        ]}
        dataTableItemBuilder={(dataItem: FeedConfig) => {
          return {
            key: dataItem._id,
            children: [
              {
                child: (
                  <Row alignItems="center">
                    <Image
                      imageUrl={generatePlaceholderAvatarUrl(dataItem?.title)}
                      size={ImageSizes.M}
                    />
                    <RunningText className="ml-20">
                      {dataItem.title}
                    </RunningText>
                  </Row>
                ),
              },

              {
                child: <RunningText>{dataItem.subTitle}</RunningText>,
              },

              {
                child: (
                  <RunningText>
                    {formatDate(dataItem?.system?.createdAt)}
                  </RunningText>
                ),
              },
            ],
          } as any;
        }}
      />
    </ComponentWrapper>
  );
};

export default inject("feedStore", "modalStore")(observer(FeedConfigList));
