import CanvasEditor from "@vondot-development/metool_lib/dist/components/public/canvas.editor.component/canvas.editor.component";
import { NavigationActionType } from "@vondot-development/metool_lib/dist/globals/interfaces/navigation.interface";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import MainLayout from "layouts/main.layout/main.layout";
import React from "react";
import { useParams } from "react-router-dom";

const CanvasEditorPage = (): JSX.Element => {
  const { pageID } = useParams();

  const _buildPageHeadline = (): JSX.Element => {
    return <PageHeadline title={"Edit Page"} showBackButton />;
  };

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      {pageID != null && (
        <CanvasEditor
          pageToRender={{
            actionType: NavigationActionType.NAV_TO_PAGE,
            targetID: pageID,
          }}
        />
      )}
    </MainLayout>
  );
};

export default CanvasEditorPage;
