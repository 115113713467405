import React, { useEffect } from "react";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import { Outlet, Route, Routes, useParams } from "react-router";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import { inject, observer } from "mobx-react";
import MainLayout from "layouts/main.layout/main.layout";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import TrainingPlanStore from "stores/training.plan.store";
import TrainingPlanForm, {
  trainingPlanInfoFormSchema,
} from "./components/training.plan.form.component/training.plan.form.component";
import TrainingPlanExerciseList, {
  exerciseListFormSchema,
} from "../../../components/table.components/training.plan.exercise.list.component/training.plan.exercise.list.component";
import { ModalStore } from "stores/modal.store";
import { useTranslation } from "react-i18next";
import FixedControllersToolbar from "components/navigation.components/fixed.controller.toolbar.component/fixed.controller.toolbar.component";
import { useForm } from "react-hook-form";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePersistentId } from "globals/helpers/form.key.helper";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import { navigationHelper } from "globals/helpers/navigation.helper";
import { isEqual } from "lodash";

interface TrainingPlanDetailPageProps {
  trainingPlanStore?: TrainingPlanStore;
  modalStore?: ModalStore;
}

const TrainingPlanPage = ({
  trainingPlanStore,
  modalStore,
}: TrainingPlanDetailPageProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = navigationHelper();

  const { trainingPlanID } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const persistentId = usePersistentId(trainingPlanID);
  const isEditing = trainingPlanID !== "new";

  const currentPath = location.pathname;
  const trainingPlan = trainingPlanStore?.currentTrainingPlan?.data;
  const planExercises = trainingPlan?.exercises ?? [];

  useEffect(() => {
    initializePage();
  }, [trainingPlanID]);

  const initializePage = async (): Promise<void> => {
    if (trainingPlanID == null) {
      return;
    }

    if (trainingPlanID !== "new") {
      trainingPlanStore?.fetchAndSetTrainingPlan({
        trainingPlanID,
      });
    }
  };

  if (!isEditing) {
    const initialPlan = {
      type: "STUDIO",
    };

    if (!isEqual(trainingPlanStore?.currentTrainingPlan?.data, initialPlan)) {
      trainingPlanStore?.setCurrentTrainingPlan(initialPlan as TrainingPlan);
    }
  }

  const trainingPlanExercisesForm = useForm<TrainingPlan>({
    resolver: yupResolver(exerciseListFormSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    values: { exercises: planExercises, type: "STUDIO" } as any,
  });

  const trainingPlanForm = useForm({
    resolver: yupResolver(trainingPlanInfoFormSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    values: { ...trainingPlan, type: "STUDIO" } as any,
  });

  const { getValues } = trainingPlanForm;

  const formsAreDirty = [trainingPlanExercisesForm, trainingPlanForm].some(
    (form) => form.formState.isDirty
  );

  const tabOptions = [
    {
      label: t("trainingPlanDetailPage.tabs.info"),
      path: "info",
      formID: "training-plan-form",
      form: trainingPlanForm,
      element: (
        <TrainingPlanForm
          formMethods={trainingPlanForm}
          key={trainingPlanID}
          isEditing={isEditing}
        />
      ),
    },
    {
      label: t("trainingPlanDetailPage.tabs.exercises"),
      path: "exercises",
      formID: "training-plan-exercise-form",
      form: trainingPlanExercisesForm,
      element: (
        <TrainingPlanExerciseList formMethods={trainingPlanExercisesForm} />
      ),
    },
  ];

  const currentTab = tabOptions.find((tab) => currentPath.includes(tab.path));

  const deleteTrainingPlan = async (): Promise<void> => {
    modalStore?.showConfirmAlert({
      onConfirm: async () => {
        if (trainingPlan?._id == null) return;
        trainingPlanStore?.archivePlan(trainingPlan);

        // Navigate to the training plan overview page
        navigate("training-plans");
      },
      confirmLabel: t("trainingPlanDetailPage.deleteButton.confirmLabel"),
      title: t("trainingPlanDetailPage.deleteButton.title"),
      description: t("trainingPlanDetailPage.deleteButton.description"),
    });
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        tabs={tabOptions.map((tab) => (
          <Tab
            label={tab.label}
            path={tab.path}
            rootPath={trainingPlanID}
            key={tab.path}
          />
        ))}
      />
    );
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline showBackButton rootPath="training-plans">
        {_buildTabs()}
      </PageHeadline>
    );
  };

  if (trainingPlanStore?.currentTrainingPlan?.isLoading === true) {
    return <SkeletonDetailPage />;
  }

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer key={persistentId}>
        <WidthLimiterWrapper maxSize="form">
          <Routes>
            <Route element={<Outlet />}>
              {tabOptions.map(({ path, element }) => (
                <Route path={path} element={element} key={path} />
              ))}
            </Route>
          </Routes>
          <FixedControllersToolbar
            formID={currentTab?.formID}
            disabled={!formsAreDirty}
            isLoading={false}
            hasUnsavedChanges={formsAreDirty}
            keyString="booking-options-save-btn"
            title={getValues("title")}
            imageUrl={getValues("coverImageUrl")}
            handleDelete={deleteTrainingPlan}
          />
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "trainingPlanStore",
  "modalStore"
)(observer(TrainingPlanPage));
