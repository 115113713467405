import { systemSchema } from "schemas/system.schema";
import * as yup from "yup";
import { userAccountSchema } from "./user.account.schema";
import { userGoalSchema } from "./user.goal.schema";
import { userStudioSchema } from "./user.studio.schema";

export const userSchema = yup.object().shape({
  _id: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string(),
  gender: yup.string(),
  dateOfBirth: yup.date(),
  bodyHeight: yup.number(),
  email: yup.string().email().required(),
  profileImageUrl: yup.string(),
  weeklyTrainingRhythm: yup.number().notRequired(), // TODO added for FIBO DEMO
  trainingsLevel: yup.number(),
  deviceIdentifierID: yup.string().notRequired(),
  accounts: yup.array().of(userAccountSchema),
  setupCompleted: yup.boolean().required(),
  webAccess: yup.boolean(),
  studios: yup.array().of(userStudioSchema),
  goals: yup.array().of(userGoalSchema),
  language: yup.string().required(),
  system: systemSchema.notRequired(),
});

export interface User extends yup.InferType<typeof userSchema> {}

export interface TeamMember extends User {
  roleAlias: string;
}
