import React, { useEffect } from "react";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import MainLayout from "layouts/main.layout/main.layout";
import marketingIllustrations from "assets/illustrations/invitation.png";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import { ModalStore } from "stores/modal.store";
import ExerciseStore from "stores/exercise.store";
import LinkTabs from "components/input.components/tab.components/link.tab.component/link.tabs.component";
import Tab from "components/input.components/tab.components/tab.component/tab.component";
import { Outlet, Route, Routes, useLocation } from "react-router";
import ExerciseList from "./components/exercise.list.component/exercise.list.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { SearchTopic } from "stores/global.search.store";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import PageIntroCard from "components/card.components/page.intro.card.component/page.intro.card.component";
import { navigationHelper } from "globals/helpers/navigation.helper";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import Column from "components/general.compoenents/column.component/column.component";

interface InventoryOverviewPageProps {
  exerciseStore?: ExerciseStore;
  modalStore?: ModalStore;
}

const InventoryOverviewPage = ({
  exerciseStore,
  modalStore,
}: InventoryOverviewPageProps): JSX.Element => {
  const location = useLocation();
  const avtiveTab = location.pathname.split("/").pop();

  const navigate = navigationHelper();
  const { t } = useTranslation();
  const translate = createTranslate(t, `inventoryPage.${avtiveTab}.overview`);

  const exercises = exerciseStore?.exercises;

  useEffect(() => {
    initializePage();
  }, []);

  const initializePage = async (): Promise<void> => {
    await exerciseStore?.fetchAndSetExercises({});
  };

  const handleClickItem = (
    item: any,
    inventoryType: "exercises",
    isNew?: boolean
  ): void => {
    if (item?._id != null && !isNew) {
      navigate(`inventory/${inventoryType}/${item?._id}/info`);
    } else {
      navigate(`inventory/${inventoryType}/new/info`);
    }
  };

  const _buildTabs = (): JSX.Element => {
    return (
      <LinkTabs
        showBorder
        actions={[
          <FilledButton
            key="search-button"
            className="mb-5"
            label="Search"
            icon={faSearch}
            onClick={() => {
              modalStore?.openGlobalSearchModal(SearchTopic.EXERCISE);
            }}
          />,
        ]}
        tabs={[
          <Tab
            label={t("inventoryPage.exercises.overview.tabTitle")}
            key="exercises-tab"
            path="exercises"
            rootPath={"inventory"}
          />,
        ]}
      >
        <Outlet />
      </LinkTabs>
    );
  };

  const _buildExerciseList = (): JSX.Element => {
    return (
      <Column className="mt-15">
        <ExerciseList
          exercises={exercises?.data ?? []}
          isLoading={exercises?.isLoading}
          onClick={(exercise: Exercise) => {
            handleClickItem(exercise, avtiveTab as any, false);
          }}
        />
      </Column>
    );
  };

  return (
    <MainLayout>
      <PageContainer>
        <WidthLimiterWrapper>
          <PageIntroCard
            image={marketingIllustrations}
            imagePosition="bottom center"
            title={translate("introTitle")}
            description={translate("introDescription")}
            buttonText={translate("addButton")}
            buttonAction={() => {
              handleClickItem(undefined, avtiveTab as "exercises", true);
            }}
          />

          {_buildTabs()}

          <Routes>
            <Route path="exercises" element={_buildExerciseList()} />
          </Routes>
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "modalStore",
  "exerciseStore"
)(observer(InventoryOverviewPage));
