import React, { useEffect } from "react";
import AppPreviewComponent from "components/app.preview.component/app.preview.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import SplitLayout from "layouts/split.layout/split.layout";
import { inject, observer } from "mobx-react";
import FeedStore from "stores/feed.store";
import StudioStore from "stores/studio.store";
import Column from "components/general.compoenents/column.component/column.component";
import WidgetTemplate from "components/widget.template.component/widget.template.component";
import { setProperty } from "globals/helpers/assign.object.keys.helper";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { feedConfigSchema } from "schemas/feed.schemas/feed.config.schema";
import { toast } from "react-toastify";
import {
  aspectRatioTemplates,
  swipeTemplates,
  widgetTemplates,
} from "globals/data/globals.data";
import "./feed.config.form.component.scss";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";

interface FeedConfigFormProps {
  studioStore?: StudioStore;
  feedStore?: FeedStore;
  onFormSubmit: (data: any) => void;
  onDirty: (isDirty: boolean) => void;
}

const FeedConfigForm = ({
  studioStore,
  feedStore,
  onFormSubmit,
  onDirty,
}: FeedConfigFormProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "feedPage.config.form");

  const editingStudio = studioStore?.editingStudio;
  const feedConfig = feedStore?.currentFeedConfig?.data;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    clearErrors,
  } = useForm({
    resolver: yupResolver(feedConfigSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { ...feedConfig },
  });

  useEffect(() => {
    studioStore?.setEditingProject();
  }, []);

  useEffect(() => {
    onDirty(isDirty);
  }, [isDirty]);

  const setValueAndMarkDirty = (name: any, value: any): void => {
    setValue(name, value, { shouldDirty: true });
  };

  const _buildPreview = (): JSX.Element => {
    if (studioStore == null || editingStudio == null) {
      return <></>;
    }

    return (
      <div className="app-configurator-preview-container">
        <AppPreviewComponent />
      </div>
    );
  };

  const _buildForm = (): JSX.Element => {
    return (
      <WidthLimiterWrapper maxSize="form">
        <form
          id="feed-config-form"
          onSubmit={handleSubmit(onFormSubmit, (errors) => {
            toast.error(translate("onSubmitError"));
          })}
        >
          <Column>
            {_buildDetails()}
            {_buildWidgetTemplates()}
            {_buildSwipeTemplates()}
          </Column>
        </form>
      </WidthLimiterWrapper>
    );
  };

  const _buildDetails = (): JSX.Element => {
    return (
      <ComponentWrapper
        title={translate("infoHeadline")}
        className="full-width mb-15"
      >
        <FormWrapper>
          <OutlinedTextInput
            className="mb-25"
            label={translate("title")}
            placeholder={translate("titlePlaceholder")}
            inputRef={register("title")}
            validationMessage={errors.title?.message?.toString()}
            onChange={(value) => {
              setProperty(feedConfig, "title", value);
              feedStore?.setEditingFeedConfig({ ...feedConfig! });
            }}
          />
          <OutlinedTextInput
            label={translate("subTitle")}
            inputRef={register("subTitle")}
            validationMessage={errors.subTitle?.message?.toString()}
            onChange={(value) => {
              setProperty(feedConfig, "subTitle", value);
              feedStore?.setEditingFeedConfig({ ...feedConfig! });
            }}
          />
        </FormWrapper>
      </ComponentWrapper>
    );
  };

  const _buildWidgetTemplates = (): JSX.Element => {
    return (
      <ComponentWrapper
        title={translate("designHeadline")}
        className="full-width mb-15"
      >
        <FormWrapper title={translate("widgetHeadline")}>
          <Wrap className="mb-20" gap={10}>
            {widgetTemplates.map((item, i) => {
              return (
                <WidgetTemplate
                  key={item.id}
                  title={item.title}
                  description={item.description}
                  imageUrl={item.imageUrl}
                  isActive={item.id === feedConfig?.widgetID}
                  onClick={() => {
                    setProperty(feedConfig, "widgetID", item.id);
                    setValueAndMarkDirty("widgetID", item.id);
                    clearErrors("widgetID");
                    feedStore?.setEditingFeedConfig({ ...feedConfig! });
                  }}
                />
              );
            })}
          </Wrap>
        </FormWrapper>

        {feedConfig?.widgetID !== "WIDGET_6" && (
          <FormWrapper title={translate("formatHeadline")} className="mt-20">
            <Wrap className="mb-20" gap={10}>
              {aspectRatioTemplates.map((item, i) => {
                return (
                  <WidgetTemplate
                    key={item.id}
                    title={item.title}
                    description={item.description}
                    imageUrl={item.imageUrl}
                    isActive={item.id === feedConfig?.aspectRatio}
                    onClick={() => {
                      setProperty(feedConfig, "aspectRatio", item.id);
                      setValueAndMarkDirty("aspectRatio", item.id);
                      clearErrors("aspectRatio");
                      feedStore?.setEditingFeedConfig({ ...feedConfig! });
                    }}
                  />
                );
              })}
            </Wrap>
          </FormWrapper>
        )}
      </ComponentWrapper>
    );
  };

  const _buildSwipeTemplates = (): JSX.Element => {
    return (
      <ComponentWrapper
        title={translate("swipeHeadline")}
        className="full-width mb-15"
      >
        <FormWrapper>
          <Wrap className="mb-20" gap={10}>
            {swipeTemplates.map((item, i) => {
              return (
                <WidgetTemplate
                  key={item.key}
                  title={item.title}
                  description={item.description}
                  imageUrl={item.imageUrl}
                  isActive={
                    item.scrollDirection === feedConfig?.scrollDirection &&
                    item.id === feedConfig?.layoutID
                  }
                  onClick={() => {
                    setProperty(feedConfig, "layoutID", item.id);
                    setValueAndMarkDirty("layoutID", item.id);
                    clearErrors("layoutID");
                    setValueAndMarkDirty(
                      "scrollDirection",
                      item.scrollDirection
                    );
                    clearErrors("scrollDirection");
                    feedStore?.setEditingFeedConfig({ ...feedConfig! });
                    setProperty(
                      feedConfig,
                      "scrollDirection",
                      item.scrollDirection
                    );
                  }}
                />
              );
            })}
          </Wrap>
        </FormWrapper>
      </ComponentWrapper>
    );
  };

  return (
    <SplitLayout
      leftChild={_buildForm()}
      leftGrow={2}
      rightChild={_buildPreview()}
      rightGrow={1}
    />
  );
};

export default inject("studioStore", "feedStore")(observer(FeedConfigForm));
