export interface DemoBooking {
  number: number;
  title: string;
  resource: string;
  from: string;
  to: string;
  total: string;
  status: string;
}

export const demoBookings: DemoBooking[] = [
  {
    number: 87654321,
    title: "Platz Buchen Morgen",
    resource: "Platz1",
    from: "11.04.2024",
    to: "12.04.2024",
    total: "15,00 €",
    status: "Abgeschlossen",
  },
  {
    number: 98765432,
    title: "Trainingsstunde",
    resource: "Trainingsstunde",
    from: "13.04.2024",
    to: "14.04.2024",
    total: "20,00 €",
    status: "Abgeschlossen",
  },
  {
    number: 76543219,
    title: "Sauna",
    resource: "Sauna",
    from: "15.04.2024",
    to: "16.04.2024",
    total: "11,40 €",
    status: "Abgeschlossen",
  },
  {
    number: 65432198,
    title: "Alpha Cooling",
    resource: "Alpha Cooling",
    from: "17.04.2024",
    to: "18.04.2024",
    total: "43,00 €",
    status: "Abgeschlossen",
  },
  {
    number: 54321987,
    title: "Platz Buchen Morgen",
    resource: "Platz1",
    from: "19.04.2024",
    to: "20.04.2024",
    total: "25,10 €",
    status: "Abgeschlossen",
  },
  {
    number: 43219876,
    title: "Trainingsstunde",
    resource: "Trainingsstunde",
    from: "21.04.2024",
    to: "22.04.2024",
    total: "30,00 €",
    status: "Abgeschlossen",
  },
  {
    number: 32198765,
    title: "Sauna",
    resource: "Sauna",
    from: "23.04.2024",
    to: "24.04.2024",
    total: "21,75 €",
    status: "Abgeschlossen",
  },
  {
    number: 21987654,
    title: "Alpha Cooling",
    resource: "Alpha Cooling",
    from: "25.04.2024",
    to: "26.04.2024",
    total: "45,00 €",
    status: "Abgeschlossen",
  },
  {
    number: 19876543,
    title: "Platz Buchen Morgen",
    resource: "Platz1",
    from: "27.04.2024",
    to: "28.04.2024",
    total: "35,00 €",
    status: "Abgeschlossen",
  },
  {
    number: 18765432,
    title: "Trainingsstunde",
    resource: "Trainingsstunde",
    from: "29.04.2024",
    to: "30.04.2024",
    total: "41,00 €",
    status: "Abgeschlossen",
  },
];
