import React from "react";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { useTranslation } from "react-i18next";
import {
  createTranslate,
  formatDate,
  generatePlaceholderAvatarUrl,
} from "globals/helpers/global.helper";
import Image from "components/image.component/image.component";
import { ImageSizes } from "globals/enums/global.enum";
import Row from "components/general.compoenents/row.component/row.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { inject, observer } from "mobx-react";
import { ModalStore } from "stores/modal.store";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";
import StudioStore from "stores/studio.store";
// import { navigationHelper } from "globals/helpers/navigation.helper";
import { Customer } from "schemas/customer.schemas/customer.schema";
import CustomerStore from "stores/customer.store";

interface CustomerListProps {
  customers: Customer[];
  onClick?: (customer: Customer) => void;
  isLoading?: boolean;
  customerStore?: CustomerStore;
  modalStore?: ModalStore;
  studioStore?: StudioStore;
}

const CustomerList = ({
  customers,
  onClick,
  isLoading = false,
  customerStore,
  studioStore,
  modalStore,
}: CustomerListProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, "customerOverviewPage.members.overview");
  //   const navigate = navigationHelper();

  const handleArchiveCustomer = async (customers: Customer): Promise<void> => {
    modalStore?.showDeleteAlert({
      t,
      onConfirm: async () => {
        // await customerStore?.archive(customers);
      },
    });
  };

  return (
    <ComponentWrapper noPadding>
      <ListDataTable
        gap={20}
        data={customers || []}
        noDataMessage={translate("noDataMessage")}
        isLoading={isLoading}
        onClick={onClick}
        listDropdownMenu={{
          items: [
            {
              label: translate("listDropdownMenu.edit"),
              icon: faEdit,
              onClick: (customer: Customer) => {
                // if (customer?._id == null) return;
                // navigate(`inventory/exercises/${customer._id}/info`);
              },
            },
            {
              label: translate("listDropdownMenu.delete"),
              icon: faTrash,
              onClick: (customer: Customer) => {
                handleArchiveCustomer(customer);
              },
            },
          ],
          placement: "left",
        }}
        columns={[
          {
            child: <TitleText tableHeader>{translate("title")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("email")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("appUser")}</TitleText>,
            flex: 1,
          },
          {
            child: (
              <TitleText tableHeader>{translate("lastModified")}</TitleText>
            ),
            flex: 1,
          },
        ]}
        dataTableItemBuilder={(dataItem: Customer) => {
          const fullName = `${dataItem?.firstName} ${dataItem?.lastName}`;

          return {
            key: `customer-item-${dataItem?._id}`,
            children: [
              {
                child: (
                  <Row alignItems="center">
                    <Image
                      imageUrl={
                        dataItem?.profileImageUrl ??
                        generatePlaceholderAvatarUrl(fullName)
                      }
                      size={ImageSizes.M}
                    />

                    <RunningText className="ml-20">
                      {dataItem?.firstName} {dataItem?.lastName}
                    </RunningText>
                  </Row>
                ),
              },
              {
                child: <RunningText>{dataItem?.email}</RunningText>,
              },
              {
                child: (
                  <HighlightedInfoText>
                    {/* // TODO */}
                    {dataItem?.appUser != null ? "Yes" : "No"}
                  </HighlightedInfoText>
                ),
                flex: 1,
              },
              {
                child: (
                  <RunningText>
                    {formatDate(dataItem?.system?.modifiedAt)}
                  </RunningText>
                ),
              },
            ],
          } as any;
        }}
      />
    </ComponentWrapper>
  );
};

export default inject(
  "customerStore",
  "modalStore",
  "studioStore"
)(observer(CustomerList));
