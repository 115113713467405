import * as yup from "yup";
import { Localization } from "./studio.schemas/studio.schema";

export const languageObjectSchema = (
  localizations: Localization[] = []
): yup.ObjectSchema<{ [key: string]: object }> => {
  const languageObjectShape: {
    [key: string]: yup.ObjectSchema<object, object>;
  } = {};

  localizations.forEach((localization) => {
    languageObjectShape[localization.language] = yup
      .object()
      .typeError(`${localization.language.toUpperCase()} muss ein Objekt sein`)
      .required(
        `${localization.language.toUpperCase()} Objekt ist erforderlich`
      );
  });

  return yup.object(languageObjectShape) as yup.ObjectSchema<{
    [key: string]: object;
  }>;
};

export interface LanguageObject {
  [key: string]: object;
}
