import { Page } from "schemas/metool.schemas/page.schema";
import { GenericHttpClient } from "../config/http.generic.client";

export class HttpPageService extends GenericHttpClient<Page> {
  static _instance: HttpPageService;
  static getInstance(): HttpPageService {
    if (this._instance == null) {
      this._instance = new HttpPageService("/pages");
    }
    return this._instance;
  }
}
