import { makeAutoObservable } from "mobx";
import { Logging } from "../globals/helpers/logging.helper";
import RootStore from "./root.store";
import {
  DataItem,
  PaginationDataList,
  getSkipAndLimitFromPage,
} from "globals/intefaces/pageination.data.list.interface";
import {
  ContentLibrary,
  InstalledContentLibrary,
} from "schemas/content.library.schemas/content.library.schema";
import { HttpContentLibraryClient } from "services/httpClients/http.content.library.client";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";

class ContentLibraryStore {
  private stores: RootStore;

  // Properties
  private _contentLibraryDataList: PaginationDataList<ContentLibrary> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _currentContentLibrary: DataItem<ContentLibrary> = {
    data: undefined,
    isLoading: false,
  };

  private _currentContentLibraryExercises: PaginationDataList<Exercise> = {
    data: [],
    pageIndex: 0,
    itemsInPage: 100,
    isLoading: false,
  };

  private _installedContentLibraries: PaginationDataList<InstalledContentLibrary> =
    {
      data: [],
      pageIndex: 0,
      itemsInPage: 100,
      isLoading: false,
    };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setters

  setContentLibraries = (contentLibraries: ContentLibrary[]): void => {
    this._contentLibraryDataList.data = contentLibraries;
  };

  setCurrentContentLibrary = (contentLibrary: ContentLibrary): void => {
    this._currentContentLibrary.data = contentLibrary;
  };

  setCurrentContentLibraryExercises = (exercises: Exercise[]): void => {
    this._currentContentLibraryExercises.data = exercises;
  };

  setInstalledContentLibraries = (
    installedContentLibraries: InstalledContentLibrary[]
  ): void => {
    this._installedContentLibraries.data = installedContentLibraries;
  };

  //! Getters

  get contentLibraries(): PaginationDataList<ContentLibrary> {
    return this._contentLibraryDataList;
  }

  get currentContentLibrary(): DataItem<ContentLibrary> {
    return this._currentContentLibrary;
  }

  get currentContentLibraryExercises(): PaginationDataList<Exercise> {
    return this._currentContentLibraryExercises;
  }

  get installedContentLibraries(): PaginationDataList<InstalledContentLibrary> {
    return this._installedContentLibraries;
  }

  //! Methods
  fetchAndSetContentLibraries = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._contentLibraryDataList != null &&
        this._contentLibraryDataList.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._contentLibraryDataList.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._contentLibraryDataList.pageIndex += 1;
      } else {
        this._contentLibraryDataList.pageIndex = 0;
        this._contentLibraryDataList.data = [];
      }

      this._contentLibraryDataList.isLoading = true;

      const libraries = await HttpContentLibraryClient.getInstance().find({
        query: {
          ...getSkipAndLimitFromPage({
            pageIndex: this._contentLibraryDataList.pageIndex,
            itemsInPage: this._contentLibraryDataList.itemsInPage,
          }),
        },
      });

      if (libraries == null) {
        this._contentLibraryDataList.isLoading = false;
        return;
      }

      this.setContentLibraries(libraries);
      this._contentLibraryDataList.isLoading = false;
    } catch (err) {
      this._contentLibraryDataList.isLoading = false;

      Logging.error({
        className: "ContentLibraryStore",
        methodName: "fetchAndSetContentLibraries",
        message: "Failed to fetch and set content libraries",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetContentLibrary = async (args: {
    contentLibraryID: string;
  }): Promise<void> => {
    try {
      this._currentContentLibrary.isLoading = true;

      const library = await HttpContentLibraryClient.getInstance().findOne({
        id: args.contentLibraryID,
      });

      if (library == null) {
        this._currentContentLibrary.isLoading = false;
        return;
      }

      this.setCurrentContentLibrary(library);
      this._currentContentLibrary.isLoading = false;
    } catch (err) {
      this._currentContentLibrary.isLoading = false;

      Logging.error({
        className: "ContentLibraryStore",
        methodName: "fetchAndSetContentLibrary",
        message: "Failed to fetch and set content library",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetContentLibraryExercises = async (args: {
    contentLibraryID: string;
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._currentContentLibraryExercises != null &&
        this._currentContentLibraryExercises.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._currentContentLibraryExercises.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._currentContentLibraryExercises.pageIndex += 1;
      } else {
        this._currentContentLibraryExercises.pageIndex = 0;
        this._currentContentLibraryExercises.data = [];
      }

      this._currentContentLibraryExercises.isLoading = true;

      const exercises =
        await HttpContentLibraryClient.getInstance().getContentForLibrary<Exercise>(
          {
            contentLibraryID: args.contentLibraryID,
            query: {
              ...getSkipAndLimitFromPage({
                pageIndex: this._currentContentLibraryExercises.pageIndex,
                itemsInPage: this._currentContentLibraryExercises.itemsInPage,
              }),
            },
          }
        );

      if (exercises == null) {
        this._currentContentLibraryExercises.isLoading = false;
        return;
      }

      this.setCurrentContentLibraryExercises(exercises);
      this._currentContentLibraryExercises.isLoading = false;
    } catch (err) {
      this._currentContentLibraryExercises.isLoading = false;

      Logging.error({
        className: "ExerciseStore",
        methodName: "fetchAndSetExercises",
        message: "Übungen konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetInstalledContentLibraries = async (args: {
    refresh?: boolean;
    loadMore?: boolean;
  }): Promise<void> => {
    try {
      if (
        args.loadMore !== true &&
        this._installedContentLibraries != null &&
        this._installedContentLibraries.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._installedContentLibraries.isLoading) {
        return;
      }

      if (args.loadMore != null) {
        this._installedContentLibraries.pageIndex += 1;
      } else {
        this._installedContentLibraries.pageIndex = 0;
        this._installedContentLibraries.data = [];
      }

      this._installedContentLibraries.isLoading = true;

      const libraries =
        await HttpContentLibraryClient.getInstance().getInstalledContentLibraries();

      if (libraries == null) {
        this._installedContentLibraries.isLoading = false;
        return;
      }

      this.setInstalledContentLibraries(libraries);
      this._installedContentLibraries.isLoading = false;
    } catch (err) {
      this._installedContentLibraries.isLoading = false;

      Logging.error({
        className: "ContentLibraryStore",
        methodName: "fetchAndSetInstalledContentLibraries",
        message: "Failed to fetch and set installed content libraries",
        exception: err,
        showAlert: true,
      });
    }
  };

  archiveContentLibrary = async (args: {
    contentLibraryID: string;
  }): Promise<void> => {
    try {
      this._currentContentLibrary.isLoading = true;
      this._installedContentLibraries.isLoading = true;

      // unarchive content library
      await HttpContentLibraryClient.getInstance().archiveOne({
        id: args.contentLibraryID,
      });

      // refresh data after archiving
      await this.fetchAndSetInstalledContentLibraries({ refresh: true });
      await this.stores.exerciseStore.fetchAndSetExercises({ refresh: true });

      this._currentContentLibrary.isLoading = false;
      this._installedContentLibraries.isLoading = false;
    } catch (err) {
      this._currentContentLibrary.isLoading = false;
      this._installedContentLibraries.isLoading = false;

      Logging.error({
        className: "ContentLibraryStore",
        methodName: "archiveContentLibrary",
        message: "Failed to archive content library",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default ContentLibraryStore;
