import React, { useEffect } from "react";
import Column from "components/general.compoenents/column.component/column.component";
import feedIllustration from "assets/illustrations/feed-mockup.png";
import { inject, observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import FeedStore from "stores/feed.store";
import StudioStore from "stores/studio.store";
import FeedContentList from "../components/feed.content.list.component/feed.content.list.component";
import { Feed } from "schemas/feed.schemas/feed.schema";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { ModalStore } from "stores/modal.store";
import { SearchTopic } from "stores/global.search.store";
import PageIntroCard from "components/card.components/page.intro.card.component/page.intro.card.component";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";

interface FeedContentOverviewPageProps {
  studioStore?: StudioStore;
  feedStore?: FeedStore;
  modalStore?: ModalStore;
}

const FeedContentOverviewPage = ({
  studioStore,
  feedStore,
  modalStore,
}: FeedContentOverviewPageProps): JSX.Element => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const translate = createTranslate(t, "feedPage.content.overview");

  const { feedConfigID } = useParams();
  const feed = feedStore?.feed?.data ?? [];

  useEffect(() => {
    initializePage();
  }, [feedConfigID]);

  const initializePage = async (): Promise<void> => {
    if (feedConfigID == null) {
      return;
    }

    if (feedConfigID !== "new") {
      await feedStore?.fetchAndSetFeed({
        refresh: true,
        filter: { feedConfig: feedConfigID },
      });
    }
  };

  const handleClickFeedItem = (
    feed: Feed | undefined,
    isNew?: boolean
  ): void => {
    if (studioStore?.studio?._id == null) return;

    if (feedConfigID != null && feed?._id != null && !isNew) {
      navigate(
        `/studios/${studioStore.studio._id}/feed/${feedConfigID}/content/${feed._id}`
      );
    } else {
      navigate(
        `/studios/${studioStore.studio._id}/feed/${feedConfigID}/content/new`
      );
    }
  };

  return (
    <Column>
      <PageIntroCard
        image={feedIllustration}
        imagePosition="bottom center"
        title={translate("introTitle", {
          categoryName: feedStore?.currentFeedConfig?.data?.title ?? "",
        })}
        description={translate("introDescription")}
        buttonText={translate("addButton")}
        buttonAction={() => {
          handleClickFeedItem(undefined, true);
        }}
      />
      <Row justifyContent="flex-end">
        <FilledButton
          className="mb-15"
          label="Search"
          icon={faSearch}
          onClick={() => {
            modalStore?.openGlobalSearchModal(SearchTopic.FEED);
          }}
        />
      </Row>

      <FeedContentList
        feedItems={feed}
        onClick={handleClickFeedItem}
        isLoading={feedStore?.feed?.isLoading}
      />
    </Column>
  );
};

export default inject(
  "studioStore",
  "feedStore",
  "modalStore"
)(observer(FeedContentOverviewPage));
