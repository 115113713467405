import React, { useEffect, useState } from "react";
import "./plan.exercise.selection.modal.component.scss";
import { inject, observer } from "mobx-react";
import ExerciseStore from "stores/exercise.store";
import { ModalStore } from "stores/modal.store";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { SkeletonSelectionListDataTableItem } from "components/table.components/list.data.table.component/list.data.table.component";
import TrainingPlanStore from "stores/training.plan.store";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import Row from "components/general.compoenents/row.component/row.component";
import { Logging } from "globals/helpers/logging.helper";
import { PlanExercise } from "schemas/training.plan.schemas/plan.exercise.schema";
import ModalHeader from "../modal.header.component/modal.header.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import Headline from "components/text.components/headline.component/headline.component";
import { ContainerSizes } from "globals/enums/global.enum";
import GlobalExercisesList from "components/table.components/global.exercise.list.component/global.exercises.list.component";

interface PlanExerciseSelectionModalProps {
  exerciseStore?: ExerciseStore;
  modalStore?: ModalStore;
  trainingPlanStore?: TrainingPlanStore;
}

const PlanExerciseSelectionModal = ({
  exerciseStore,
  modalStore,
  trainingPlanStore,
}: PlanExerciseSelectionModalProps): JSX.Element => {
  const exercises = exerciseStore?.exercises?.data ?? [];
  const currentTrainingPlan = trainingPlanStore?.currentTrainingPlan?.data;
  const currentSelectedExercises = trainingPlanStore?.currentSelectedExercises;

  const [isSearchActive, setIsSearchActive] = useState(false);

  const exercisesSearchResult = exerciseStore?.exercisesSearchResult ?? [];

  // if isSearch is active, use exercisesSearchResult otherwise use exercises
  const exercisesToRender = isSearchActive ? exercisesSearchResult : exercises;

  useEffect(() => {
    exerciseStore?.fetchAndSetExercises({});
    trainingPlanStore?.setCurrentSelectedExercises([]);

    // set the current assigned plan exercises to the selecteExercises in trainingPlanStore
    if (trainingPlanStore?.currentTrainingPlan?.data?.exercises != null) {
      const selectedPlanExercises =
        trainingPlanStore?.currentTrainingPlan?.data.exercises.map(
          (item: PlanExercise) => item?.exercise ?? null
        );

      trainingPlanStore?.setCurrentSelectedExercises(
        selectedPlanExercises as any
      );
    }
  }, []);

  // handle the assignment of exercises to the device
  const handleSubmit = async (): Promise<void> => {
    if (
      //  currentTrainingPlan?._id != null &&
      currentTrainingPlan != null &&
      currentSelectedExercises != null &&
      currentSelectedExercises?.length > 0
    ) {
      try {
        // if currentTrainingPlan has no exercises, set it to an empty array
        if (currentTrainingPlan?.exercises == null) {
          currentTrainingPlan.exercises = [];
        }

        // get all the exercises wehere not in the currentTrainingPlan exercises
        const newExercises = currentSelectedExercises.filter((exercise) => {
          return (
            currentTrainingPlan?.exercises?.findIndex(
              (item) => item?.exercise?._id === exercise?._id
            ) === -1
          );
        });

        // filter out the exercises that are removed from the current training plan
        const updatedExercises = currentTrainingPlan?.exercises?.filter(
          (item) => {
            return (
              currentSelectedExercises.findIndex(
                (exercise) => exercise?._id === item?.exercise?._id
              ) !== -1
            );
          }
        );

        // prepare the exercises to planExercises
        const newPlanExercises: any = newExercises.map((exercise, index) => {
          return {
            exercise,
            sets: [
              {
                reps: 3,
                weight: 0,
                set: 1,
              },
            ],
            sort: index,
          };
        });

        const exercises = updatedExercises != null ? updatedExercises : [];

        if (newPlanExercises != null) {
          exercises.push(...newPlanExercises);
        }

        // add the new exercises to the current training plan
        trainingPlanStore?.setCurrentTrainingPlan({
          ...currentTrainingPlan,
          exercises,
        });

        modalStore?.closeModal();
      } catch (err) {
        modalStore?.closeModal();

        Logging.error({
          className: "PlanExerciseSelectionModal",
          methodName: "handleSubmit",
          message: "Änderungen konnten nicht gespeichert werden",
          exception: err,
        });
      }
    }
  };

  const _buildTopBar = (): JSX.Element => {
    return (
      <ModalHeader
        title="Übungen hinzufügen"
        description="Füge Übungen zu deinem Trainingsplan hinzu."
      >
        <Row
          className="mobile-row-break"
          justifyContent="space-between"
          alignItems="center"
        >
          <SizedContainer size={ContainerSizes.M}>
            <OutlinedTextInput
              placeholder="Übungen suchen"
              onChange={(value) => {
                if (value != null && value?.trim().length > 0) {
                  setIsSearchActive(true);
                  exerciseStore?.searchAndSetExercises(value);
                } else {
                  setIsSearchActive(false);
                }
              }}
            />
          </SizedContainer>
          <Row justifyContent="flex-end" alignItems="center">
            <Headline className="mr-15">
              {currentSelectedExercises?.length ?? 0} x
            </Headline>
            <FilledButton
              label="Speichern"
              color="secondary"
              onClick={() => {
                handleSubmit();
              }}
            />
          </Row>
        </Row>
      </ModalHeader>
    );
  };

  const _buildExercisesList = (): JSX.Element => {
    if (exerciseStore?.exercises?.isLoading === true) {
      return <SkeletonSelectionListDataTableItem count={5} />;
    } else {
      return (
        <div className="exercises-list-wrapper">
          <GlobalExercisesList
            exercises={exercisesToRender}
            selectedExercises={
              trainingPlanStore?.currentSelectedExercises ?? []
            }
            onClick={(exercise: Exercise) => {
              // check if the exercise is already selected
              const selectedExerciseExists = currentSelectedExercises?.find(
                (item) => {
                  return item._id === exercise._id;
                }
              );

              // if the exercise is already selected, remove it from the list
              if (selectedExerciseExists != null) {
                const fitered = currentSelectedExercises?.filter(
                  (item) => item._id !== exercise._id
                );
                trainingPlanStore?.setCurrentSelectedExercises(fitered);
              } else {
                // if the exercise is not selected, add it to the lis
                trainingPlanStore?.setCurrentSelectedExercises([
                  ...(currentSelectedExercises ?? []),
                  exercise,
                ]);
              }
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className="selection-modal-wrapper">
      <div className="selection-modal-header-container">{_buildTopBar()}</div>
      <div className="selection-modal-body-container">
        {_buildExercisesList()}
      </div>
    </div>
  );
};

export default inject(
  "exerciseStore",
  "trainingPlanStore",
  "modalStore"
)(observer(PlanExerciseSelectionModal));
