import React from "react";
import Column from "components/general.compoenents/column.component/column.component";
import Row from "components/general.compoenents/row.component/row.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { inject, observer } from "mobx-react";
import { ModalStore } from "stores/modal.store";
import ModalHeader from "../modal.header.component/modal.header.component";
import { useNavigate } from "react-router";
import FilledCheckbox from "components/input.components/filled.checkbox.component/filled.checkbox.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";

interface AskToNavigateModalProps {
  modalStore?: ModalStore;
}

const AskToNavigateModal = ({
  modalStore,
}: AskToNavigateModalProps): JSX.Element => {
  const navigate = useNavigate();
  const { path } = modalStore?.navigateData ?? {};
  const askToNavigate = localStorage.getItem("askToNavigate");

  const [allowToNavigate, setAllowToNavigate] = React.useState(
    askToNavigate === "false"
  );

  return (
    <Column>
      <ModalHeader
        title={modalStore?.navigateData?.title ?? "Möchten Sie Navigieren?"}
        description={
          modalStore?.navigateData?.description ??
          "Sind Sie sicher, dass Sie navigieren möchten?"
        }
      >
        <Row justifyContent="flex-end" alignItems="center" className="mt-10">
          <Row alignItems="center" justifyContent="flex-start">
            <FilledCheckbox
              checked={allowToNavigate}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const checked = event.target.checked;
                setAllowToNavigate(checked);
                localStorage.setItem(
                  "askToNavigate",
                  checked ? "false" : "true"
                );
              }}
            />
            <RunningText className="ml-5 mt-5">
              Nicht mehr Frage um zu Navigieren
            </RunningText>
          </Row>

          <FilledButton
            color="secondary"
            label={"Abrechen"}
            onClick={() => {
              modalStore?.closeModal();
            }}
          />
          <FilledButton
            className="ml-10"
            label={"OK"}
            onClick={() => {
              modalStore?.closeModal();
              if (path != null) navigate(path);
            }}
          />
        </Row>
      </ModalHeader>
    </Column>
  );
};

export default inject("modalStore")(observer(AskToNavigateModal));
