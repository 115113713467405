import React from "react";
import previewIcon from "assets/illustrations/gymo_app_preview.png";
import gymoIcon from "assets/icons/gymo_logo_with_lettering.png";
import Headline from "components/text.components/headline.component/headline.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import Column from "components/general.compoenents/column.component/column.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import { ContainerSizes } from "globals/enums/global.enum";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import { useForm } from "react-hook-form";
import { HttpAuthService } from "services/httpClients/http.auth.client";
import { toast } from "react-toastify";
import { getEmailValidationPattern } from "globals/helpers/validation.helper";
import { Logging } from "globals/helpers/logging.helper";
import Row from "components/general.compoenents/row.component/row.component";
import "./forgot.password.page.scss";
import { useTranslation } from "react-i18next";

const ForgotPasswordPage = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleForgotPassword = async (data: any): Promise<void> => {
    try {
      const response = await HttpAuthService.getInstance().handleForgotPassword(
        { email: data.email }
      );

      if (response) {
        toast.success("Email wurde erfolgreich versendet");
      }
    } catch (err) {
      Logging.error({
        className: "ForgotPasswordPage",
        methodName: "handleForgotPassword",
        message: "Email konnte nicht versendet werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  const _buildForgotPasswordContent = (): JSX.Element => {
    return (
      <div className="forgot-password-form-container">
        <div className="forgot-password-form-wrapper">
          <form
            className="forgot-password-form"
            onSubmit={handleSubmit(handleForgotPassword, (errors) => {
              toast.error(t("forgotPasswordPage.toastError"));
            })}
            noValidate
          >
            <SizedContainer size={ContainerSizes.L}>
              <Column alignItems="flex-start">
                <Headline className="mb-15">
                  {t("forgotPasswordPage.headline")}
                </Headline>
                <RunningText className="mb-20">
                  {t("forgotPasswordPage.instructionText")}
                </RunningText>
                <OutlinedTextInput
                  label={t("forgotPasswordPage.email") ?? ""}
                  type="email"
                  inputRef={register("email", {
                    required: "Please enter an email",
                    pattern: {
                      value: getEmailValidationPattern(),
                      message: "Please enter a valid email",
                    },
                  })}
                  validationMessage={errors.email?.message?.toString()}
                />
                <FilledButton
                  label={t("forgotPasswordPage.sendButton")}
                  className="mt-15 full-width"
                  type="submit"
                />
              </Column>
            </SizedContainer>
          </form>
        </div>
      </div>
    );
  };

  const _buildPreview = (): JSX.Element => {
    return (
      <div className="forgot-password-preview-container">
        <img className="gymo-logo" src={gymoIcon} />
        <Headline className="forgot-password-header">{`The biggest smallest\nFitness Software.`}</Headline>
        <img className="forgot-password-preview-image" src={previewIcon} />
      </div>
    );
  };

  return (
    <Row className="forgot-password-page">
      <div className="forgot-password-page-wrapper">
        {_buildPreview()}
        <div className="forgot-password-form-wrapper">
          {_buildForgotPasswordContent()}
        </div>
      </div>
    </Row>
  );
};

export default ForgotPasswordPage;
