import { WidgetConfig } from "@vondot-development/metool_lib";
import BillingWidgetIcon from "./billing.svg";
import BillingDataFormWidget from "./billing.data.form.widget.component/billing.data.form.widget.component";
import billingDataFormWidgetOptionSidebar from "./sidebar.component/billing.data.form.widget.option.sidebar.component";

export const BillingDataFormWidgetConfig: WidgetConfig = {
  name: "Billing Data Form",
  type: "BILLING_DATA_WIDGET",
  component: BillingDataFormWidget,
  icon: BillingWidgetIcon,
  canHaveChildren: false,
  sidebarComponent: billingDataFormWidgetOptionSidebar,
};
