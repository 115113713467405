import { Logging } from "globals/helpers/logging.helper";
import {
  DataItem,
  MapPaginationDataList,
  PaginationDataList,
  getSkipAndLimitFromPage,
} from "globals/intefaces/pageination.data.list.interface";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import { DataAttributeCollection } from "schemas/data.attribute.collection.schemas/data.attribute.collection.schema";
import {
  DataAttribute,
  attributeToJson,
} from "schemas/data.attribute.schemas/data.attribute.schema";
import { HttpDataAttributeService } from "services/httpClients/http.data.attribute.client";
import RootStore from "./root.store";

class DataAttributeStore {
  private stores: RootStore;

  // Properties
  private _collectionAttributeDataList: MapPaginationDataList<DataAttribute> =
    new Map();

  private _currentDataAttribute: DataItem<DataAttribute> = {
    data: undefined,
    isLoading: false,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setter
  setCollectionAttributes(
    collectionID: string,
    data: PaginationDataList<DataAttribute>
  ): void {
    this._collectionAttributeDataList.set(collectionID, data);
  }

  setCurrentDataAttribute(
    dataAttribute: DataAttribute | undefined,
    isLoading: boolean = false
  ): void {
    this._currentDataAttribute = { data: dataAttribute, isLoading };
  }

  //! Getters
  getCollectionAttributes(
    collectionID: string
  ): PaginationDataList<DataAttribute> {
    return (
      this._collectionAttributeDataList.get(collectionID) ?? {
        data: [],
        pageIndex: 0,
        itemsInPage: 100,
        isLoading: false,
      }
    );
  }

  get currentDataAttribute(): DataItem<DataAttribute> {
    return this._currentDataAttribute;
  }

  //! Helper

  private updateCollectionDataList(args: {
    collectionID: string;
    dataAttribute: DataAttribute;
    isNew: boolean;
  }): void {
    const { collectionID, dataAttribute, isNew } = args;

    const existingPaginationData =
      this._collectionAttributeDataList.get(collectionID);

    if (existingPaginationData) {
      if (isNew) {
        // add new DataAttribute to the list if it is new
        existingPaginationData.data.push(dataAttribute);
      } else {
        // update existing DataAttribute in the list if it is not new
        const index = existingPaginationData.data.findIndex(
          (item) => item._id === dataAttribute._id
        );
        if (index !== -1) existingPaginationData.data[index] = dataAttribute;
      }
    } else {
      // create new collection data list if it does not exist
      this._collectionAttributeDataList.set(collectionID, {
        data: [dataAttribute],
        pageIndex: 0,
        itemsInPage: 100,
        isLoading: false,
      });
    }
  }

  //! Methods

  fetchAndSetAttributesForCollection = async (args: {
    refresh?: boolean;
    filter?: any;
    collectionID: string;
  }): Promise<void> => {
    const paginationData = this._collectionAttributeDataList.get(
      args.collectionID
    );

    // Determine if we need to refresh data or not
    if (!args.refresh && paginationData && paginationData.data.length > 0) {
      return;
    }

    // Set loading state before fetching data
    const isLoading = true;
    const pageIndex = paginationData ? paginationData.pageIndex : 0;
    const itemsInPage = paginationData ? paginationData.itemsInPage : 100;

    try {
      this._collectionAttributeDataList.set(args.collectionID, {
        data: paginationData ? paginationData.data : [],
        pageIndex,
        itemsInPage,
        isLoading,
      });

      const query = {
        ...getSkipAndLimitFromPage({
          pageIndex,
          itemsInPage,
        }),
        filter: {
          collection: args.collectionID,
        },
      };

      const dataAttributes = await HttpDataAttributeService.getInstance().find({
        query,
      });

      // Set fetched data and update loading state
      this._collectionAttributeDataList.set(args.collectionID, {
        data: dataAttributes != null ? dataAttributes : [],
        pageIndex,
        itemsInPage,
        isLoading: false,
      });
    } catch (err) {
      // Handle error by setting isLoading to false but keep the existing data
      this._collectionAttributeDataList.set(args.collectionID, {
        data: paginationData ? paginationData.data : [],
        pageIndex,
        itemsInPage,
        isLoading: false,
      });

      Logging.error({
        className: "DataAttributeStore",
        methodName: "fetchAndSetAttributesForCollection",
        message: "Eigenschaften konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createInitialDataAttribute = (
    currentDataAttributeCollection: DataAttributeCollection
  ): DataAttribute => {
    const initalDataAttribute: any = {
      collection: currentDataAttributeCollection,
    };

    this.setCurrentDataAttribute(initalDataAttribute);

    return initalDataAttribute;
  };

  createDataAttribute = async (args: {
    dataAttribute: DataAttribute;
  }): Promise<DataAttribute | undefined> => {
    try {
      const collectionID = args.dataAttribute?.collection?._id;

      if (collectionID == null) {
        return;
      }

      this._currentDataAttribute.isLoading = true;

      const createdDataAttribute =
        await HttpDataAttributeService.getInstance().create({
          data: attributeToJson(args.dataAttribute),
        });

      if (createdDataAttribute == null) {
        this._currentDataAttribute.isLoading = false;
        return;
      }

      this.updateCollectionDataList({
        collectionID,
        dataAttribute: createdDataAttribute,
        isNew: true,
      });

      this.setCurrentDataAttribute(createdDataAttribute);
      this._currentDataAttribute.isLoading = false;
      toast.success("Eigenschaft wurde erfolgreich erstellt");

      return createdDataAttribute;
    } catch (err) {
      this._currentDataAttribute.isLoading = false;

      Logging.error({
        className: "DataAttributeStore",
        methodName: "createDataAttribute",
        message: "Eigenschaft konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateDataAttribute = async (args: {
    dataAttribute: DataAttribute;
  }): Promise<DataAttribute | undefined> => {
    try {
      const collectionID = args.dataAttribute?.collection?._id;

      if (collectionID == null) {
        return;
      }

      this._currentDataAttribute.isLoading = true;

      const updatedDataAttribute =
        await HttpDataAttributeService.getInstance().updateOne({
          id: args.dataAttribute._id!,
          data: attributeToJson(args.dataAttribute),
        });

      if (updatedDataAttribute == null) {
        this._currentDataAttribute.isLoading = false;
        return;
      }

      this.updateCollectionDataList({
        collectionID,
        dataAttribute: updatedDataAttribute,
        isNew: false,
      });

      this.setCurrentDataAttribute(updatedDataAttribute);
      this._currentDataAttribute.isLoading = false;
      toast.success("Eigenschaft wurde erfolgreich aktualisiert");
      return updatedDataAttribute;
    } catch (err) {
      this._currentDataAttribute.isLoading = false;

      Logging.error({
        className: "DataAttributeStore",
        methodName: "updateDataAttribute",
        message: "Eigenschaft konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default DataAttributeStore;
