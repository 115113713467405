import { Provider as MobxProvider } from "mobx-react";
import { ToastContainer } from "react-toastify";
import React, { useEffect } from "react";
import Routing from "routing/routing";
import "react-toastify/dist/ReactToastify.css";
import "./styles/global.scss";
import "react-loading-skeleton/dist/skeleton.css";
import Intro from "components/general.compoenents/intro.component/intro.component";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import RootStore from "stores/root.store";

const rootStore = new RootStore();

const App = (): JSX.Element => {
  const updateDynamicStyleVariable = (): void => {
    if (window.innerHeight < 250) {
      setTimeout(() => {
        updateDynamicStyleVariable();
      }, 100);

      return;
    }

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;

    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme !== null) {
      const darkModeOn = theme === "dark";
      if (darkModeOn) {
        document.body.classList.add("theme--dark");
        document.body.classList.remove("theme--light");
      } else {
        document.body.classList.remove("theme--dark");
        document.body.classList.add("theme--light");
      }
    } else {
      localStorage.setItem("theme", "light");
    }

    updateDynamicStyleVariable();

    window.addEventListener("resize", (event) => {
      updateDynamicStyleVariable();
    });

    if (window.screen.orientation) {
      window.screen.orientation.addEventListener("change", (event) => {
        updateDynamicStyleVariable();
      });
    }

    if (process.env.NODE_ENV === "production") {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN ?? "",
      });
    }
  }, []);

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID ?? ""}
    >
      <MobxProvider {...rootStore}>
        <Sentry.ErrorBoundary>
          <Intro />
          <Routing />
          <ToastContainer autoClose={2700} position="top-right" />
        </Sentry.ErrorBoundary>
      </MobxProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
