import React, { useEffect, useState } from "react";
import "./training.plan.exercise.list.component.scss";
import TrainingPlanStore from "stores/training.plan.store";
import { inject, observer } from "mobx-react";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TrainingPlanExerciseListItem from "./components/training.plan.exercise.list.item.component";
import { arrayMoveImmutable } from "array-move";
import { planExerciseSchema } from "schemas/training.plan.schemas/plan.exercise.schema";
import * as yup from "yup";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { ModalStore, ModalType } from "stores/modal.store";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import SortableList from "components/list.components/nav.list.components/sortable.list.component/sortable.list.component";
import Spacer from "components/general.compoenents/spacer.component/spacer.component";
import ColorPicker from "components/input.components/color.picker.component/color.picker.component";
import { TrainingPlan } from "schemas/training.plan.schemas/training.plan.schema";
import { useNavigate, useParams } from "react-router";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";

export const trainingPlanFormSchema = yup.object().shape({
  title: yup.string().required("Titel ist verpflichtend"),
  subTitle: yup.string(),
  rhythm: yup
    .number()
    .integer()
    .min(1)
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? null
        : value
    )
    .nullable()
    .notRequired()
    .default(null),
  duration: yup
    .number()
    .integer()
    .min(1, "Dauer muss mindestens 1 sein")
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? null
        : value
    )
    .nullable()
    .notRequired()
    .default(null),
  exercises: yup.array().of(planExerciseSchema).required().default([]),
  user: yup.string().notRequired(),
});

export const exerciseListFormSchema = yup.object().shape({
  exercises: yup.array().of(planExerciseSchema).required(),
});

export interface ExerciseListFormSchema
  extends yup.InferType<typeof exerciseListFormSchema> {}

interface TrainingPlanExerciseListProps {
  formMethods: UseFormReturn<TrainingPlan, any, undefined>;
  trainingPlanStore?: TrainingPlanStore;
  modalStore?: ModalStore;
  isCustomerTrainingPlan?: boolean;
}

const TrainingPlanExerciseList = ({
  formMethods,
  trainingPlanStore,
  modalStore,
  isCustomerTrainingPlan = false,
}: TrainingPlanExerciseListProps): JSX.Element => {
  const navigate = useNavigate();
  const { studioID } = useParams();

  const trainingPlan = trainingPlanStore?.currentTrainingPlan?.data;
  const planExercises = trainingPlan?.exercises ?? [];

  const [openIndex, setOpenIndex] = useState(0);

  const { register, handleSubmit, formState, control, getValues, setValue } =
    formMethods;

  const exerciseMethods = useFieldArray<any>({
    control,
    name: "exercises",
  });

  const handleOpen = (index: number): void => {
    setOpenIndex(index);
  };

  useEffect(() => {
    // Automatically open collapsable
    setOpenIndex(openIndex);
  }, [planExercises]);

  const saveTrainingPlan = async (data: TrainingPlan): Promise<void> => {
    if (trainingPlan?._id) {
      await trainingPlanStore?.updateTrainingPlan({
        id: trainingPlan._id!,
        trainingPlan: data,
      });
    } else {
      const createdTrainingPlan = await trainingPlanStore?.createTrainingPlan({
        trainingPlan: data,
      });

      createdTrainingPlan &&
        navigate(
          `/studios/${studioID}/training-plans/${createdTrainingPlan._id}/info`
        );
    }
  };

  const handleRemoveExercise = (exerciseIndex: number): void => {
    // check if the exercise with the index exists
    if (exerciseMethods.fields[exerciseIndex] == null) {
      return;
    }

    // remove the exercise from the list
    exerciseMethods.remove(exerciseIndex);
  };

  const { errors } = formState;

  const setValueAndMarkDirty = (name: any, value: any): void => {
    setValue(name, value, { shouldDirty: true });
  };

  const onSortEnd = (oldIndex: number, newIndex: number): void => {
    if (!exerciseMethods.fields) return;

    const sortedPlanExercises: any = arrayMoveImmutable(
      exerciseMethods.fields,
      oldIndex,
      newIndex
    );

    setValueAndMarkDirty("exercises", sortedPlanExercises);
  };

  const _buildNoExericsesMessage = (): JSX.Element => {
    return (
      <RunningText>
        Diesem Trainingsplan wurden noch keine Übungen hinzugefügt.
      </RunningText>
    );
  };

  const _buildExercises = (): JSX.Element => {
    return (
      <SortableList
        data={exerciseMethods.fields.map((exercise: any) => {
          return { ...exercise, id: exercise._id ?? exercise.exercise._id };
        })}
        onSortStart={() => {
          handleOpen(-1);
        }}
        onSortEnd={onSortEnd}
        itemBuilder={(data, index) => {
          return (
            <>
              <TrainingPlanExerciseListItem
                planExercise={data}
                exerciseIndex={index}
                formMethods={formMethods}
                isOpened={index === openIndex}
                onOpening={() => handleOpen(index)}
                onRemoveExercise={(exerciseIndex: number) => {
                  handleRemoveExercise(exerciseIndex);
                }}
              />
              {index !== exerciseMethods.fields.length - 1 && <Spacer />}
            </>
          );
        }}
      />
    );
  };

  const _buildCustomerTrainingPlanInfo = (): JSX.Element => {
    return (
      <ComponentWrapper className="mb-15" title="Informationen">
        <FormWrapper>
          <Wrap>
            <OutlinedTextInput
              label="Titel"
              className="training-plan-info-input mr-15"
              inputRef={register("title")}
              validationMessage={(errors as any)?.title?.message?.toString()}
            />
            <OutlinedTextInput
              label="Subtitle"
              className="training-plan-info-input"
              inputRef={register("subTitle")}
              validationMessage={(errors as any).subTitle?.message?.toString()}
            />
            <OutlinedTextInput
              type="number"
              label="Wochenrhythmus"
              className="training-plan-info-input mr-15"
              inputRef={register("rhythm")}
              validationMessage={(errors as any).rhythm?.message?.toString()}
            />

            <OutlinedTextInput
              type="number"
              label="Dauer in Minuten"
              className="training-plan-info-input"
              inputRef={register("duration")}
              validationMessage={(errors as any).duration?.message?.toString()}
            />

            <ColorPicker
              name="color"
              label="Farbe"
              inputRef={register("coverColor")}
              defaultValue={getValues("coverColor") ?? "#313634e0"}
              validationMessage={(
                errors as any
              ).coverColor?.message?.toString()}
            />
          </Wrap>
        </FormWrapper>
      </ComponentWrapper>
    );
  };

  return (
    <form
      id="training-plan-exercise-form"
      onSubmit={handleSubmit(saveTrainingPlan)}
    >
      {isCustomerTrainingPlan && _buildCustomerTrainingPlanInfo()}

      <ComponentWrapper
        title={"Übungen"}
        actions={
          <LinkButton
            noPadding
            label="Übung hinzufügen"
            onClick={() => {
              modalStore?.openModal(ModalType.PLAN_EXERCISE_SELECTION_MODAL);
            }}
          />
        }
      >
        {exerciseMethods.fields == null ||
          (exerciseMethods.fields.length === 0
            ? _buildNoExericsesMessage()
            : _buildExercises())}
      </ComponentWrapper>
    </form>
  );
};

export default inject(
  "modalStore",
  "trainingPlanStore"
)(observer(TrainingPlanExerciseList));
