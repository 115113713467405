import React, { useEffect } from "react";
import Column from "components/general.compoenents/column.component/column.component";
import ContentLibraryStore from "stores/content.library.store";
import { inject, observer } from "mobx-react";
import { InstalledContentLibrary } from "schemas/content.library.schemas/content.library.schema";
import BigMarketPlaceCard, {
  BigMarketPlaceCardSkeleton,
} from "pages/marketplace.pages/components/big.marketplace.cardcomponent/big.marketplace.card.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";
import { navigationHelper } from "globals/helpers/navigation.helper";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

interface MarketplaceInstalledAppsPageProps {
  contentLibraryStore?: ContentLibraryStore;
}

// TODO FINSIH THIS PAGE
const MarketplaceInstalledAppsPage = ({
  contentLibraryStore,
}: MarketplaceInstalledAppsPageProps): JSX.Element => {
  const navigate = navigationHelper();

  const handleAddOnClick = (contentLibraryID: string): void => {
    if (contentLibraryID == null) return;
    navigate(`marketplace/content/${contentLibraryID}`);
  };

  const installedLibraries = contentLibraryStore?.installedContentLibraries;

  useEffect(() => {
    initializePage();
  }, []);

  const initializePage = async (): Promise<void> => {
    await contentLibraryStore?.fetchAndSetInstalledContentLibraries({
      refresh: true,
    });
  };

  const _buildInstalledLibraryItems = (): JSX.Element => {
    if (installedLibraries?.isLoading) {
      return <BigMarketPlaceCardSkeleton count={4} />;
    }

    return (
      <Wrap gap={20}>
        {installedLibraries?.data.map(
          (contentLibrary: InstalledContentLibrary) => {
            return (
              <BigMarketPlaceCard
                key={contentLibrary._id}
                onClick={() => handleAddOnClick(contentLibrary?._id ?? "")}
                title={contentLibrary.title?.de}
                description={contentLibrary.contentCount.toString()}
                imageUrl={contentLibrary?.coverImageUrl}
              />
            );
          }
        )}
      </Wrap>
    );
  };

  return (
    <Column className="mt-30 mb-30">
      <LinkButton
        label="Content Libraries"
        width="fit-content"
        fontSize="headline"
        color="secondary"
        icon={faChevronRight}
        noPadding
        wrapperClassName="mb-10"
        onClick={() => navigate("marketplace/content")}
      />

      {_buildInstalledLibraryItems()}
    </Column>
  );
};

export default inject("contentLibraryStore")(
  observer(MarketplaceInstalledAppsPage)
);
