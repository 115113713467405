import { exerciseSchema } from "schemas/exercise.schemas/exercise.schema";
import * as yup from "yup";
import { exerciseSetSchema } from "./training.plan.schema";

export enum PlanExerciseType {
  HIIT = "hiit",
  CARDIO = "cardio",
}

export enum WorkoutMode {
  REPS = "reps",
  TIME = "time",
}

export const timeBasedSettingsSchema = yup.object().shape({
  countdownInMilliseconds: yup.number().integer().notRequired(),
  trainingDurationInMilliseconds: yup.number().integer().notRequired(),
  pauseInMilliseconds: yup.number().integer().notRequired(),
  repetitions: yup.number().integer().notRequired(),
  effortLevel: yup.number().integer().notRequired(),
});

export const planExerciseSchema = yup.object().shape({
  _id: yup.string().notRequired(),
  exercise: exerciseSchema([]).required(),
  sets: yup
    .array()
    .of(yup.lazy(() => exerciseSetSchema))
    .required(),
  timeSettings: timeBasedSettingsSchema.notRequired(),
  sort: yup.number().integer().notRequired(),
  info: yup.string(),
  workoutMode: yup.mixed().oneOf(Object.values(WorkoutMode)).required(),
  exerciseType: yup.string().notRequired(),
});

export interface PlanExercise
  extends yup.InferType<typeof planExerciseSchema> {}
