import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import Image from "components/image.component/image.component";
import React, { useEffect } from "react";
import { ImageSizes } from "globals/enums/global.enum";
import { TeamMember } from "schemas/user.schemas/user.schema";
import { inject, observer } from "mobx-react";
import Row from "components/general.compoenents/row.component/row.component";
import Column from "components/general.compoenents/column.component/column.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import Headline from "components/text.components/headline.component/headline.component";
import { ModalSize, ModalStore, ModalType } from "stores/modal.store";
import StudioStore from "stores/studio.store";
import { Invitation } from "schemas/invitation.schema";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import IconButton from "components/input.components/icon.button.component/icon.button.component";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { HttpInvitationService } from "services/httpClients/http.invitation.client";
import { toast } from "react-toastify";
import { HttpUserService } from "services/httpClients/http.user.client";
import { Logging } from "globals/helpers/logging.helper";
import { useTranslation } from "react-i18next";
import TitleText from "components/text.components/title.text.component/title.text.component";

interface TeamTabProps {
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const TeamTab = ({ studioStore, modalStore }: TeamTabProps): JSX.Element => {
  const { t } = useTranslation();

  const teamMembers = studioStore?.teamMembers?.data ?? [];
  const openTeamMemberInvitations =
    studioStore?.openTeamMemberInvitations?.data ?? [];
  const teamMembersAreLoading = studioStore?.teamMembers?.isLoading ?? false;

  useEffect(() => {
    studioStore?.getTeamMembers({});
    studioStore?.getOpenTeamMemberInvitations({});
  }, []);

  const resendInvitation = async (invitationID: string): Promise<void> => {
    await HttpInvitationService.getInstance().resendInvation(invitationID);
  };

  const deleteInvitation = async (invitationID: string): Promise<void> => {
    try {
      await HttpInvitationService.getInstance().deleteOne({ id: invitationID });
      await studioStore?.getOpenTeamMemberInvitations({ refresh: true });

      toast.success(t("team.invitationDeletedSuccess"));
    } catch (err) {
      Logging.error({
        className: "TeamTab",
        methodName: "deleteInvitation",
        message: t("team.invitationDeletedError") ?? "",
        exception: err,
        showAlert: true,
      });
    }
  };

  const handleRemoveTeamMember = async (userID: string): Promise<void> => {
    try {
      modalStore?.showConfirmAlert({
        onConfirm: async () => {
          const response = await HttpUserService.getInstance().deleteOne({
            id: userID,
          });

          if (response != null) {
            studioStore?.getTeamMembers({ refresh: true });
            toast.success(t("team.memberRemovedSuccess"));
          } else {
            toast.error(t("team.memberRemovedError"));
          }
        },
        confirmLabel: t("team.delete"),
        title: t("team.confirmDeleteTitle"),
        description: t("team.confirmDeleteDescription"),
      });
    } catch (err) {
      Logging.error({
        className: "TeamTab",
        methodName: "handleRemoveTeamMember",
        message: t("team.memberRemovedError") ?? "",
        exception: err,
        showAlert: true,
      });
    }
  };

  const _buildTeamMembers = (): JSX.Element => {
    return (
      <ListDataTable
        isLoading={teamMembersAreLoading}
        data={teamMembers}
        listDropdownMenu={{
          items: [
            {
              label: "Remove User",
              icon: faTrash,
              onClick: (teamMember: TeamMember) => {
                handleRemoveTeamMember(teamMember._id);
              },
            },
          ],
          placement: "left",
        }}
        columns={[
          {
            child: (
              <TitleText tableHeader className="device-brand-table-header">
                {t("team.name")}
              </TitleText>
            ),
            flex: 1,
          },
          {
            child: (
              <TitleText tableHeader className="device-brand-table-header">
                {t("team.role")}
              </TitleText>
            ),
          },
        ]}
        dataTableItemBuilder={(dataItem: TeamMember) => {
          if (dataItem.firstName == null && dataItem.lastName == null) {
            // skip this item
          }

          return {
            key: dataItem._id,
            children: [
              {
                child: (
                  <Row alignItems="center">
                    <Image
                      className="mr-15"
                      imageUrl={dataItem.profileImageUrl}
                      size={ImageSizes.S}
                    />
                    <Column>
                      <RunningText className="bold">
                        {dataItem.firstName} {dataItem.lastName}
                      </RunningText>
                      <RunningText>{dataItem.email}</RunningText>
                    </Column>
                  </Row>
                ),
              },
              {
                child: <RunningText>{dataItem?.roleAlias ?? "-"}</RunningText>,
              },
            ],
          };
        }}
        itemClassName="device-brand-table-item"
      />
    );
  };

  const _buildOpenTeamMemberInvitations = (): JSX.Element => {
    return (
      <ListDataTable
        data={openTeamMemberInvitations}
        columns={[
          {
            child: (
              <RunningText className="device-brand-table-header">
                {t("team.email")}
              </RunningText>
            ),
            flex: 2,
          },
          {
            child: <></>,
          },
          {
            child: <></>,
            flex: 1,
          },
          {
            child: <></>,
          },
        ]}
        dataTableItemBuilder={(dataItem: Invitation) => {
          return {
            key: dataItem._id ?? dataItem.email,
            children: [
              {
                child: (
                  <Row alignItems="center">
                    <Image
                      className="mr-15"
                      imageUrl={undefined}
                      size={ImageSizes.S}
                      rounded={true}
                    />
                    <Column>
                      <RunningText className="bold">
                        {dataItem.email}
                      </RunningText>
                      <RunningText>
                        {dataItem.role?.title ?? t("team.noRoleAssigned")}
                      </RunningText>
                    </Column>
                  </Row>
                ),
              },
              {
                child: (
                  <LinkButton
                    className="pl-0 pr-0"
                    label={t("team.resendInvitation")}
                    onClick={() => {
                      if (dataItem._id == null) {
                        return;
                      }
                      resendInvitation(dataItem._id);
                    }}
                  />
                ),
              },
              { child: <></> },
              {
                child: (
                  <IconButton
                    icon={faTrash}
                    onClick={() => {
                      if (dataItem._id == null) {
                        return;
                      }
                      deleteInvitation(dataItem._id);
                    }}
                  />
                ),
              },
            ],
          };
        }}
        noDataMessage={t("team.noOpenInvitations") ?? ""}
      />
    );
  };

  return (
    <Column>
      <ComponentWrapper
        className="team-form-wrapper mb-15"
        noPadding
        outsideActions={
          <Row
            className="mb-20"
            justifyContent="space-between"
            alignItems="center"
          >
            <Headline>{t("team.team")}</Headline>
            <FilledButton
              label={t("team.addTeamMember")}
              onClick={() => {
                modalStore?.openModal(
                  ModalType.INVITE_TEAM_MEMBER_MODAL,
                  ModalSize.SMALL
                );
              }}
            />
          </Row>
        }
      >
        {_buildTeamMembers()}
      </ComponentWrapper>
      {openTeamMemberInvitations.length > 0 && (
        <>
          <ComponentWrapper
            className="team-form-wrapper"
            title={t("team.openInvitations") ?? ""}
          >
            {_buildOpenTeamMemberInvitations()}
          </ComponentWrapper>
        </>
      )}
    </Column>
  );
};

export default inject("studioStore", "modalStore")(observer(TeamTab));
