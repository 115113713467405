import { Logging } from "globals/helpers/logging.helper";
import {
  DataItem,
  PaginationDataList,
  getSkipAndLimitFromPage,
} from "globals/intefaces/pageination.data.list.interface";
import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import { DataAttributeCollection } from "schemas/data.attribute.collection.schemas/data.attribute.collection.schema";
import { HttpDataAttributeCollectionService } from "services/httpClients/http.data.attribute.collection.client";
import RootStore from "./root.store";

class DataAttributeCollectionStore {
  private stores: RootStore;

  // properties
  private _dataAttributeCollectionDataList: PaginationDataList<DataAttributeCollection> =
    {
      data: [],
      pageIndex: 0,
      itemsInPage: 100,
      isLoading: false,
    };

  private _currentDataAttributeCollection: DataItem<DataAttributeCollection> = {
    data: undefined,
    isLoading: false,
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.stores = rootStore;
  }

  //! Setter

  setDatAttributeCollections = (
    dataAttributeCollections: DataAttributeCollection[]
  ): void => {
    this._dataAttributeCollectionDataList.data = dataAttributeCollections;
  };

  setCurrentDataAttributeCollection = (
    dataAttributeCollection: DataAttributeCollection
  ): void => {
    this._currentDataAttributeCollection.data = dataAttributeCollection;
  };

  //! Getters

  get dataAttributeCollections():
    | PaginationDataList<DataAttributeCollection>
    | undefined {
    if (this._dataAttributeCollectionDataList == null) {
      return;
    }
    return this._dataAttributeCollectionDataList;
  }

  get currentDataAttributeCollection():
    | DataItem<DataAttributeCollection>
    | undefined {
    if (this._currentDataAttributeCollection == null) {
      return;
    }
    return this._currentDataAttributeCollection;
  }

  //! Methods

  fetchAndSetDataAttributeCollections = async (args: {
    refresh?: boolean;
    filter?: any;
  }): Promise<void> => {
    try {
      if (
        this.dataAttributeCollections != null &&
        this.dataAttributeCollections.data.length !== 0 &&
        args.refresh !== true
      ) {
        return;
      }

      if (this._dataAttributeCollectionDataList.isLoading) {
        return;
      }

      this._dataAttributeCollectionDataList.isLoading = true;

      const query = {
        ...getSkipAndLimitFromPage({
          pageIndex: this._dataAttributeCollectionDataList.pageIndex,
          itemsInPage: this._dataAttributeCollectionDataList.itemsInPage,
        }),
      };

      if (args.filter != null) {
        query.type = args.filter;
      }

      const dataAttributeCollections =
        await HttpDataAttributeCollectionService.getInstance().find({
          query,
        });

      if (dataAttributeCollections == null) {
        this._dataAttributeCollectionDataList.isLoading = false;
        return;
      }

      this.setDatAttributeCollections(dataAttributeCollections);

      this._dataAttributeCollectionDataList.isLoading = false;
    } catch (err) {
      this._dataAttributeCollectionDataList.isLoading = false;

      Logging.error({
        className: "DataAttributeCollectionStore",
        methodName: "fetchAndSetDataAttributeCollections",
        message: "Collections konnten nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  fetchAndSetDataAttributeCollection = async (args: {
    dataAttributeCollectionID: string;
  }): Promise<void> => {
    try {
      this._currentDataAttributeCollection.isLoading = true;

      const dataAttributeCollection =
        await HttpDataAttributeCollectionService.getInstance().findOne({
          id: args.dataAttributeCollectionID,
        });

      if (dataAttributeCollection == null) {
        this._currentDataAttributeCollection.isLoading = false;
        return;
      }

      this.setCurrentDataAttributeCollection(dataAttributeCollection);
      this._currentDataAttributeCollection.isLoading = false;
    } catch (err) {
      this._currentDataAttributeCollection.isLoading = false;

      Logging.error({
        className: "DataAttributeCollectionStore",
        methodName: "fetchAndSetDataAttributeCollection",
        message: "Collection konnte nicht geladen werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  createInitialDataAttributeCollection = (): DataAttributeCollection => {
    const initalDataAttributeCollection: any = {};

    this.setCurrentDataAttributeCollection(initalDataAttributeCollection);

    return initalDataAttributeCollection;
  };

  createDataAttributeCollection = async (args: {
    dataAttributeCollection: DataAttributeCollection;
  }): Promise<DataAttributeCollection | undefined> => {
    try {
      this._currentDataAttributeCollection.isLoading = true;

      const createdCollection =
        await HttpDataAttributeCollectionService.getInstance().create({
          data: args.dataAttributeCollection,
        });

      if (createdCollection == null) {
        this._currentDataAttributeCollection.isLoading = false;
        return;
      }

      this._dataAttributeCollectionDataList.data.unshift(createdCollection);

      this.setCurrentDataAttributeCollection(createdCollection);
      this._currentDataAttributeCollection.isLoading = false;
      toast.success("Collection wurde erfolgreich erstellt");

      return createdCollection;
    } catch (err) {
      this._currentDataAttributeCollection.isLoading = false;

      Logging.error({
        className: "DataAttributeCollectionStore",
        methodName: "createDataAttributeCollection",
        message: "Collection konnte nicht erstellt werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  updateDataAttributeCollection = async (args: {
    dataAttributeCollection: DataAttributeCollection;
  }): Promise<DataAttributeCollection | undefined> => {
    try {
      this._currentDataAttributeCollection.isLoading = true;

      const updatedCollection =
        await HttpDataAttributeCollectionService.getInstance().updateOne({
          id: args.dataAttributeCollection._id!,
          data: args.dataAttributeCollection,
        });

      if (updatedCollection == null) {
        this._currentDataAttributeCollection.isLoading = false;
        return;
      }

      // find updated collection item in list and replace it
      // and set collection item as first item in list
      const collectionItemsWithoutUpdatedItem =
        this._dataAttributeCollectionDataList.data.filter(
          (item) => item._id !== updatedCollection._id
        );

      this._dataAttributeCollectionDataList.data = [
        updatedCollection,
        ...collectionItemsWithoutUpdatedItem,
      ];

      this.setCurrentDataAttributeCollection(updatedCollection);
      this._currentDataAttributeCollection.isLoading = false;
      toast.success("Collection wurde erfolgreich aktualisiert");
      return updatedCollection;
    } catch (err) {
      this._currentDataAttributeCollection.isLoading = false;

      Logging.error({
        className: "DataAttributeCollectionStore",
        methodName: "updateDataAttributeCollection",
        message: "Collection konnte nicht aktualisiert werden",
        exception: err,
        showAlert: true,
      });
    }
  };

  archiveDataAttributeCollection = async (args: {
    dataAttributeCollection: DataAttributeCollection;
  }): Promise<DataAttributeCollection | undefined> => {
    try {
      this._currentDataAttributeCollection.isLoading = true;
      this._dataAttributeCollectionDataList.isLoading = true;

      const archivedCollection =
        await HttpDataAttributeCollectionService.getInstance().archiveOne({
          id: args.dataAttributeCollection._id!,
        });

      if (archivedCollection == null) {
        this._currentDataAttributeCollection.isLoading = false;
        this._currentDataAttributeCollection.isLoading = false;
        toast.success("Collection konnte nicht archiviert werden");
        return;
      }

      // remove archived collection item from this._dataAttributeCollectionDataList.data
      const collectionsWithoutArchivedItem =
        this._dataAttributeCollectionDataList.data.filter(
          (item) => item._id !== args.dataAttributeCollection._id
        );

      this._dataAttributeCollectionDataList.data =
        collectionsWithoutArchivedItem;

      this._currentDataAttributeCollection.isLoading = false;
      this._dataAttributeCollectionDataList.isLoading = false;
      toast.success("Collection wurde erfolgreich archiviert");
    } catch (err) {
      this._currentDataAttributeCollection.isLoading = false;
      this._dataAttributeCollectionDataList.isLoading = false;

      Logging.error({
        className: "DataAttributeCollectionStore",
        methodName: "archiveDataAttributeCollection",
        message: "Collection konnte nicht archiviert werden",
        exception: err,
        showAlert: true,
      });
    }
  };
}

export default DataAttributeCollectionStore;
