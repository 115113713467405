import React, { useEffect } from "react";
import Row from "components/general.compoenents/row.component/row.component";
import WidthLimiterWrapper from "components/general.compoenents/width.limiter.wrapper.component/width.limiter.wrapper.component";
import ContentLibraryStore from "stores/content.library.store";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router";
import MainLayout from "layouts/main.layout/main.layout";
import PageContainer from "components/general.compoenents/page.container.component/page.container.component";
import PageHeadline from "components/navigation.components/page.headline.component/page.headline.component";
import FilledButton from "components/input.components/filled.button.component/filled.button.component";
import ContentLibraryExerciseList from "./components/content.library.exercise.list.component/content.library.exercise.list.component";
import StudioStore from "stores/studio.store";
import FixedControllersToolbar from "components/navigation.components/fixed.controller.toolbar.component/fixed.controller.toolbar.component";
import Gleap from "gleap";
import { ModalStore } from "stores/modal.store";
import { HttpContentLibraryClient } from "services/httpClients/http.content.library.client";
import MarketplaceDetailHero from "../components/marketplace.detail.hero.component";
import { createTranslate } from "globals/helpers/global.helper";
import { useTranslation } from "react-i18next";
import SkeletonDetailPage from "components/general.compoenents/loading.components/skeleton.detail.page.component/skeleton.detail.page.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowDown,
  faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import { SmallText } from "components/text.components/small.text.component/small.text.component";
import HighlightedInfoText from "components/text.components/highlighted.info.text.component/highlighted.info.text.component";

interface MarketplaceContentLibraryDetailPageProps {
  contentLibraryStore?: ContentLibraryStore;
  studioStore?: StudioStore;
  modalStore?: ModalStore;
}

const MarketplaceContentLibraryDetailPage = ({
  contentLibraryStore,
  studioStore,
  modalStore,
}: MarketplaceContentLibraryDetailPageProps): JSX.Element => {
  const { contentLibraryID } = useParams();
  const { t } = useTranslation();
  const translate = createTranslate(t, "marketplacePage.detailPage");

  const currentContentLibrary = contentLibraryStore?.currentContentLibrary;
  const creator = currentContentLibrary?.data?.studio;
  const currentLanguage = studioStore?.currentLanguage?.value;
  const installedLibraries = contentLibraryStore?.installedContentLibraries;

  // TODO check loading state for all data
  const isLibraryInstalled = installedLibraries?.data.some(
    (library) => library._id === contentLibraryID
  );

  useEffect(() => {
    initializePage();
  }, [contentLibraryID]);

  const initializePage = async (): Promise<void> => {
    if (contentLibraryID == null) {
      return;
    }

    await contentLibraryStore?.fetchAndSetContentLibrary({
      contentLibraryID,
    });

    await contentLibraryStore?.fetchAndSetContentLibraryExercises({
      contentLibraryID,
      refresh: true,
    });

    await contentLibraryStore?.fetchAndSetInstalledContentLibraries({
      refresh: true,
    });
  };

  const _buildPageHeadline = (): JSX.Element => {
    return (
      <PageHeadline
        showBackButton
        title={currentContentLibrary?.data?.title[currentLanguage!]}
      />
    );
  };

  const getLibraryInfo = (): JSX.Element | undefined => {
    const isPublished = currentContentLibrary?.data?.isPublished;

    if (isLibraryInstalled) {
      return (
        <HighlightedInfoText color="error">
          <Row gap={10} alignItems="center">
            <FontAwesomeIcon
              icon={faCloudArrowDown}
              className="color-primary"
            />
            <SmallText className="bold">
              {translate("info.isInstalled")}
            </SmallText>
          </Row>
        </HighlightedInfoText>
      );
    }

    if (!isPublished) {
      return (
        <HighlightedInfoText color="error">
          <Row gap={10} alignItems="center">
            <FontAwesomeIcon icon={faTriangleExclamation} size="2xs" />
            <SmallText className="bold">
              {translate("info.notAvailable")}
            </SmallText>
          </Row>
        </HighlightedInfoText>
      );
    }

    return undefined;
  };

  const handleDownload = (): void => {
    const type = currentContentLibrary?.data?.type;
    const contentLibraryID = currentContentLibrary?.data?._id;

    if (type == null || contentLibraryID == null) return;

    switch (type) {
      case "EXERCISE": {
        modalStore?.showConfirmAlert({
          title: translate("info.installModal.title"),
          description: translate("info.installModal.description"),
          confirmLabel: translate("info.installModal.confirmLabel"),
          onConfirm: () => {
            HttpContentLibraryClient.getInstance().installContentForLibrary({
              contentLibraryID,
            });
          },
        });
        break;
      }
    }
  };

  const _buildContentPreview = (): JSX.Element => {
    const type = currentContentLibrary?.data?.type;

    switch (type) {
      case "EXERCISE":
        return <ContentLibraryExerciseList />;
      default:
        return <></>;
    }
  };

  if (
    currentContentLibrary?.isLoading === true ||
    installedLibraries?.isLoading === true
  ) {
    return <SkeletonDetailPage />;
  }

  const _renderActionButtons = (): JSX.Element => {
    return (
      <Row gap={10}>
        <FilledButton
          label={translate("info.support")}
          onClick={() => {
            Gleap.open();
          }}
        />
        {isLibraryInstalled ? (
          <FilledButton
            color="primary"
            label={translate("info.remove")}
            onClick={() => {
              modalStore?.showDeleteAlert({
                t,
                onConfirm: () => {
                  if (currentContentLibrary?.data?._id == null) return;

                  contentLibraryStore?.archiveContentLibrary({
                    contentLibraryID: currentContentLibrary?.data?._id,
                  });
                },
              });
            }}
          />
        ) : (
          <FilledButton
            disabled={!currentContentLibrary?.data?.isPublished}
            color="primary"
            label={
              isLibraryInstalled
                ? translate("info.isInstalled")
                : translate("info.download")
            }
            onClick={() => handleDownload()}
          />
        )}
      </Row>
    );
  };

  return (
    <MainLayout topBars={[_buildPageHeadline()]}>
      <PageContainer>
        <WidthLimiterWrapper maxSize="form">
          <MarketplaceDetailHero
            // TODO add dynamic desc, price, tags
            title={currentContentLibrary?.data?.title[currentLanguage!]}
            renderInfo={getLibraryInfo}
            coverImageUrl={currentContentLibrary?.data?.coverImageUrl}
            tags={["Content Library", "Exercises"]}
            creator={{
              name: creator?.name,
              logoUrl: creator?.logoUrl,
              price: translate("info.free"),
            }}
          />

          {_buildContentPreview()}

          <FixedControllersToolbar
            disabled={false}
            isLoading={false}
            hasUnsavedChanges={false}
            keyString="exercise-info-form-btn"
            title={currentContentLibrary?.data?.title[currentLanguage!]}
            imageUrl={currentContentLibrary?.data?.coverImageUrl}
            renderButtons={() => {
              return _renderActionButtons();
            }}
          />
        </WidthLimiterWrapper>
      </PageContainer>
    </MainLayout>
  );
};

export default inject(
  "contentLibraryStore",
  "studioStore",
  "modalStore"
)(observer(MarketplaceContentLibraryDetailPage));
