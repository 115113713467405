import React, { useEffect } from "react";
import ListDataTable from "components/table.components/list.data.table.component/list.data.table.component";
import { RunningText } from "components/text.components/running.text.component/running.text.component";
import TitleText from "components/text.components/title.text.component/title.text.component";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import Image from "components/image.component/image.component";
import { ImageSizes } from "globals/enums/global.enum";
import Row from "components/general.compoenents/row.component/row.component";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import { inject, observer } from "mobx-react";
import { Exercise } from "schemas/exercise.schemas/exercise.schema";
import StudioStore from "stores/studio.store";
import ContentLibraryStore from "stores/content.library.store";

interface ContentLibraryExerciseListProps {
  onClick?: (exercise: Exercise) => void;
  studioStore?: StudioStore;
  contentLibraryStore?: ContentLibraryStore;
}

const ContentLibraryExerciseList = ({
  onClick,
  studioStore,
  contentLibraryStore,
}: ContentLibraryExerciseListProps): JSX.Element => {
  const currentContentLibrary = contentLibraryStore?.currentContentLibrary;
  const libraryExercises = contentLibraryStore?.currentContentLibraryExercises;

  useEffect(() => {
    initializePage();
  }, [currentContentLibrary]);

  const initializePage = async (): Promise<void> => {
    const contentLibraryID = currentContentLibrary?.data?._id;

    if (contentLibraryID == null) return;

    await contentLibraryStore?.fetchAndSetContentLibraryExercises({
      contentLibraryID,
      refresh: true,
    });
  };

  const { t } = useTranslation();
  const translate = createTranslate(
    t,
    "marketplacePage.detailPage.content.exercise"
  );

  const currentLanguage = studioStore?.currentLanguage?.value;

  const getMuscleGroup = (
    exercise: Exercise,
    type: "agonist" | "antagonist" | "synergist"
  ): string => {
    const muscleGroups = exercise?.muscleGroups[type] || [];

    if (muscleGroups.length === 0 || currentLanguage == null) {
      return "-";
    }

    // map over the muscle groups and return the name and join them with a comma
    return muscleGroups
      .map((muscleGroup) => muscleGroup?.title?.[currentLanguage])
      .join(", ");
  };

  const getAvailableLanguageForExercise = (exercise: Exercise): string => {
    const availableLanguages = Object.keys(exercise?.title || {});
    return availableLanguages.join(", ");
  };

  return (
    <ComponentWrapper title={translate("headline")}>
      <ListDataTable
        gap={20}
        data={libraryExercises?.data ?? []}
        noDataMessage={translate("noDataMessage")}
        isLoading={libraryExercises?.isLoading}
        onClick={onClick}
        columns={[
          {
            child: <TitleText tableHeader>{translate("title")}</TitleText>,
            flex: 2,
          },
          {
            child: <TitleText tableHeader>{translate("agonist")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("synergist")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("antagonist")}</TitleText>,
            flex: 1,
          },
          {
            child: <TitleText tableHeader>{translate("languages")}</TitleText>,
            flex: 1,
          },
        ]}
        dataTableItemBuilder={(dataItem: Exercise) => {
          return {
            key: `exercise-item-${dataItem?._id}`,
            children: [
              {
                child: (
                  <Row alignItems="center">
                    <Image
                      imageUrl={dataItem?.coverImageUrl}
                      size={ImageSizes.M}
                    />

                    <RunningText className="ml-20">
                      {dataItem?.title?.[currentLanguage!]}
                    </RunningText>
                  </Row>
                ),
              },
              {
                child: (
                  <RunningText>
                    {getMuscleGroup(dataItem, "agonist")}
                  </RunningText>
                ),
              },
              {
                child: (
                  <RunningText>
                    {getMuscleGroup(dataItem, "antagonist")}
                  </RunningText>
                ),
              },
              {
                child: (
                  <RunningText>
                    {getMuscleGroup(dataItem, "synergist")}
                  </RunningText>
                ),
              },
              {
                child: (
                  <RunningText>
                    {getAvailableLanguageForExercise(dataItem)}
                  </RunningText>
                ),
              },
            ],
          } as any;
        }}
      />
    </ComponentWrapper>
  );
};

export default inject(
  "studioStore",
  "contentLibraryStore"
)(observer(ContentLibraryExerciseList));
