import React, { useEffect, useState } from "react";
import { StateStore, WidgetStore } from "@vondot-development/metool_lib";
import { inject, observer } from "mobx-react";
import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import Row from "components/general.compoenents/row.component/row.component";
import SelectDropDown from "components/input.components/dropdown.components/select.dropdown.component/select.dropdown.component";
import FileUpload from "components/input.components/file.upload.component/file.upload.component";
import TextEditor from "components/input.components/text.editor.components/text.editor.component/text.editor.component";
import "./customer.data.form.widget.component.scss";
import { CustomerDataWidgetLoading } from "../loading.component/customer.data.widget.loading.component";

interface CustomerDataFormWidgetProps {
  widgetID: string;
  widgetStore?: WidgetStore;
  stateStore?: StateStore;
}

const CustomerDataFormWidget = ({
  widgetID,
  widgetStore,
  stateStore,
}: CustomerDataFormWidgetProps): JSX.Element => {
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const analized = widgetStore?.getAnalyzedWidgetOptions(widgetID);
    if (!analized) return;

    stateStore?.initializeDynamicOptions(
      widgetID,
      analized,
      (options) => {
        setIsLoading(options?.isLoading);
        setData(options?.data);
      },
      {}
    );
  }, []);

  const sexOptions = [
    {
      label: "Weiblich",
      value: "FEMALE",
    },
    {
      label: "Männlich",
      value: "MALE",
    },
    {
      label: "Divers",
      value: "OTHER",
    },
  ];

  if (isLoading) {
    return <CustomerDataWidgetLoading count={7} />;
  }

  return (
    <ComponentWrapper title={"Kundendaten"}>
      <FormWrapper title="Stammdaten">
        <Row gap={20}>
          <OutlinedTextInput
            label="Vorname"
            initialValue={data?.firstName ?? ""}
          />
          <OutlinedTextInput
            label="Nachname"
            initialValue={data?.lastName ?? ""}
          />
        </Row>

        <Row gap={20}>
          <OutlinedTextInput label="Titel" initialValue="" />
          <SelectDropDown
            label="Geschlecht"
            items={sexOptions}
            selectedItem={{
              value: "MALE",
              label: "Männlich",
            }}
          />
        </Row>

        <Row gap={20}>
          <OutlinedTextInput label="Organisation" initialValue="vondot GmbH" />
          <OutlinedTextInput
            label="Geburtsdatum"
            type="date"
            initialValue={data?.dateOfBirth ?? ""}
          />
        </Row>

        <Row gap={20}>
          <OutlinedTextInput label="Email" initialValue={data?.email ?? ""} />
          <OutlinedTextInput
            label="Telefonnummer"
            initialValue="+43 664 82 12 897"
          />
        </Row>

        <Row gap={20}>
          <OutlinedTextInput
            label="Straße & Hausnummer"
            initialValue="Illstraße 3"
          />
        </Row>

        <Row gap={20}>
          <OutlinedTextInput label="Postleitzahl" initialValue="6800" />
          <OutlinedTextInput label="Stadt" initialValue="Feldkirch" />
        </Row>

        <Row gap={20}>
          <OutlinedTextInput label="Land" initialValue="Österreich" />
        </Row>
      </FormWrapper>

      <FormWrapper title="Bild & Notizen" className="mt-20">
        <Row gap={20}>
          <FileUpload
            folder="profile-images"
            fileUrl="https://assets-global.website-files.com/637614918171bc1c3374df81/6383d43f428da05dfc28e874_MitlgliedBild-p-500.jpg"
          />

          <TextEditor
            content={"Kunde bemerkungen..."}
            inputContentChanged={(content: any) => {}}
            className="customer-info-editor"
          />
        </Row>
      </FormWrapper>
    </ComponentWrapper>
  );
};

export default inject(
  "widgetStore",
  "stateStore"
)(observer(CustomerDataFormWidget));
