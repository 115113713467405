import React from "react";
import bookingSystemAddon from "assets/addons/booking_system_addon.png";
import customerManagmentAddon from "assets/addons/customer_managment_addon.png";
import Column from "components/general.compoenents/column.component/column.component";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { navigationHelper } from "globals/helpers/navigation.helper";
import LinkButton from "components/input.components/link.button.component/link.button.component";
import BigMarketPlaceCard from "pages/marketplace.pages/components/big.marketplace.cardcomponent/big.marketplace.card.component";
import Wrap from "components/general.compoenents/wrap.component/wrap.component";

interface MarketplaceIntegrationPageProps {
  showHeader?: boolean;
}

const MarketplaceIntegrationPage = ({
  showHeader = false,
}: MarketplaceIntegrationPageProps): JSX.Element => {
  const { t } = useTranslation();
  const translate = createTranslate(t, `marketplacePage.overview.integrations`);
  const navigate = navigationHelper();

  return (
    <Column className="mt-30 mb-30">
      {showHeader && (
        <LinkButton
          width="fit-content"
          fontSize="headline"
          label={translate("introTitle")}
          color="secondary"
          icon={faChevronRight}
          noPadding
          wrapperClassName="mb-10"
          onClick={() => navigate("marketplace/integrations")}
        />
      )}
      <Wrap gap={20}>
        <BigMarketPlaceCard
          title="Gantner Integration"
          description="Integriere dein Gantner System mit unserer Plattform und erweitere deine Möglichkeiten. Zugangsmanagement, Schrankverwaltung und mehr."
          imageUrl={bookingSystemAddon}
        />
        <BigMarketPlaceCard
          title="Dormakaba Integration"
          description="Nutze die dormakaba Integration und erweitere deine Möglichkeiten. Schliesssysteme, automatische Türen und Zutrittskontrollen."
          imageUrl={customerManagmentAddon}
        />
      </Wrap>
    </Column>
  );
};

export default MarketplaceIntegrationPage;
