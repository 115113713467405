import React from "react";
import { faTrashCan, faBars } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "components/general.compoenents/row.component/row.component";
import SizedContainer from "components/general.compoenents/sized.container.component/sized.container.component";
import IconButton from "components/input.components/icon.button.component/icon.button.component";
import OutlinedTextArea from "components/input.components/outlined.text.area.component/outlined.text.area.component";
import OutlinedTextInput from "components/input.components/outlined.text.input.component/outlined.text.input.component";
import { ContainerSizes } from "globals/enums/global.enum";
import { inject, observer } from "mobx-react";
import { useFormContext } from "react-hook-form";
import StudioStore from "stores/studio.store";
import { useTranslation } from "react-i18next";
import { createTranslate } from "globals/helpers/global.helper";
import Column from "components/general.compoenents/column.component/column.component";

interface ExerciseInfoContentProps {
  studioStore?: StudioStore;
  index: number;
  onDeleted: (index: number) => void;
  disabled?: boolean;
}

const ExerciseInfoContent = ({
  studioStore,
  index,
  onDeleted,
  disabled = false,
}: ExerciseInfoContentProps): JSX.Element => {
  const currentLanguage = studioStore?.currentLanguage;
  const { t } = useTranslation();
  const translate = createTranslate(t, "exerciseInfoDetailPage");

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const iconClass = disabled ? "disabled" : "cursor-pointer";

  return (
    <Column>
      <Row justifyContent="space-between" alignItems="center">
        <SizedContainer size={ContainerSizes.M} className="mb-10">
          <OutlinedTextInput
            disabled={disabled}
            placeholder={translate("contentTitle")}
            className="mt-0"
            inputRef={register(
              `data[${index}].label.${currentLanguage!.value}`
            )}
            validationMessage={
              (errors as any).data?.[index]?.label?.[currentLanguage!.value]
                ?.message
            }
          />
        </SizedContainer>
        <div>
          <FontAwesomeIcon icon={faBars} className={iconClass} />

          <IconButton
            disabled={disabled}
            icon={faTrashCan}
            key="2"
            className="ml-10"
            onClick={() => {
              onDeleted(index);
            }}
          />
        </div>
      </Row>
      <SizedContainer size={ContainerSizes.XXL}>
        <OutlinedTextArea
          disabled={disabled}
          placeholder={translate("contentDescription")}
          className="mb-0"
          inputRef={register(
            `data[${index}].description.${currentLanguage!.value}`
          )}
          validationMessage={
            (errors as any).data?.[index]?.description?.[currentLanguage!.value]
              ?.message
          }
        />
      </SizedContainer>
    </Column>
  );
};

export default inject("studioStore")(observer(ExerciseInfoContent));
