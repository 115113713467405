import ComponentWrapper from "components/general.compoenents/component.wrapper.component/component.wrapper.component";
import FormWrapper from "components/general.compoenents/form.wrapper.component/form.wrapper.component";
import React from "react";
import Skeleton from "react-loading-skeleton";

export const CustomerInfoLoading = ({
  count = 1,
}: {
  count?: number;
}): JSX.Element => {
  return (
    <ComponentWrapper title="Kundeninfo">
      <FormWrapper>
        <div
          style={{
            width: "100%",
          }}
        >
          <Skeleton count={1} width="100%" height={380} />
        </div>
      </FormWrapper>
    </ComponentWrapper>
  );
};
